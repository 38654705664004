import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import PageTitle from "./../Layout/PageTitle";
import Jobfindbox from "./../Element/Jobfindbox";

//Images
import Icon1 from "./../../images/logo/icon1.png";
import bnr from "./../../images/banner/bnr1.jpg";
import { Form, Nav } from "react-bootstrap";
import Profilesidebar from "../Element/Profilesidebar";
import { useDispatch } from "react-redux";
import { fetchopportunities } from "../../redux/actions/OpportunitiesActions";
import { fetchprofile } from "../../redux/actions/ProfileActions";
import Timleline from "./Timleline";
import axios from "axios";
import moment from "moment";
import NoDataPage from "./NoDataPage";

const jobPost = [
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
];
const brandLogo = [
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
];

const timeline = [
  {
    company: "Sapio",
    job_role: "Data Analytics",
    stage: [
      {
        stage: 1,
        step: "Bot Sucecessfully Engaged",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 2,
        step: "Counsellor Engagement On",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 3,
        step: "Interview Process Initiated",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 4,
        step: "Interview Process On",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 5,
        step: "Final Selection Stage",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 6,
        step: "Post Selection Stage",
        company: "company",
        status: false,
        date: "2024-03-04",
      },
    ],
  },
];

function BrowseInternshipList({ location }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [toggle, setToggle] = useState("");
  const [viewId, setViewId] = useState("");
  const [step, setStep] = useState(0);
  const [jobsData, setJobsData] = useState([]);
  // const [state, setState] = useState([]);
  // const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [searchObj, setSearchObj] = useState({
    job_title: "",
    state: "",
    district: "",
  });
  // const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
  };

  // const dispatch = useDispatch();
  // const opportunitiesData = useSelector(
  //   (state) => state.opportunity.opportunityInfo
  // );
  // const opportunityInfo = opportunitiesData.opportunityInfo;

  // useEffect(() => {
  //   dispatch(fetchopportunities());
  //   // dispatch(fetchprofile());
  // }, [dispatch]);

  // console.log("recomended::", opportunitiesData);

  // const postBlog = opportunitiesData;

  // const postBlog = [
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  // ];

  const interestedJobs = [
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
  ];

  const ongoingJobs = [
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
  ];

  // while (i < totalPages) {
  //   paginationArray.push(i + 1);
  //   i++;
  // }

  const postsPerPage = 4;
  const pagesPerGroup = 5;
  //   const currentPosts = jobsData.slice(
  //     (activePage - 1) * postsPerPage,
  //     activePage * postsPerPage
  //   );
  const totalPages = Math.ceil(jobsData.length / postsPerPage);
  const startPage =
    Math.floor((activePage - 1) / pagesPerGroup) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);
  // let i = 0;
  const paginationArray = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationArray.push(i);
  }
  // step === 0
  //   ? jobsData.slice(
  //       (activePage - 1) * postsPerPage,
  //       activePage * postsPerPage
  //     )
  //   : step === 1
  //   ? interestedJobs.slice(
  //       (activePage - 1) * postsPerPage,
  //       activePage * postsPerPage
  //     )
  //   : ongoingJobs.slice(
  //       (activePage - 1) * postsPerPage,
  //       activePage * postsPerPage
  //     );
  console.log("pagination::", startPage, endPage);
  console.log("page nos:", paginationArray, totalPages);

  const fetchopportunities = async () => {
    let url =
      step === -1
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/all-jobpost`
        : step === 0
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/recommended`
        : step === 1
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/interested`
        : `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/ongoing`;
    await axios
      .get(url, {
        params: {
          candid: userInfo["Registration ID"],
          job_title: searchObj.job_title || "",
          state: searchObj.state || "",
          district: searchObj.district || "",
        },
      })
      .then((res) => {
        let data = step === 0 ? res.data.data : res.data.data;
        setJobsData(data);
      });
  };

  const handleAddToOngoing = async (jobid) => {
    let url = `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/ongoing`;

    await axios
      .post(url, {
        jobid: jobid,
        candid: userInfo["Registration ID"],
      })
      .then((res) => {
        // let data = step === 0 ? res.data.recommended : res.data.data;
        // setJobsData(data);
        Swal.fire({
          icon: "success",
          title: "Internship Application Successful",
          //text: res.data["User Message"],
          // position: "center",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        }).then((res) => {
          fetchopportunities();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong...",
          //text: res.data["User Message"],
          // position: "center",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const handleAddToInterested = async (jobid) => {
    let url = `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobseeker/dashboard/interested`;

    await axios
      .post(url, {
        jobid: jobid,
        candid: userInfo["Registration ID"],
      })
      .then((res) => {
        // let data = step === 0 ? res.data.recommended : res.data.data;
        // setJobsData(data);
        Swal.fire({
          icon: "success",
          title: "Internship added to interested list",
          //text: res.data["User Message"],
          showConfirmButton: false,
          // position: "center",
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        }).then((res) => {
          fetchopportunities();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong...",
          //text: res.data["User Message"],
          showConfirmButton: false,
          // position: "center",
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const fetchStateList = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
      .then((res) => {
        console.log("State", res.data);
        // var StateList = res.data[0].sort().map(function (item) {
        //   return { label: item, value: item };
        // });
        setStateOption(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const fetchCity = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district`, {
        params: { state: selectedState },
      })
      .then((res) => {
        setDistrictOption(res.data);

        console.log("District", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };
  useEffect(() => {
    fetchopportunities();
    setViewId("");
    setActivePage(1);
    setToggle("");
  }, [step]);

  useEffect(() => {
    fetchStateList();
  }, []);

  useEffect(() => {
    fetchCity();
  }, [selectedState]);

  return (
    <>
      <Header location={location} />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <form className="pb-4">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 p-2">
                          <label>Internship title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Internship title"
                            onChange={(e) => {
                              // console.log(e.target.value, "123");
                              setSearchObj({
                                ...searchObj,
                                job_title: e.target.value,
                              });
                            }}
                          />
                        </div>

                        <div className="col-lg-3 col-md-6 p-2">
                          <label>Select State</label>
                          <Form.Control
                            as="select"
                            custom
                            className="btn dropdown-toggle text-left btn-default"
                            placeholder="Select State"
                            onChange={(e) => {
                              // console.log(e.target.value, "123");
                              setSearchObj({
                                ...searchObj,
                                state: e.target.value,
                              });
                              setSelectedState(e.target.value);
                            }}
                          >
                            <option>Select State</option>
                            {stateOption?.map((v) => {
                              return <option>{v.label}</option>;
                            })}
                          </Form.Control>
                        </div>
                        <div className="col-lg-3 col-md-6 p-2">
                          <label>Select District</label>
                          <Form.Control
                            as="select"
                            custom
                            className="btn dropdown-toggle text-left btn-default"
                            placeholder="Select District"
                            onChange={(e) => {
                              // console.log(e.target.value, "123");
                              setSearchObj({
                                ...searchObj,
                                district: e.target.value,
                              });
                            }}
                          >
                            <option>Select District</option>
                            {districtOption?.map((v) => {
                              return <option>{v.label}</option>;
                            })}
                          </Form.Control>
                        </div>

                        <div className="col-lg-2 col-md-12 flex-div-bottom">
                          <button
                            type="submit"
                            className="site-button btn-block m-2"
                            onClick={(e) => (
                              e.preventDefault(), fetchopportunities()
                            )}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="site-filters style1 clearfix left">
                      <Nav as="ul" className="filters">
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(-1)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="All">
                            <span
                              style={
                                step === -1
                                  ? { color: "#2e55fa" }
                                  : { color: "#000000" }
                              }
                            >
                              All
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(0)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="All">
                            <span
                              style={
                                step === 0
                                  ? { color: "#2e55fa" }
                                  : { color: "#000000" }
                              }
                            >
                              Recommended
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(1)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="Designing">
                            <span>Interested</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(2)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="Construct">
                            <span>Ongoing</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                    {jobsData.length === 0 ? (
                      <>
                        <NoDataPage />
                      </>
                    ) : step === -1 ? (
                      <>
                        <ul className="post-job-bx browse-job">
                          {jobsData
                            .slice(
                              (activePage - 1) * postsPerPage,
                              activePage * postsPerPage
                            )
                            .map((item, id) => (
                              <li key={id}>
                                <div className="post-bx">
                                  <div className="d-flex m-b10">
                                    <div className="job-post-company">
                                      <span>
                                        <img
                                          alt=""
                                          src={
                                            item.org_logo
                                              ? item.org_logo
                                              : require("./../../images/logo/icon1.png")
                                          }
                                        />
                                      </span>
                                    </div>
                                    <div className="job-post-info">
                                      <h4>
                                        {/* <Link to={"/job-detail"}> */}
                                        {/* Digital Marketing Executive */}
                                        {item.job_title ? item.job_title : "NA"}
                                        {/* </Link> */}
                                      </h4>
                                      <div className="d-flex-start-end">
                                        <p className="m-b0">
                                          {item.org_name ? item.org_name : "NA"}
                                        </p>
                                        <span
                                          className="dd-btn"
                                          onClick={() => (
                                            setToggle(!toggle),
                                            setViewId(!toggle ? id : "")
                                          )}
                                        >
                                          Show{" "}
                                          {!toggle
                                            ? "More"
                                            : viewId === id
                                            ? "Less"
                                            : viewId !== id
                                            ? "More"
                                            : "Less"}{" "}
                                        </span>
                                      </div>
                                      <p className="font-12 ">
                                        {/* <i className="fa fa-clock-o"></i>  */}
                                        {/* Posted 11 months ago */}
                                        {`Posted ${moment(item.createddt)
                                          .startOf("hour")
                                          .fromNow()}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex-space-between">
                                    <ul>
                                      <li>
                                        <i className="fa fa-briefcase"></i>{" "}
                                        {item.experience
                                          ? `${item.experience} yrs`
                                          : "NA"}
                                      </li>
                                      <li>
                                        <i className="fa fa-money"></i>{" "}
                                        {item.salary ? item.salary : "NA"}
                                      </li>
                                      <li>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        {`${
                                          item.district ? item.district : "NA"
                                        }, ${item.state ? item.state : "NA"}`}
                                      </li>
                                      {/* <li>
                                      <i className="fa fa-bookmark-o"></i> Full
                                      Time
                                    </li> */}
                                      {/* <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      Published 11 months ago
                                    </li> */}
                                    </ul>
                                    <div
                                      className="site-button button-sm float-right"
                                      onClick={() =>
                                        handleAddToOngoing(item.jobid)
                                      }
                                    >
                                      Apply Job
                                    </div>
                                  </div>
                                  <label className="like-btn">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        e.target.checked === true &&
                                        handleAddToInterested(item.jobid)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  {toggle && viewId === id && (
                                    <div className="job-info-box m-t10">
                                      <h5 className="font-weight-200 font-14">
                                        Job Description
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <p
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.jobdesc || "NA"
                                        )}
                                      ></p>
                                      <h5 className="font-weight-200 font-14 m-t10">
                                        Qualification Details
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <ul className="job-info mb-4">
                                        <li>
                                          <strong>
                                            Highest Qualification:
                                          </strong>{" "}
                                          {item.qualification
                                            ? item.qualification
                                            : "NA"}
                                        </li>
                                        <li>
                                          <strong>Gender:</strong>{" "}
                                          {item.gender ? item.gender : "NA"}
                                        </li>
                                        <li>
                                          <strong>Age:</strong>{" "}
                                          {item.age ? item.age : "NA"}
                                        </li>
                                        <li className="col-lg-12">
                                          <strong>Skills:</strong>{" "}
                                          {item?.skills ? item.skills : "NA"}
                                          {/* HTML,CSS,Javascript */}
                                        </li>
                                      </ul>
                                      <h5 className="font-weight-200 font-14 m-t10">
                                        Job Location
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <ul className="job-info">
                                        <li>
                                          <strong>State:</strong>{" "}
                                          {item.state ? item.state : "NA"}
                                        </li>
                                        <li>
                                          <strong>District:</strong>{" "}
                                          {item.district ? item.district : "NA"}
                                        </li>
                                        {/* <li>
                                        <strong>Taluka:</strong> Mumbai Suburban
                                      </li> */}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </li>
                            ))}
                        </ul>
                        <div className="pagination-bx m-t30 float-right">
                          <ul className="pagination">
                            <li
                              className={`previous ${
                                activePage === 1 ? "disabled" : ""
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  activePage !== 1 &&
                                  handlePageClick(activePage - 1)
                                }
                                disabled={activePage === 1 ? true : false}
                              >
                                <i className="ti-arrow-left"></i> Prev
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage - pagesPerGroup)
                                  }
                                >
                                  &laquo;
                                </Link>
                              </li>
                            )}

                            {paginationArray.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  activePage === pageNumber ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </Link>
                              </li>
                            ))}
                            {endPage < totalPages && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage + pagesPerGroup)
                                  }
                                >
                                  &raquo;
                                </Link>
                              </li>
                            )}
                            <li
                              className={`next ${
                                activePage ===
                                paginationArray[paginationArray.length - 1]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  activePage !==
                                    paginationArray[
                                      paginationArray.length - 1
                                    ] && handlePageClick(activePage + 1)
                                }
                                disabled={
                                  activePage ===
                                  paginationArray[paginationArray.length - 1]
                                }
                              >
                                Next <i className="ti-arrow-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : step === 0 ? (
                      <>
                        <ul className="post-job-bx browse-job">
                          {jobsData
                            .slice(
                              (activePage - 1) * postsPerPage,
                              activePage * postsPerPage
                            )
                            .map((item, id) => (
                              <li key={id}>
                                <div className="post-bx">
                                  <div className="d-flex m-b10">
                                    <div className="job-post-company">
                                      <span>
                                        <img
                                          alt=""
                                          src={
                                            item.org_logo
                                              ? item.org_logo
                                              : require("./../../images/logo/icon1.png")
                                          }
                                        />
                                      </span>
                                    </div>
                                    <div className="job-post-info">
                                      <h4>
                                        {/* <Link to={"/job-detail"}> */}
                                        {/* Digital Marketing Executive */}
                                        {item.job_title ? item.job_title : "NA"}
                                        {/* </Link> */}
                                      </h4>
                                      <div className="d-flex-start-end">
                                        <p className="m-b0">
                                          {item.org_name ? item.org_name : "NA"}
                                        </p>
                                        <span
                                          className="dd-btn"
                                          onClick={() => (
                                            setToggle(!toggle),
                                            setViewId(!toggle ? id : "")
                                          )}
                                        >
                                          Show{" "}
                                          {!toggle
                                            ? "More"
                                            : viewId === id
                                            ? "Less"
                                            : viewId !== id
                                            ? "More"
                                            : "Less"}{" "}
                                        </span>
                                      </div>
                                      <p className="font-12 ">
                                        {/* <i className="fa fa-clock-o"></i>  */}
                                        {/* Posted 11 months ago */}
                                        {`Posted ${moment(item.createddt)
                                          .startOf("hour")
                                          .fromNow()}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex-space-between">
                                    <ul>
                                      <li>
                                        <i className="fa fa-briefcase"></i>{" "}
                                        {item.experience
                                          ? `${item.experience} yrs`
                                          : "NA"}
                                      </li>
                                      <li>
                                        <i className="fa fa-money"></i>{" "}
                                        {item.salary ? item.salary : "NA"}
                                      </li>
                                      <li>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        {`${
                                          item.district ? item.district : "NA"
                                        }, ${item.state ? item.state : "NA"}`}
                                      </li>
                                      {/* <li>
                                      <i className="fa fa-bookmark-o"></i> Full
                                      Time
                                    </li> */}
                                      {/* <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      Published 11 months ago
                                    </li> */}
                                    </ul>
                                    <div
                                      className="site-button button-sm float-right"
                                      onClick={() =>
                                        handleAddToOngoing(item.jobid)
                                      }
                                    >
                                      Apply Job
                                    </div>
                                  </div>
                                  <label className="like-btn">
                                    <input
                                      type="checkbox"
                                      onChange={(e) =>
                                        e.target.checked === true &&
                                        handleAddToInterested(item.jobid)
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                  {toggle && viewId === id && (
                                    <div className="job-info-box m-t10">
                                      <h5 className="font-weight-200 font-14">
                                        Job Description
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <p
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.jobdesc || "NA"
                                        )}
                                      ></p>
                                      <h5 className="font-weight-200 font-14 m-t10">
                                        Qualification Details
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <ul className="job-info mb-4">
                                        <li>
                                          <strong>
                                            Highest Qualification:
                                          </strong>{" "}
                                          {item.qualification
                                            ? item.qualification
                                            : "NA"}
                                        </li>
                                        <li>
                                          <strong>Gender:</strong>{" "}
                                          {item.gender ? item.gender : "NA"}
                                        </li>
                                        <li>
                                          <strong>Age:</strong>{" "}
                                          {item.age ? item.age : "NA"}
                                        </li>
                                        <li className="col-lg-12">
                                          <strong>Skills:</strong>{" "}
                                          {item?.skills ? item.skills : "NA"}
                                          {/* HTML,CSS,Javascript */}
                                        </li>
                                      </ul>
                                      <h5 className="font-weight-200 font-14 m-t10">
                                        Job Location
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <ul className="job-info">
                                        <li>
                                          <strong>State:</strong>{" "}
                                          {item.state ? item.state : "NA"}
                                        </li>
                                        <li>
                                          <strong>District:</strong>{" "}
                                          {item.district ? item.district : "NA"}
                                        </li>
                                        {/* <li>
                                        <strong>Taluka:</strong> Mumbai Suburban
                                      </li> */}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </li>
                            ))}
                        </ul>
                        <div className="pagination-bx m-t30 float-right">
                          <ul className="pagination">
                            <li
                              className={`previous ${
                                activePage === 1 ? "disabled" : ""
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  activePage !== 1 &&
                                  handlePageClick(activePage - 1)
                                }
                                disabled={activePage === 1 ? true : false}
                              >
                                <i className="ti-arrow-left"></i> Prev
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage - pagesPerGroup)
                                  }
                                >
                                  &laquo;
                                </Link>
                              </li>
                            )}

                            {paginationArray.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  activePage === pageNumber ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </Link>
                              </li>
                            ))}
                            {endPage < totalPages && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage + pagesPerGroup)
                                  }
                                >
                                  &raquo;
                                </Link>
                              </li>
                            )}
                            <li
                              className={`next ${
                                activePage ===
                                paginationArray[paginationArray.length - 1]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  activePage !==
                                    paginationArray[
                                      paginationArray.length - 1
                                    ] && handlePageClick(activePage + 1)
                                }
                                disabled={
                                  activePage ===
                                  paginationArray[paginationArray.length - 1]
                                }
                              >
                                Next <i className="ti-arrow-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : step === 1 ? (
                      <>
                        <ul className="post-job-bx">
                          {jobsData
                            .slice(
                              (activePage - 1) * postsPerPage,
                              activePage * postsPerPage
                            )
                            .map((item, id) => (
                              <li key={id}>
                                <div className="post-bx">
                                  <div className="d-flex m-b10">
                                    <div className="job-post-company">
                                      <span>
                                        <img
                                          alt=""
                                          src={
                                            item.org_logo
                                              ? item.org_logo
                                              : require("./../../images/logo/icon1.png")
                                          }
                                        />
                                      </span>
                                    </div>
                                    <div className="job-post-info">
                                      <h4>
                                        {/* <Link to={"/job-detail"}> */}
                                        {/* Digital Marketing Executive */}
                                        {item.job_title}
                                        {/* </Link> */}
                                      </h4>
                                      <div className="d-flex-start-end">
                                        <p className="m-b0">{item.org_name}</p>
                                        <span
                                          className="dd-btn"
                                          onClick={() => (
                                            setToggle(!toggle),
                                            setViewId(!toggle ? id : "")
                                          )}
                                        >
                                          Show{" "}
                                          {!toggle
                                            ? "More"
                                            : viewId === id
                                            ? "Less"
                                            : viewId !== id
                                            ? "More"
                                            : "Less"}{" "}
                                        </span>
                                      </div>
                                      <p className="font-12 ">
                                        {/* <i className="fa fa-clock-o"></i>  */}
                                        {/* Posted 11 months ago */}
                                        {`Posted ${moment(item.createddt)
                                          .startOf("hour")
                                          .fromNow()}`}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex-space-between">
                                    <ul>
                                      <li>
                                        <i className="fa fa-briefcase"></i>{" "}
                                        {item.experience
                                          ? `${item.experience} yrs`
                                          : "NA"}
                                      </li>
                                      <li>
                                        <i className="fa fa-money"></i>{" "}
                                        {item.salary ? item.salary : "NA"}
                                      </li>
                                      <li>
                                        <i className="fa fa-map-marker"></i>{" "}
                                        {`${
                                          item.district ? item.district : "NA"
                                        }, ${item.state ? item.state : "NA"}`}
                                      </li>
                                      {/* <li>
                                      <i className="fa fa-bookmark-o"></i> Full
                                      Time
                                    </li> */}
                                      {/* <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      Published 11 months ago
                                    </li> */}
                                    </ul>
                                    <div
                                      className="site-button button-sm float-right"
                                      onClick={() =>
                                        handleAddToOngoing(item.jobid)
                                      }
                                    >
                                      Apply Job
                                    </div>
                                  </div>
                                  <label className="like-btn">
                                    <input type="checkbox" checked />
                                    <span className="checkmark"></span>
                                  </label>
                                  {toggle && viewId === id && (
                                    <div className="job-info-box m-t10">
                                      <h5 className="font-weight-200 font-14">
                                        Job Description
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <p
                                        dangerouslySetInnerHTML={createMarkup(
                                          item.jobdesc || "NA"
                                        )}
                                      ></p>
                                      <h5 className="font-weight-200 font-14 m-t10">
                                        Qualification Details
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <ul className="job-info mb-4">
                                        <li>
                                          <strong>
                                            Highest Qualification:
                                          </strong>{" "}
                                          {item.qualification
                                            ? item.qualification
                                            : "NA"}
                                        </li>
                                        <li>
                                          <strong>Gender:</strong>{" "}
                                          {item.gender ? item.gender : "NA"}
                                        </li>
                                        <li>
                                          <strong>Age:</strong>{" "}
                                          {item.age ? item.age : "NA"}
                                        </li>
                                        <li className="col-lg-12">
                                          <strong>Skills:</strong>{" "}
                                          {item?.skills ? item.skills : "NA"}
                                          {/* HTML,CSS,Javascript */}
                                        </li>
                                      </ul>
                                      <h5 className="font-weight-200 font-14 m-t10">
                                        Job Location
                                      </h5>
                                      <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                      <ul className="job-info">
                                        <li>
                                          <strong>State:</strong>{" "}
                                          {item.state ? item.state : "NA"}
                                        </li>
                                        <li>
                                          <strong>District:</strong>{" "}
                                          {item.district ? item.district : "NA"}
                                        </li>
                                        {/* <li>
                                        <strong>Taluka:</strong> Mumbai Suburban
                                      </li> */}
                                      </ul>
                                    </div>
                                  )}
                                </div>
                              </li>
                            ))}
                        </ul>
                        <div className="pagination-bx m-t30 float-right">
                          <ul className="pagination">
                            <li
                              className={`previous ${
                                activePage === 1 ? "disabled" : ""
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  activePage !== 1 &&
                                  handlePageClick(activePage - 1)
                                }
                                disabled={activePage === 1 ? true : false}
                              >
                                <i className="ti-arrow-left"></i> Prev
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage - pagesPerGroup)
                                  }
                                >
                                  &laquo;
                                </Link>
                              </li>
                            )}

                            {paginationArray.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  activePage === pageNumber ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </Link>
                              </li>
                            ))}
                            {endPage < totalPages && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage + pagesPerGroup)
                                  }
                                >
                                  &raquo;
                                </Link>
                              </li>
                            )}
                            <li
                              className={`next ${
                                activePage ===
                                paginationArray[paginationArray.length - 1]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  activePage !==
                                    paginationArray[
                                      paginationArray.length - 1
                                    ] && handlePageClick(activePage + 1)
                                }
                                disabled={
                                  activePage ===
                                  paginationArray[paginationArray.length - 1]
                                }
                              >
                                Next <i className="ti-arrow-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        <ul className="post-job-bx">
                          {jobsData
                            .slice(
                              (activePage - 1) * postsPerPage,
                              activePage * postsPerPage
                            )
                            .map((item, id) => (
                              <li key={id}>
                                <div className="post-bx">
                                  <div className="row">
                                    <div className="col-lg-1 col-md-12 p-0">
                                      <div className="job-post-company">
                                        <Link to={"#"}>
                                          <span>
                                            <img
                                              alt=""
                                              src={
                                                item.org_logo
                                                  ? item.org_logo
                                                  : require("./../../images/logo/icon1.png")
                                              }
                                            />
                                          </span>
                                        </Link>
                                      </div>
                                    </div>
                                    <div className="col-lg-11 col-md-12 d-flex-space-between">
                                      <div className="col-lg-6 col-md-12">
                                        <h4>
                                          {/* Data Scientists */}
                                          {/* <Link to={"/job-detail"}></Link> */}
                                          {item.job_title
                                            ? item.job_title
                                            : "NA"}
                                        </h4>
                                        <p className="m-b0">
                                          {item.org_name ? item.org_name : "NA"}
                                        </p>
                                        <p className="font-12 ">
                                          {/* <i className="fa fa-clock-o"></i>  */}
                                          {/* Posted 11 months ago */}
                                          {`Posted ${moment(item.createddt)
                                            .startOf("hour")
                                            .fromNow()}`}
                                        </p>
                                      </div>

                                      <div className="col-lg-4 col-md-12">
                                        <div className="job-time float-right">
                                          {/* Status:&nbsp; */}
                                          <Link to={"#"} className="mr-0">
                                            <span className="font-12">
                                              {/* Bot Successfully Engaged */}
                                              {item.status
                                                ? item.status
                                                : "Applied"}
                                            </span>
                                          </Link>
                                          <br />
                                          {/* <p className="text-right">22/12/2023</p> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 d-flex-space-between">
                                      <ul>
                                        <li>
                                          <i className="fa fa-briefcase"></i>{" "}
                                          {item.experience
                                            ? `${item.experience} yrs`
                                            : "NA"}
                                        </li>
                                        <li>
                                          <i className="fa fa-money"></i>{" "}
                                          {item.salary ? item.salary : "NA"}
                                        </li>
                                        <li>
                                          <i className="fa fa-map-marker"></i>{" "}
                                          {`${
                                            item.district ? item.district : "NA"
                                          }, ${item.state ? item.state : "NA"}`}
                                        </li>
                                        {/* <li>
                                      <i className="fa fa-bookmark-o"></i> Full
                                      Time
                                    </li> */}
                                        {/* <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      Published 11 months ago
                                    </li> */}
                                      </ul>
                                      <div
                                        className="dd-btn float-right"
                                        onClick={() => (
                                          setToggle(!toggle),
                                          setViewId(!toggle ? id : "")
                                        )}
                                      >
                                        Show{" "}
                                        {!toggle
                                          ? "More"
                                          : viewId === id
                                          ? "Less"
                                          : viewId !== id
                                          ? "More"
                                          : "Less"}{" "}
                                        <i
                                          className={
                                            !toggle
                                              ? "fa fa-chevron-down"
                                              : "fa fa-chevron-up"
                                          }
                                        ></i>
                                      </div>
                                    </div>

                                    {toggle && viewId === id && (
                                      <div className="col-lg-12 col-md-12">
                                        <hr />
                                      </div>
                                    )}

                                    {toggle && viewId === id ? (
                                      // <div className="d-flex">
                                      //   <div className="col-lg-12 col-md-12">
                                      //     <div classN="containertimeline">
                                      //       <ul className="timeline">
                                      //         <li className="active-tl">
                                      //           <label>
                                      //             Bot Successfully Engaged
                                      //           </label>
                                      //           <p>Monday</p>
                                      //           <p>01/02/2024</p>
                                      //         </li>
                                      //         <li className="">
                                      //           <label>
                                      //             Counsellor Engagement On
                                      //           </label>
                                      //           <p>Tuesday</p>
                                      //           <p>02/02/2024</p>
                                      //         </li>
                                      //       </ul>
                                      //     </div>
                                      //   </div>
                                      // </div>
                                      <Timleline
                                        type={"internships"}
                                        data={item.timeline}
                                      />
                                    ) : null}
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                        <div className="pagination-bx m-t30 float-right">
                          <ul className="pagination">
                            <li
                              className={`previous ${
                                activePage === 1 ? "disabled" : ""
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  activePage !== 1 &&
                                  handlePageClick(activePage - 1)
                                }
                                disabled={activePage === 1 ? true : false}
                              >
                                <i className="ti-arrow-left"></i> Prev
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage - pagesPerGroup)
                                  }
                                >
                                  &laquo;
                                </Link>
                              </li>
                            )}

                            {paginationArray.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  activePage === pageNumber ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </Link>
                              </li>
                            ))}
                            {endPage < totalPages && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage + pagesPerGroup)
                                  }
                                >
                                  &raquo;
                                </Link>
                              </li>
                            )}
                            <li
                              className={`next ${
                                activePage ===
                                paginationArray[paginationArray.length - 1]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  activePage !==
                                    paginationArray[
                                      paginationArray.length - 1
                                    ] && handlePageClick(activePage + 1)
                                }
                                disabled={
                                  activePage ===
                                  paginationArray[paginationArray.length - 1]
                                }
                              >
                                Next <i className="ti-arrow-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default BrowseInternshipList;
