export const RESUME_REQUEST = "RESUME_REQUEST";
export const RESUME_SUCCESS = "RESUME_SUCCESS";
export const RESUME_FAIL = "RESUME_FAIL";

export const RESUME_EDIT_REQUEST = "RESUME_EDIT_REQUEST";
export const RESUME_EDIT_SUCCESS = "RESUME_EDIT_SUCCESS";
export const RESUME_EDIT_FAIL = "RESUME_EDIT_FAIL";

export const HEADLINE_EDIT_REQUEST = "HEADLINE_EDIT_REQUEST";
export const HEADLINE_EDIT_SUCCESS = "HEADLINE_EDIT_SUCCESS";
export const HEADLINE_EDIT_FAIL = "HEADLINE_EDIT_FAIL";

export const SKILLS_EDIT_REQUEST = "SKILLS_EDIT_REQUEST";
export const SKILLS_EDIT_SUCCESS = "SKILLS_EDIT_SUCCESS";
export const SKILLS_EDIT_FAIL = "SKILLS_EDIT_FAIL";

export const INTEREST_EDIT_REQUEST = "INTEREST_EDIT_REQUEST";
export const INTEREST_EDIT_SUCCESS = "INTEREST_EDIT_SUCCESS";
export const INTEREST_EDIT_FAIL = "INTEREST_EDIT_FAIL";

export const EMPLOYMENT_EDIT_REQUEST = "EMPLOYMENT_EDIT_REQUEST";
export const EMPLOYMENT_EDIT_SUCCESS = "EMPLOYMENT_EDIT_SUCCESS";
export const EMPLOYMENT_EDIT_FAIL = "EMPLOYMENT_EDIT_FAIL";

export const EDUCATION_EDIT_REQUEST = "EDUCATION_EDIT_REQUEST";
export const EDUCATION_EDIT_SUCCESS = "EDUCATION_EDIT_SUCCESS";
export const EDUCATION_EDIT_FAIL = "EDUCATION_EDIT_FAIL";

export const RESUME_FILE_EDIT_REQUEST = "RESUME_FILE_EDIT_REQUEST";
export const RESUME_FILE_EDIT_SUCCESS = "RESUME_FILE_EDIT_SUCCESS";
export const RESUME_FILE_EDIT_FAIL = "RESUME_FILE_EDIT_FAIL";
