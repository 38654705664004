import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Profilesidebar from "./../Element/Profilesidebar";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { editprofile, fetchprofile } from "../../redux/actions/ProfileActions";
import axios from "axios";
import moment from "moment";

function Jobprofile({ location }) {
  const dispatch = useDispatch();
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [stateselected, setStateselected] = useState([]);
  const [educationOption, setEducationOption] = useState([]);
  const [degreeOption, setDegreeOption] = useState([]);
  const [streamOption, setStreamOption] = useState([]);
  const [profilenew, setProfilenew] = useState({
    // fullname: "",
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    gender: "",
    dob: "",
    highest_education: "",
    degree: "",
    stream: "",
    state: "",
    district: "",
    taluka: "",
    registration_id: "",
  });
  const profile = useSelector((state) => state.profile);
  const editProfile = useSelector((state) => state.editprofile);
  const profileData = profile?.profileInfo?.data;
  const { loading, profileDetails, error } = profile;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  console.log(profile, "AAA11");
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  useEffect(() => {
    dispatch(fetchprofile());
  }, [editProfile]);

  console.log(error, loading, profileDetails);

  console.log(userInfo, "AAA");

  useEffect(() => {
    const fetchStateList = () => {
      axios
        .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`)
        .then((res) => {
          console.log("State", res.data);
          // var StateList = res.data[0].sort().map(function (item) {
          //   return { label: item, value: item };
          // });
          setState(res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchStateList();
  }, []);
  useEffect(() => {
    const params = {
      state: selectedState,
    };
    const paramString = new URLSearchParams(params).toString();
    const fetchCity = () => {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
          // {
          //   state: selectedState,
          // }
        )
        .then((res) => {
          setDistrict(res.data);

          console.log("District", res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    //fetchStateList();
    fetchCity();
  }, [selectedState]);

  useEffect(() => {
    console.log(profile?.profileInfo?.data.mobile.length, "112");
    setProfilenew({
      // fullname: "",
      first_name: profile?.profileInfo?.data.first_name
        ? profile?.profileInfo?.data.first_name
        : "",
      last_name: profile?.profileInfo?.data.last_name
        ? profile?.profileInfo?.data.last_name
        : "",
      mobile: profile?.profileInfo?.data.mobile
        ? profile?.profileInfo?.data.mobile.length == 12
          ? profile?.profileInfo?.data.mobile.slice(2)
          : profile?.profileInfo?.data.mobile
        : "",
      email: profile?.profileInfo?.data.email
        ? profile?.profileInfo?.data.email
        : "",
      gender: profile?.profileInfo?.data.gender
        ? profile?.profileInfo?.data.gender
        : "",
      dob: profile?.profileInfo?.data.dob ? profile?.profileInfo?.data.dob : "",
      highest_education: profile?.profileInfo?.data.highest_education
        ? profile?.profileInfo?.data.highest_education
        : "",
      degree: profile?.profileInfo?.data.degree
        ? profile?.profileInfo?.data.degree
        : "",
      stream: profile?.profileInfo?.data.stream
        ? profile?.profileInfo?.data.stream
        : "",
      state: profile?.profileInfo?.data.state
        ? profile?.profileInfo?.data.state
        : "",
      district: profile?.profileInfo?.data.district
        ? profile?.profileInfo?.data.district
        : "",
      taluka: profile?.profileInfo?.data.taluka
        ? profile?.profileInfo?.data.taluka
        : "",
      registration_id: profile?.profileInfo?.data.registration_id
        ? profile?.profileInfo?.data.registration_id
        : "",
      profile: profile?.profileInfo?.data.profile
        ? profile?.profileInfo?.data.profile
        : "",
      is_active: profile?.profileInfo?.data.is_active
        ? profile?.profileInfo?.data.is_active
        : "",
      skills: profile?.profileInfo?.data.skills
        ? profile?.profileInfo?.data.skills
        : "",
    });
    setSelectedState(
      profile?.profileInfo?.data.state ? profile?.profileInfo?.data.state : ""
    );
  }, [profile]);

  /*
	useEffect(()=>{
		setProfilenew({
			...profilenew,
			first_name:userInfo?.Name,
			last_name:"",
			mobile:"",
			email:""
		})
	},[userInfo])
	*/

  const FetchState = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`
      );
      const data = await res?.data;
      setStateOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchEducation = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`
      );
      const data = await res?.data;
      setEducationOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchDegree = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/degree`
      );
      const data = await res?.data;
      setDegreeOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchStream = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`
      );
      const data = await res?.data;
      setStreamOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchDistrict = async () => {
    try {
      const params = {
        state: stateselected,
      };
      const paramString = new URLSearchParams(params).toString();
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
      );
      const data = await res?.data;
      setDistrictOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   FetchDistrict();
  // }, [stateselected]);

  useEffect(() => {
    FetchState();
    FetchEducation();
    FetchDegree();
    FetchStream();
  }, []);

  return (
    <>
      <Header location={location} />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        Basic Information
                      </h5>
                      {/* <Link
                        to={"/profile"}
                        className="site-button right-arrow button-sm float-right"
                      >
                        Back
                      </Link> */}
                    </div>
                    <div className="row m-b15">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>First Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type Your First Name"
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                first_name: e.target.value,
                              })
                            }
                            value={profilenew.first_name}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Last Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type Your Last Name"
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                last_name: e.target.value,
                              })
                            }
                            value={profilenew.last_name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-b15">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Email Address:</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Type Your Email Address"
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                email: e.target.value,
                              })
                            }
                            value={profilenew.email}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Mobile Number:</label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder="Type Your 10 Digit Mobile Number"
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                mobile: e.target.value,
                              })
                            }
                            value={profilenew.mobile}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row m-b15">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Date of Birth:</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                dob: e.target.value,
                              })
                            }
                            value={profilenew.dob}
                          />
                        </div>
                      </div>

                      {/*
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>Age:</label>
													<input type="number" className="form-control" placeholder="" />
												</div>
											</div>
											*/}

                      <div className="col-lg-6 col-md-6">
                        <label>Gender:</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              gender: e.target.value,
                            })
                          }
                          value={profilenew.gender}
                        >
                          <option>Select</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Others</option>
                        </Form.Control>
                      </div>
                    </div>

                    <div className="row m-b15">
                      <div className="col-lg-6 col-md-6">
                        <label>State</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) => {
                            console.log(e.target.value, "123");
                            setProfilenew({
                              ...profilenew,
                              state: e.target.value,
                            });
                            setSelectedState(e.target.value);
                          }}
                          value={profilenew.state}
                        >
                          <option>Select</option>
                          {state?.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <label>District</label>
                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          value={profilenew.district}
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              district: e.target.value,
                            })
                          }
                        >
                          <option>Select</option>
                          {district?.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>

                      {/* <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Taluka</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </div> */}
                    </div>

                    <div className="row m-b15">
                      <div className="col-lg-6 col-md-6">
                        <label>Highest Education:</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              highest_education: e.target.value,
                            })
                          }
                          value={profilenew.highest_education}
                        >
                          <option>Select</option>
                          {educationOption?.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <label>Degree:</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              degree: e.target.value,
                            })
                          }
                          value={profilenew.degree}
                        >
                          <option>Select</option>
                          {degreeOption?.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>
                    </div>
                    <div className="row m-b15">
                      <div className="col-lg-6 col-md-6">
                        <label>Stream:</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              stream: e.target.value,
                            })
                          }
                          value={profilenew.stream}
                        >
                          <option>Select</option>
                          {streamOption.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>
                    </div>

                    <button
                      className="site-button m-1"
                      onClick={() => dispatch(editprofile(profilenew))}
                    >
                      Save
                    </button>

                    <button className="site-button-secondry m-1">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Jobprofile;
