import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logo/SakshamLogo.svg";
import E_logo from "../../images/Email_L.png";
import Fb_logo from "../../images/Fb_logo.png";
import X_logo from "../../images/X_logo.png";
import YT_logo from "../../images/YT_logo.png";
import IG_logo from "../../images/IG_logo.png";
import LI_logo from "../../images/LI_logo.png";
import FooterBG from "../../images/FooterBG.png";

function Footer() {
  return (
    <footer
      className="w-100 shadow p-5 pb-4 relative mt-4"
      style={{
        backgroundImage: `url(${FooterBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-3 g-3 row">
        <div className="w-full max-w-screen-xl mx-auto pt-10 col-12 col-md-4">
          <div className="flex justify-center items-center">
            <img
              src={
                Object.keys(sessionStorage).includes("labelData")
                  ? JSON.parse(sessionStorage.getItem("labelData"))["logo"]
                  : Logo
              }
              alt="Logo"
              className="w-[96px] h-[81px]"
            />
          </div>
          <div className="mt-5">
            <p className="text-center text-[14px] leading-[17px] font-normal text-[#FFFFFF]">
              An apex mission to end unemployment in India. An initiative by
              TIFAC, an autonomous organisation under the Government of India
              and implemented by Sapio Analytics.
            </p>
          </div>
        </div>

        <div className="container-fluid col-12 col-md-4">
          <div className="row">
            <div className="">
              <div className="d-flex justify-content-between">
                <div className="">
                  <h2 className="mb-4 fs-5 text-white">Quick Links</h2>
                  <ul className="list-unstyled text-muted text-center">
                    <li className="mb-3 text-white">
                      <a
                        href="#"
                        className="text-decoration-none text-white hover-underline"
                      >
                        Skills
                      </a>
                    </li>
                    <li className="mb-3 text-white">
                      <a
                        href="#"
                        className="text-decoration-none text-white hover-underline"
                      >
                        Jobs
                      </a>
                    </li>
                    <li className="mb-3 text-white">
                      <a
                        href="#"
                        className="text-decoration-none text-white hover-underline"
                      >
                        Job Fair
                      </a>
                    </li>
                    <li className="mb-3 text-white">
                      <a
                        href="#"
                        className="text-decoration-none text-white hover-underline"
                      >
                        About Us
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="d-flex justify-content-center align-items-center">
                  <svg
                    width="1"
                    height=""
                    viewBox="0 0 1 157"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.5"
                      y1="0"
                      x2="0.500007"
                      y2="157"
                      stroke="white"
                    />
                  </svg>
                </div>

                <div className="">
                  <h2 className="mb-4 fs-5 text-white">Useful Links</h2>
                  <ul className="list-unstyled text-muted text-center ">
                    <li className="mb-3 text-white">
                      <a
                        href="#"
                        className="text-decoration-none text-white hover-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li className="mb-3 text-white">
                      <a
                        href="#"
                        className="text-decoration-none text-white hover-underline"
                      >
                        Success Stories
                      </a>
                    </li>
                    <li className="mb-3 text-white">
                      <a
                        href="/Privacy-Policy-Saksham.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none text-white hover-underline"
                      >
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Paragraph Below the Grid Boxes */}
              <p className="mt-4 text-white" style={{ fontSize: "0.75rem" }}>
                Disclaimer: Shramik and industry information on this portal will
                only be used to increase employment opportunities and for Govt.
                policy formation regarding skill assessment, gs, needs, and
                training through data analytics. Only workers/industries who
                give consent will have information shared.
              </p>
            </div>
          </div>
        </div>

        <div className="container-fluid px-4 py-4 col-12 col-md-4">
          <div className="row">
            <div className="d-flex flex-column align-items-center align-md-start">
              <h5
                className="text-white font-weight-semibold mb-3"
                style={{ fontSize: "18px" }}
              >
                Subscribe Now
              </h5>
              <p
                className="text-white mb-4"
                style={{ fontSize: "16px", lineHeight: "17px" }}
              >
                Don’t miss our future updates! Get Subscribed Today!
              </p>
              <form className="d-flex align-items-start justify-content-around  w-100">
                <input
                  type="email"
                  placeholder="Enter your email here"
                  className="form-control"
                  style={{
                    maxWidth: "280px",
                    height: "36px",
                    padding: "0 16px",
                    color: "black",
                    borderRadius: "0.375rem",
                    border: "1px solid #ced4da",
                    boxShadow: "none",
                  }}
                  required
                />
                <button
                  type="submit"
                  className="btn"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "5px",
                    width: "36px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "none",
                  }}
                >
                  <img
                    src={E_logo}
                    alt="Submit"
                    style={{ width: "24px", height: "24px", maxWidth: "24px" }}
                  />
                </button>
              </form>
              <p
                className="text-white font-weight-semibold py-4"
                style={{ fontSize: "18px", textDecoration: "underline" }}
              >
                Connect with us
              </p>
              <ul
                className="d-flex g-3 list-unstyled w-100"
                style={{ display: "flex", gap: "10px" }}
              >
                <li>
                  <a
                    href="https://www.linkedin.com/company/sakshamtifac/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={Fb_logo}
                      alt="LinkedIn"
                      style={{ width: "34px", height: "34px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/sakshamtifac/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={X_logo}
                      alt="Instagram"
                      style={{ width: "34px", height: "34px" }}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/people/Saksham/100068920491625/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={LI_logo}
                      alt="Facebook"
                      style={{ width: "34px", height: "34px" }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p className="pt-10 flex text-center text-white justify-center font-normal text-[12px]">
        ©2024. Saksham by TIFAC. All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
