import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import Swal from "sweetalert2";
import PageTitle from "./../Layout/PageTitle";
import Jobfindbox from "./../Element/Jobfindbox";

//Images
import Icon1 from "./../../images/logo/icon1.png";
import bnr from "./../../images/banner/bnr1.jpg";
import { Form, Nav } from "react-bootstrap";
import Profilesidebar from "../Element/Profilesidebar";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { fetchopportunities } from "../../redux/actions/OpportunitiesActions";
import { fetchprofile } from "../../redux/actions/ProfileActions";
import Timleline from "./Timleline";
import NoDataPage from "./NoDataPage";

const jobPost = [
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
];
const brandLogo = [
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
  { image: Icon1 },
];

const timeline = [
  {
    company: "Sapio",
    job_role: "Data Analytics",
    stage: [
      {
        stage: 1,
        step: "Bot Sucecessfully Engaged",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 2,
        step: "Counsellor Engagement On",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 3,
        step: "Interview Process Initiated",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 4,
        step: "Interview Process On",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 5,
        step: "Final Selection Stage",
        company: "company",
        status: true,
        date: "2024-03-04",
      },
      {
        stage: 6,
        step: "Post Selection Stage",
        company: "company",
        status: false,
        date: "2024-03-04",
      },
    ],
  },
];

function EventsJobs({ location }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [toggle, setToggle] = useState("");
  const [viewId, setViewId] = useState("");
  const [step, setStep] = useState(0);
  const [jobsData, setJobsData] = useState([]);
  const [qualificationDD, setQualificationDD] = useState([]);
  const [districtDD, setDistrictDD] = useState([]);
  const [searchObj, setSearchObj] = useState({
    job_title: "",
    qualification: "",
    district: "",
  });
  // const [totalPages, setTotalPages] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  // Usage in component
  const query = useQuery();
  const jfId = query.get("jfid");

  // const dispatch = useDispatch();
  // const opportunitiesData = useSelector(
  //   (state) => state.opportunity.opportunityInfo
  // );
  // const opportunityInfo = opportunitiesData.opportunityInfo;

  // useEffect(() => {
  //   dispatch(fetchopportunities());
  //   // dispatch(fetchprofile());
  // }, [dispatch]);

  // console.log("recomended::", opportunitiesData);

  // const postBlog = opportunitiesData;

  // const postBlog = [
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  //   {
  //     image: require("./../../images/logo/icon1.png"),
  //   },
  // ];

  const interestedJobs = [
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
  ];

  const ongoingJobs = [
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
    {
      image: require("./../../images/logo/icon1.png"),
    },
  ];
  const postsPerPage = 4;
  const pagesPerGroup = 5;
  const totalPages = Math.ceil(jobsData.length / postsPerPage);
  const startPage =
    Math.floor((activePage - 1) / pagesPerGroup) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);
  // let i = 0;
  const paginationArray = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationArray.push(i);
  }
  // while (i < totalPages) {
  //   paginationArray.push(i + 1);
  //   i++;
  // }

  const currentPosts = jobsData.slice(
    (activePage - 1) * postsPerPage,
    activePage * postsPerPage
  );

  console.log("page nos:", paginationArray, totalPages);

  const fetchopportunities = async () => {
    let url =
      step === 0
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobpost-list`
        : step === 1
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/applied_jobs`
        : step === 2
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/underverification_jobs`
        : step === 3
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/selected_jobs`
        : `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/joined_jobs`;

    // jobfair/underverification_jobs
    // jobfair/selected_jobs
    // jobfair/joined_jobs
    await axios
      .get(url, {
        params: {
          registration_id: userInfo["Registration ID"],
          jf_id: jfId || "",
          // job_title: searchObj.job_title || "",
          // qualification: searchObj.qualification || "",
          // district: searchObj.district || "",
        },
      })
      .then((res) => {
        let data = res.data.data;
        if (data.length === 0) {
          // setJobsData([
          //   {
          //     image: require("./../../images/logo/icon1.png"),
          //   },
          //   {
          //     image: require("./../../images/logo/icon1.png"),
          //   },
          //   {
          //     image: require("./../../images/logo/icon1.png"),
          //   },
          //   {
          //     image: require("./../../images/logo/icon1.png"),
          //   },
          // ]);
          setJobsData(data);
        } else {
          setJobsData(data);
        }
      });
  };

  const fetchSearch = async ({ job_title, qualification, district }) => {
    let url =
      step === 0
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobpost-list`
        : step === 1
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/applied_jobs`
        : step === 2
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/underverification_jobs`
        : step === 3
        ? `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/selected_jobs`
        : `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/joined_jobs`;
    console.log("search::", { job_title, qualification, district });
    await axios
      .get(url, {
        params: {
          registration_id: userInfo["Registration ID"],
          jf_id: jfId || "",
          job_title: job_title,
          qualification: qualification,
          district: district,
        },
      })
      .then((res) => {
        let data = res.data.data;
        if (data.length === 0) {
          setJobsData(data);
        } else {
          setJobsData(data);
        }
      });
  };

  const handleApply = async (jobid) => {
    let url = `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/apply_job`;

    await axios
      .post(url, {
        job_ids: [jobid],
        registration_id: userInfo["Registration ID"],
      })
      .then((res) => {
        // let data = step === 0 ? res.data.recommended : res.data.data;
        // setJobsData(data);
        Swal.fire({
          icon: "success",
          title: "Job application successful",
          //text: res.data["User Message"],
          showConfirmButton: false,
          // position: "center",
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        }).then((res) => {
          fetchopportunities();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong...",
          //text: res.data["User Message"],
          //   position: "center",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const handleAddToInterested = async (jobid) => {
    let url = `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/apply-interested`;

    await axios
      .post(url, {
        job_id: jobid,
        registration_id: userInfo["Registration ID"],
      })
      .then((res) => {
        // let data = step === 0 ? res.data.recommended : res.data.data;
        // setJobsData(data);
        fetchopportunities();
      });
  };

  const fetchQualification = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/qualification`)
      .then((res) => {
        setQualificationDD(res.data.filter((ele, id) => ele.label !== ""));
      })
      .catch((err) => console.log(err));
  };

  const fetchDistrict = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/district`)
      .then((res) => {
        setDistrictDD(res.data);
      })
      .catch((err) => console.log(err));
  };

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
  };

  useEffect(() => {
    fetchopportunities();
    setViewId("");
    setActivePage(1);
    setToggle("");
  }, [step]);

  useEffect(() => {
    fetchDistrict();
    fetchQualification();
  }, []);
  console.log("params::", jfId, query);
  // console.log("location::", window.location);

  return (
    <>
      <Header location={location} />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white browse-job p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar />

                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx job-profile">
                    <Link
                      to={"/events-jobfair"}
                      className="site-button right-arrow button-sm float-right"
                    >
                      Back
                    </Link>
                  </div>
                  <div className="job-bx job-profile">
                    <form className="pb-4">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 p-2">
                          <label>Job title</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Job title"
                            onChange={(e) =>
                              setSearchObj({
                                ...searchObj,
                                job_title: e.target.value,
                              })
                            }
                            value={searchObj.job_title}
                          />
                        </div>

                        <div className="col-lg-3 col-md-6 p-2">
                          <label>Select Qualification</label>
                          <Form.Control
                            as="select"
                            custom
                            className="btn dropdown-toggle text-left btn-default"
                            placeholder="Select Qualification"
                            onChange={(e) =>
                              setSearchObj({
                                ...searchObj,
                                qualification: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Qualification</option>
                            {qualificationDD?.map((v, id) => {
                              return (
                                <option key={id} value={v.value}>
                                  {v.label}
                                </option>
                              );
                            })}
                            {/* <option>Front End Developer</option>
                            <option>Back End Developer</option> */}
                          </Form.Control>
                        </div>
                        <div className="col-lg-3 col-md-6 p-2">
                          <label>Select District</label>
                          <Form.Control
                            as="select"
                            custom
                            className="btn dropdown-toggle text-left btn-default"
                            placeholder="Select Disrict"
                            onChange={(e) =>
                              setSearchObj({
                                ...searchObj,
                                district: e.target.value,
                              })
                            }
                          >
                            <option value="">Select District</option>
                            {districtDD?.map((v, id) => {
                              return (
                                <option key={id} value={v.value}>
                                  {v.label}
                                </option>
                              );
                            })}
                            {/* <option>Front End Developer</option>
                            <option>Back End Developer</option> */}
                          </Form.Control>
                        </div>

                        <div className="col-lg-2 col-md-12 flex-div-bottom">
                          <button
                            type="submit"
                            className="site-button btn-block m-2"
                            onClick={(e) => (
                              e.preventDefault(), fetchSearch(searchObj)
                            )}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </form>

                    <div className="site-filters style1 clearfix left">
                      <Nav as="ul" className="filters">
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(0)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="All">
                            <span
                              style={
                                step === 0
                                  ? { color: "#2e55fa" }
                                  : { color: "#000000" }
                              }
                            >
                              Recommended
                            </span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(1)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="Applied">
                            <span>Applied</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(2)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="Verify">
                            <span>Under Verification</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(3)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="Selected">
                            <span>Selected</span>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item
                          as="li"
                          className="btn"
                          onClick={() => setStep(4)}
                        >
                          <input type="radio" />
                          <Nav.Link to={"#"} eventKey="Joined">
                            <span>Joined</span>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>

                    {jobsData.length === 0 ? (
                      <>
                        <NoDataPage step={step} />
                      </>
                    ) : step === 0 ? (
                      <>
                        <ul className="post-job-bx browse-job">
                          {currentPosts.map((item, id) => (
                            <li key={id}>
                              <div className="post-bx">
                                <div className="d-flex m-b10">
                                  <div className="job-post-company">
                                    <span>
                                      <img
                                        alt=""
                                        src={
                                          item.image
                                            ? item.image
                                            : require("./../../images/logo/icon1.png")
                                        }
                                      />
                                    </span>
                                  </div>
                                  <div className="job-post-info">
                                    <h4>
                                      {/* <Link to={"/job-detail"}> */}
                                      {/* Digital Marketing Executive */}
                                      {item.job_title ? item.job_title : "NA"}
                                      {/* </Link> */}
                                    </h4>
                                    <div className="d-flex-start-end">
                                      <p className="m-b0">
                                        {item.company_name
                                          ? item.company_name
                                          : "NA"}
                                      </p>
                                      <span
                                        className="dd-btn"
                                        onClick={() => (
                                          setToggle(!toggle),
                                          setViewId(!toggle ? id : "")
                                        )}
                                      >
                                        Show{" "}
                                        {!toggle
                                          ? "More"
                                          : viewId === id
                                          ? "Less"
                                          : viewId !== id
                                          ? "More"
                                          : "Less"}{" "}
                                      </span>
                                    </div>
                                    <p className="font-12 ">
                                      {/* <i className="fa fa-clock-o"></i>  */}
                                      {/* Posted 11 months ago */}
                                      <i className="fa fa-calendar"></i>{" "}
                                      {`Last date of application: ${
                                        item.valid_till
                                          ? moment(item.valid_till).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "NA"
                                      }`}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex-space-between">
                                  <ul>
                                    <li>
                                      <i className="fa fa-map-marker"></i>{" "}
                                      {`${
                                        item.district ? item.district : "NA"
                                      }, ${item.state ? item.state : "NA"}`}
                                    </li>
                                    <li>
                                      <i className="fa fa-bookmark-o"></i>{" "}
                                      {item.vacancy_type
                                        ? item.vacancy_type
                                        : "NA"}
                                    </li>

                                    <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      {`Posted ${moment(item.createddt)
                                        .startOf("hour")
                                        .fromNow()}`}
                                    </li>
                                    {/* <li>
                                      <i className="fa fa-bookmark-o"></i> Full
                                      Time
                                    </li> */}
                                    {/* <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      Published 11 months ago
                                    </li> */}
                                  </ul>
                                  <div
                                    className="site-button button-sm float-right"
                                    onClick={() => handleApply(item.jpo_id)}
                                  >
                                    Apply Job
                                  </div>
                                </div>
                                {/* <label className="like-btn">
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label> */}
                                {toggle && viewId === id && (
                                  <div className="job-info-box m-t10">
                                    <h5 className="font-weight-200 font-14">
                                      Job Description
                                    </h5>
                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                    <p
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.jobdesc || "NA"
                                      )}
                                    ></p>
                                    <h5 className="font-weight-200 font-14 m-t10">
                                      Qualification Details
                                    </h5>
                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                    <ul className="job-info mb-4">
                                      <li>
                                        <strong>Highest Qualification:</strong>{" "}
                                        {item.qualification
                                          ? item.qualification
                                          : "NA"}
                                      </li>
                                      <li>
                                        <strong>Gender:</strong>{" "}
                                        {item.gender ? item.gender : "NA"}
                                      </li>
                                      <li>
                                        <strong>Age:</strong>{" "}
                                        {item.age ? item.age : "NA"}
                                      </li>
                                      <li className="col-lg-12">
                                        <strong>Skills:</strong>{" "}
                                        {item?.skills ? item.skills : "NA"}
                                        {/* HTML,CSS,Javascript */}
                                      </li>
                                    </ul>
                                    <h5 className="font-weight-200 font-14 m-t10">
                                      Job Location
                                    </h5>
                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                    <ul className="job-info">
                                      <li>
                                        <strong>State:</strong>{" "}
                                        {item.state ? item.state : "NA"}
                                      </li>
                                      <li>
                                        <strong>District:</strong>{" "}
                                        {item.district ? item.district : "NA"}
                                      </li>
                                      <li>
                                        <strong>Taluka:</strong>{" "}
                                        {item.taluka ? item.taluka : "NA"}
                                      </li>
                                      <li>
                                        <strong>City:</strong>{" "}
                                        {item.city_village
                                          ? item.city_village
                                          : "NA"}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="pagination-bx m-t30">
                          <ul className="pagination">
                            <li
                              className={`previous ${
                                activePage === paginationArray[0]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  activePage !== paginationArray[0] &&
                                  handlePageClick(activePage - 1)
                                }
                                disabled={
                                  activePage === paginationArray[0]
                                    ? true
                                    : false
                                }
                              >
                                <i className="ti-arrow-left"></i> Prev
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage - pagesPerGroup)
                                  }
                                >
                                  &laquo;
                                </Link>
                              </li>
                            )}
                            {paginationArray.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  activePage === pageNumber ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </Link>
                              </li>
                            ))}
                            {endPage < totalPages && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage + pagesPerGroup)
                                  }
                                >
                                  &raquo;
                                </Link>
                              </li>
                            )}
                            <li
                              className={`next ${
                                activePage ===
                                paginationArray[paginationArray.length - 1]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  activePage !==
                                    paginationArray[
                                      paginationArray.length - 1
                                    ] && handlePageClick(activePage + 1)
                                }
                                disabled={
                                  activePage ===
                                  paginationArray[paginationArray.length - 1]
                                }
                              >
                                Next <i className="ti-arrow-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <>
                        <ul className="post-job-bx browse-job">
                          {currentPosts.map((item, id) => (
                            <li key={id}>
                              <div className="post-bx">
                                <div className="d-flex m-b10">
                                  <div className="job-post-company">
                                    <span>
                                      <img
                                        alt=""
                                        src={
                                          item.image
                                            ? item.image
                                            : require("./../../images/logo/icon1.png")
                                        }
                                      />
                                    </span>
                                  </div>
                                  <div className="job-post-info">
                                    <h4>
                                      {/* <Link to={"/job-detail"}> */}
                                      {/* Digital Marketing Executive */}
                                      {item.job_title ? item.job_title : "NA"}
                                      {/* </Link> */}
                                    </h4>
                                    <div className="d-flex-start-end">
                                      <p className="m-b0">
                                        {item.company_name
                                          ? item.company_name
                                          : "NA"}
                                      </p>
                                      <span
                                        className="dd-btn"
                                        onClick={() => (
                                          setToggle(!toggle),
                                          setViewId(!toggle ? id : "")
                                        )}
                                      >
                                        Show{" "}
                                        {!toggle
                                          ? "More"
                                          : viewId === id
                                          ? "Less"
                                          : viewId !== id
                                          ? "More"
                                          : "Less"}{" "}
                                      </span>
                                    </div>
                                    <p className="font-12 ">
                                      {/* <i className="fa fa-clock-o"></i>  */}
                                      {/* Posted 11 months ago */}
                                      <i className="fa fa-calendar"></i>{" "}
                                      {`Last date of application: ${
                                        item.valid_till
                                          ? moment(item.valid_till).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "NA"
                                      }`}
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex-space-between">
                                  <ul>
                                    <li>
                                      <i className="fa fa-map-marker"></i>{" "}
                                      {`${
                                        item.district ? item.district : "NA"
                                      }, ${item.state ? item.state : "NA"}`}
                                    </li>
                                    <li>
                                      <i className="fa fa-bookmark-o"></i>{" "}
                                      {item.vacancy_type
                                        ? item.vacancy_type
                                        : "NA"}
                                    </li>

                                    <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      {`Posted ${moment(item.createddt)
                                        .startOf("hour")
                                        .fromNow()}`}
                                    </li>
                                    {/* <li>
                                      <i className="fa fa-bookmark-o"></i> Full
                                      Time
                                    </li> */}
                                    {/* <li>
                                      <i className="fa fa-clock-o"></i>{" "}
                                      Published 11 months ago
                                    </li> */}
                                  </ul>
                                  {/* <div className="site-button button-sm float-right">
                                    Apply Job
                                  </div> */}
                                </div>
                                {/* <label className="like-btn">
                                  <input type="checkbox" />
                                  <span className="checkmark"></span>
                                </label> */}
                                {toggle && viewId === id && (
                                  <div className="job-info-box m-t10">
                                    <h5 className="font-weight-200 font-14">
                                      Job Description
                                    </h5>
                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                    <p
                                      dangerouslySetInnerHTML={createMarkup(
                                        item.jobdesc || "NA"
                                      )}
                                    ></p>
                                    <h5 className="font-weight-200 font-14 m-t10">
                                      Qualification Details
                                    </h5>
                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                    <ul className="job-info mb-4">
                                      <li>
                                        <strong>Highest Qualification:</strong>{" "}
                                        {item.qualification
                                          ? item.qualification
                                          : "NA"}
                                      </li>
                                      <li>
                                        <strong>Gender:</strong>{" "}
                                        {item.gender ? item.gender : "NA"}
                                      </li>
                                      <li>
                                        <strong>Age:</strong>{" "}
                                        {item.age ? item.age : "NA"}
                                      </li>
                                      <li className="col-lg-12">
                                        <strong>Skills:</strong>{" "}
                                        {item?.skills ? item.skills : "NA"}
                                        {/* HTML,CSS,Javascript */}
                                      </li>
                                    </ul>
                                    <h5 className="font-weight-200 font-14 m-t10">
                                      Job Location
                                    </h5>
                                    <div className="dez-divider divider-2px bg-gray-dark mb-4 mt-0"></div>
                                    <ul className="job-info">
                                      <li>
                                        <strong>State:</strong>{" "}
                                        {item.state ? item.state : "NA"}
                                      </li>
                                      <li>
                                        <strong>District:</strong>{" "}
                                        {item.district ? item.district : "NA"}
                                      </li>
                                      <li>
                                        <strong>Taluka:</strong>{" "}
                                        {item.taluka ? item.taluka : "NA"}
                                      </li>
                                      <li>
                                        <strong>City:</strong>{" "}
                                        {item.city_village
                                          ? item.city_village
                                          : "NA"}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div className="pagination-bx m-t30">
                          <ul className="pagination">
                            <li
                              className={`previous ${
                                activePage === paginationArray[0]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  activePage !== paginationArray[0] &&
                                  handlePageClick(activePage - 1)
                                }
                                disabled={
                                  activePage === paginationArray[0]
                                    ? true
                                    : false
                                }
                              >
                                <i className="ti-arrow-left"></i> Prev
                              </Link>
                            </li>
                            {startPage > 1 && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage - pagesPerGroup)
                                  }
                                >
                                  &laquo;
                                </Link>
                              </li>
                            )}
                            {paginationArray.map((pageNumber) => (
                              <li
                                key={pageNumber}
                                className={
                                  activePage === pageNumber ? "active" : ""
                                }
                              >
                                <Link
                                  to="#"
                                  onClick={() => handlePageClick(pageNumber)}
                                >
                                  {pageNumber}
                                </Link>
                              </li>
                            ))}
                            {endPage < totalPages && (
                              <li>
                                <Link
                                  to="#"
                                  onClick={() =>
                                    handlePageClick(startPage + pagesPerGroup)
                                  }
                                >
                                  &raquo;
                                </Link>
                              </li>
                            )}
                            <li
                              className={`next ${
                                activePage ===
                                paginationArray[paginationArray.length - 1]
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  activePage !==
                                    paginationArray[
                                      paginationArray.length - 1
                                    ] && handlePageClick(activePage + 1)
                                }
                                disabled={
                                  activePage ===
                                  paginationArray[paginationArray.length - 1]
                                }
                              >
                                Next <i className="ti-arrow-right"></i>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default EventsJobs;
