import moment from "moment";
import React, { useEffect, useState } from "react";

function Timleline(props) {
  const { type, data } = { ...props };

  const [timelineStages, setTimelineStages] = useState([]);

  useEffect(() => {
    let mainData =
      type === "jobs"
        ? data.map((ele, id) => ({
            ...ele,
            completion_stage: ele.date === "" ? false : true,
            // stage_name: ,
            status: ele.status,
            date:
              ele.date !== "" ? moment(ele.date).format("Do MMMM YYYY") : "",
            stage: ele.stage + 1,
          }))
        : type === "skilling"
        ? data.map((ele, id) => ({
            ...ele,
            completion_stage: ele.timestamp === "" ? false : true,
            // stage_name: ,
            status: ele.status,
            date:
              ele.timestamp !== ""
                ? moment(ele.timestamp).format("Do MMMM YYYY")
                : "",
            stage: id + 1,
          }))
        : data.map((ele, id) => ({
            ...ele,
            completion_stage: ele.stage === "" ? false : true,
            // stage_name: ,
            // status: ele.status,
            status:
              id === 0
                ? "Applied"
                : id === 1
                ? "Under verification"
                : id === 2
                ? "Selected"
                : "Joined",
            date:
              ele.timestamp !== ""
                ? moment(ele.timestamp).format("Do MMMM YYYY")
                : "",
            stage: id + 1,
          }));
    setTimelineStages(mainData);
    // type === "jobs" ? setTimelineStages(mainData) : setTimelineStages(mainData);
    // setTimelineStages([
    // {
    //   stage: 1,
    //   stage_name: "Bot Successfully Engaged",
    //   date: "24 July 2024",
    //   completion_stage: true,
    // },
    // {
    //   stage: 2,
    //   stage_name: "Counsellor Engagement On",
    //   date: "26 July 2024",
    //   completion_stage: true,
    // },
    // {
    //   stage: 3,
    //   stage_name: "Interview Process Initiated",
    //   date: "27 July 2024",
    //   completion_stage: true,
    // },
    // {
    //   stage: 4,
    //   stage_name: "Interview Process On",
    //   date: "",
    //   completion_stage: false,
    // },
    // {
    //   stage: 5,
    //   stage_name: "Final Selection On",
    //   date: "",
    //   completion_stage: false,
    // },
    // {
    //   stage: 6,
    //   stage_name: "Post Selection Stage",
    //   date: "",
    //   completion_stage: false,
    // },
    // ])
    // setTimelineStages([
    //   {
    //     stage: 1,
    //     stage_name: "Applied",
    //     date: "24 July 2024",
    //     completion_stage: true,
    //   },
    //   {
    //     stage: 2,
    //     stage_name: "Under verification",
    //     date: "26 July 2024",
    //     completion_stage: true,
    //   },
    //   {
    //     stage: 3,
    //     stage_name: "Selected",
    //     date: "",
    //     completion_stage: false,
    //   },
    //   {
    //     stage: 4,
    //     stage_name: "Joined",
    //     date: "",
    //     completion_stage: false,
    //   },
    // ]);
  }, []);

  return (
    <>
      {" "}
      <div class="container padding-bottom-3x mb-1">
        <div class="mb-3">
          {/* <div class="p-4 text-center text-white text-lg bg-dark rounded-top">
            <span class="text-uppercase">Tracking Order No - </span>
            <span class="text-medium">34VB5540K83</span>
          </div>
          <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between py-3 px-2 bg-secondary">
            <div class="w-100 text-center py-1 px-2">
              <span class="text-medium">Shipped Via:</span> UPS Ground
            </div>
            <div class="w-100 text-center py-1 px-2">
              <span class="text-medium">Status:</span> Checking Quality
            </div>
            <div class="w-100 text-center py-1 px-2">
              <span class="text-medium">Expected Date:</span> SEP 09, 2017
            </div>
          </div> */}
          <div
          //   class="card-body"
          >
            <div class="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
              {timelineStages.map((item, id) => (
                <div
                  className={`step ${item.completion_stage ? "completed" : ""}`}
                  key={id}
                >
                  <div className="step-icon-wrap">
                    <div className="step-icon">
                      {/* <i className="pe-7s-cart"></i> */}
                      {item.stage}
                    </div>
                  </div>
                  <h3 className="step-title text-blue">
                    {type === "jobs"
                      ? item.stage === 1
                        ? "Bot Successfully Engaged"
                        : item.stage === 2
                        ? "Counsellor Engagement On"
                        : item.stage === 3
                        ? "Interview Process Initiated"
                        : item.stage === 4
                        ? "Interview Process On"
                        : item.stage === 5
                        ? "Final Selection On"
                        : "Post Selection Stage"
                      : type === "skilling"
                      ? item.stage === 1
                        ? "Interested"
                        : item.stage === 2
                        ? "Under Verification"
                        : item.stage === 3
                        ? "Ongoing"
                        : "Dropout"
                      : item.status}
                  </h3>
                  {/* <h5 className="step-title fw6 font-16">{item.status}</h5> */}
                  <p className="m-b0 font-10">{item.date}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <div class="d-flex flex-wrap flex-md-nowrap justify-content-center justify-content-sm-between align-items-center">
          <div class="custom-control custom-checkbox mr-3">
            <input
              class="custom-control-input"
              type="checkbox"
              id="notify_me"
              checked=""
            />
            <label class="custom-control-label" for="notify_me">
              Notify me when order is delivered
            </label>
          </div>
          <div class="text-left text-sm-right">
            <a
              class="btn btn-outline-primary btn-rounded btn-sm"
              href="orderDetails"
              data-toggle="modal"
              data-target="#orderDetails"
            >
              View Order Details
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Timleline;
