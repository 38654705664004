import React, { Suspense, lazy, useEffect, useState } from "react";
import Index from "./markup/Markup";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  Switch,
  withRouter,
  useHistory,
} from "react-router-dom";
import InputEmoji from "react-input-emoji";
// import samarth from "././images/images/Group 1000003571.png";
import samarth from "././images/logo/SakshamLogo.svg";
import send from "././images/images/send.png";
import chatbot from "././images/images/Group 1000003589.png";
import ScrollToBottom from "react-scroll-to-bottom";
//import { checkAutoLogin } from './services/AuthService';
//import { isAuthenticated } from './store/selectors/AuthSelectors';
import "./css/plugins.css";
import "./css/style.css";
import "./css/templete.css";
import "./css/skin/skin-1.css";
import "./plugins/slick/slick.min.css";
import "./plugins/slick/slick-theme.min.css";
import Chatbot from "react-chatbot-kit";

import Login from "./markup/Pages/Loginpage2";
import SignUp from "./markup/Pages/Register2";
import MessageParser from "./chatbot/MessageParser";
import ActionProvider from "./chatbot/ActionProvider";
import config from "./chatbot/chatbotConfig";
import axios from "axios";
// const SignUp = lazy(() => import("./markup/Pages/Register2"));
//const ForgotPassword = lazy(() => import('./markup/pages/ForgotPassword'));
// const Login = lazy(() => {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(import("./markup/Pages/Loginpage2")), 500);
//   });
//   //   return import("./markup/Pages/Loginpage2");
// });

function App(props) {
  /*
    const dispatch = useDispatch();
    useEffect(() => {
        checkAutoLogin(dispatch, props.history);
    }, [dispatch, props.history]);
    */
  const [showBot, toggleBot] = useState(false);
  const [text, setText] = useState("");
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [message, setMessage] = useState({});
  const [Messages, setMessages] = useState([]);

  const chatbotfetch = async (text) => {
    try {
      const res = await axios.post(
        "https://samarth.webapi.sapioglobal.com/sapiobot/",
        {
          from: "919082865612",
          message: text,
        }
      );

      const data = res?.data;
      //console.log(data)
      if (data.sttaus === "Fail") {
        setMessage({
          type: "received",
          ques: "Your quality employment is my goal. I am appointed by the Govt of India to be with you for your lifetime and help you enhance your livelihood.",
          footer: "",
          flows: [],
          date: new Date(),
        });
      } else {
        setMessage(data);
        setMessage({
          type: "sent",
          ques: text,
          footer: "",
          flows: [],
          date: new Date(),
        });
      }

      console.log(res?.data, "duck");
    } catch (err) {
      console.log(err);
      setMessage({
        type: "received",
        ques: "Your quality employment is my goal. I am appointed by the Govt of India to be with you for your lifetime and help you enhance your livelihood.",
        footer: "",
        flows: [],
        date: new Date(),
      });
    }
  };

  function handleOnEnter(text) {
    chatbotfetch(text);
    // setMessage({
    //   type: "sent",
    //   ques: text,
    //   footer: "",
    //   flows: [],
    //   date: new Date(),
    // });
  }

  useEffect(() => {
    chatbotfetch();
  }, []);

  useEffect(() => {
    setMessages((msg) => [...msg, message]);
    sessionStorage.setItem("messages", JSON.stringify(Messages));
  }, [message]);

  useEffect(() => {
    // console.log(window.location.href.includes("?id"));
    let isWhiteLable = window.location.href.includes("?id");
    if (!userInfo && userInfo !== null && !isWhiteLable) {
      console.log("issue::", userInfo);
      history.push("/login");
    }
  }, [userInfo, history]);

  return (
    <div>
      {/*
                <Route path='/login' component={Login} 
                */}
      {/* <Suspense
        fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
      > */}
      <Switch>
        {/* <Route path="*" component={Login} /> */}
        <Route path="/register" component={SignUp} />
        <Route path="/login" component={Login} />
        <Route path="/" component={Index} />
      </Switch>
      {/* </Suspense> */}
      {/* <Index /> */}
      {userInfo && (
        <>
          <div className="app-chatbot-container">
            {showBot && (
              <div
                className="app-chatbot-div"
                style={{ width: "400px", height: "500px" }}
              >
                <div className="d-flex-start-border">
                  <img src={samarth} alt="" height={"52px"} width={"52px"} />

                  <h5>&emsp;Saksham</h5>
                </div>

                {/*
                                <div className="Messages">
                                    <div className={`Message ${false ? 'sent' : 'received'}`}>
                                        <span>👋 Hi there! How can I help?</span>
                                    </div>
                                    <div className='Message received'>
                                        <p>Samarth</p>
                                    </div>

                                    <div className={`Message ${true ? 'sent' : 'received'}`}>
                                        <span>Can I talk to someone please?</span>
                                    </div>
                                    <div className='Message sent'>
                                        <p>You</p>
                                    </div>
                                </div>
                                */}

                <ScrollToBottom className="MessageContainer">
                  <div className="Messages">
                    {Messages.map((v, i) => {
                      return (
                        <div key={i} style={{ padding: "8px" }}>
                          {v?.sttaus === "Fail" ? null : (
                            <>
                              {v.ques && (
                                <div
                                  className={`Message ${
                                    v.type === "sent" ? "sent" : "received"
                                  }`}
                                >
                                  <span>{v.ques ? v.ques : ""}</span>
                                </div>
                              )}
                              {v.type === "button" ? (
                                <div
                                  style={{
                                    background: "#FFFFFF",
                                    padding: "8px",
                                    borderRadius: "12px",
                                    marginTop: "8px",
                                  }}
                                >
                                  {v.flows?.map((v) => {
                                    return (
                                      <div id={v.id} className="d-flex-center">
                                        <input
                                          type="radio"
                                          name="subject"
                                          onChange={() => chatbotfetch(v.title)}
                                        />
                                        <span style={{ padding: "8px" }}>
                                          {v.title}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}

                              {v.type === "radio" ? (
                                <div
                                  style={{
                                    background: "#FFFFFF",
                                    padding: "8px",
                                    borderRadius: "12px",
                                    marginTop: "8px",
                                  }}
                                >
                                  {v.flows?.map((v) => {
                                    return (
                                      <div id={v.id} className="d-flex-center">
                                        <input
                                          type="radio"
                                          name="subject"
                                          onChange={() => chatbotfetch(v.title)}
                                        />
                                        <span style={{ padding: "8px" }}>
                                          {v.title}
                                        </span>
                                      </div>
                                    );
                                  })}
                                </div>
                              ) : null}
                              {v.ques && (
                                <div
                                  className={`Message ${
                                    v.type === "sent" ? "sent" : "received"
                                  }`}
                                >
                                  <p>{v.type === "sent" ? "You" : "Saksham"}</p>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </ScrollToBottom>

                <div className="d-flex-start">
                  <InputEmoji
                    value={text}
                    onChange={setText}
                    cleanOnEnter
                    onEnter={handleOnEnter}
                    placeholder="Write your message"
                  />

                  <div className="send-btn" onClick={() => chatbotfetch(text)}>
                    <img src={send} alt="" height={"24px"} width={"24px"} />
                  </div>
                </div>
              </div>
            )}
          </div>

          <button
            className="app-chatbot-button"
            onClick={() => toggleBot((prev) => !prev)}
          >
            <img src={chatbot} alt="" height={"82px"} width={"82px"} />
          </button>
        </>
      )}
    </div>
  );
}

/*
let routes = (  
        <Switch>
            <Route path='/login' component={Login} />
            <Route path='/register-2' component={SignUp} />
        </Switch>
    );
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <Index/>
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routes}
                </Suspense>
			</div>
		);
	}
*/

/*
const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};
*/

//export default withRouter(connect(mapStateToProps)(App));

export default App;
