import { PROFILE_EDIT_FAIL, PROFILE_EDIT_REQUEST, PROFILE_EDIT_SUCCESS, PROFILE_FAIL, PROFILE_REQUEST, PROFILE_SUCCESS } from "../constants/ProfileConstants"

export const profileReducer = (state = {}, action) => {
    switch (action.type) {
        case PROFILE_REQUEST:
            return {
                loading: true
            }
        case PROFILE_SUCCESS:
            return {
                loading: false,
                profileInfo: action.payload
            }
        case PROFILE_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export const editprofileReducer = (state = {}, action) => {
    switch (action.type) {
        case PROFILE_EDIT_REQUEST:
            return {
                loading: true
            }
        case PROFILE_EDIT_SUCCESS:
            return {
                loading: false,
                message: action.payload
            }
        case PROFILE_EDIT_FAIL:
            return {
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}
