import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header2 from "./../Layout/Header2";
import Header from "../Layout/Header";
import Footer from "./../Layout/Footer";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import Swal from "sweetalert2";
import Profilesidebar from "../Element/Profilesidebar";

function Companymanage({ location }) {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [company, setCompany] = useState(false);
  const [filter, setFilter] = useState(true);
  const [listJF, setListJF] = useState([]);
  const [originalListJF, setOriginalListJF] = useState([]);
  const [jfDetail, setJFDetail] = useState({});
  const [filterObj, setFilterObj] = useState({
    jfStatus: "",
    applicantStatus: "",
  });
  const [activePage, setActivePage] = useState(1);
  const handlePageClick = (pageNumber) => {
    setActivePage(pageNumber);
  };
  const postsPerPage = 10;
  const pagesPerGroup = 5;
  const totalPages = Math.ceil(listJF.length / postsPerPage);
  const startPage =
    Math.floor((activePage - 1) / pagesPerGroup) * pagesPerGroup + 1;
  const endPage = Math.min(startPage + pagesPerGroup - 1, totalPages);
  // let i = 0;
  const paginationArray = [];
  for (let i = startPage; i <= endPage; i++) {
    paginationArray.push(i);
  }
  // while (i < totalPages) {
  //   paginationArray.push(i + 1);
  //   i++;
  // }

  //   const currentPosts = listJF.slice(
  //     (activePage - 1) * postsPerPage,
  //     activePage * postsPerPage
  //   );
  function shortenString(str, startLength, endLength) {
    // Ensure that the string is longer than the desired output
    if (str.length <= startLength + endLength) {
      return str;
    }

    // Extract the desired start and end sections of the string
    const start = str.slice(0, startLength);
    const end = str.slice(-endLength);

    // Combine the parts with ellipsis in the middle
    return `${start}...${end}`;
  }
  function timeFormat(str) {
    console.log("timeFormat::", typeof str);
    if (typeof str !== "string") {
      // console.error("Invalid time string:", timeString);
      return ""; // Return an empty string or handle it as needed
    }
    const [hours, minutes] = str.split(":");
    const hourInt = parseInt(hours, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    const adjustedHour = hourInt % 12 || 12;
    const mainString = `${
      adjustedHour < 10 ? `0${adjustedHour}` : adjustedHour
    } : ${minutes < 10 ? `0${minutes}` : minutes} ${period}`;

    return mainString;
  }
  const fetchopportunities = async () => {
    let url = `${process.env.REACT_APP_BACKEND_GENERIC_URL}/jobfair/jobprovider_jf`;
    await axios
      .get(url, {
        params: { js_id: userInfo["Registration ID"] },
      })
      .then((res) => {
        let data = res.data.filter((ele) => ele.status !== "Inactive");
        setListJF(data);
        setOriginalListJF(data);
      });
  };
  const handleEnrollFunc = async (jfid) => {
    let url = `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/candidate-enroll`;
    await axios
      .post(url, {
        js_id: userInfo["Registration ID"],
        jf_id: jfid,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Enrollment successful for the job fair",
          //text: res.data["User Message"],
          //   position: "center",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        }).then((res) => {
          fetchopportunities();
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong...",
          //text: res.data["User Message"],
          //   position: "center",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  useEffect(() => {
    fetchopportunities();
  }, []);

  useEffect(() => {
    console.log("filterObj:::", filterObj);
    let d = originalListJF.filter((ele) => {
      const { jfStatus, applicantStatus } = filterObj;

      // Filter based on jfStatus or applicantStatus
      const statusMatch = jfStatus === "" || ele.status.includes(jfStatus);
      const applicantMatch =
        applicantStatus === "" ||
        ele.candidate_enrolled === parseInt(applicantStatus);

      return statusMatch && applicantMatch;
    });
    // set;
    setListJF(d);
    setActivePage(1);
    console.log("filter data::", d);
  }, [filterObj]);
  //   console.log(
  //     "data:::",
  //     // listJF.filter((ele) => ele.status === "Inactive") Upcoming / Completed / Ongoing
  //     listJF.map((ele) => ele.status)
  //   );

  return (
    <>
      {/* <Header2 /> */}
      <Header location={location} />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <Profilesidebar />
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx browse-job clearfix">
                    <div className="job-bx-title  clearfix">
                      <h5 className="font-weight-700 pull-left text-uppercase">
                        All Job Fairs
                      </h5>
                      <div className="input-group-otp float-right">
                        {filter && (
                          <>
                            <div className="row2">
                              {/* <span className="select-title">Status</span> */}
                              <select
                                className="custom-btn"
                                onChange={(e) =>
                                  setFilterObj({
                                    ...filterObj,
                                    jfStatus: e.target.value,
                                  })
                                }
                                placeholder="Job Fair Status"
                                value={filterObj.jfStatus}
                              >
                                <option value="">Job Fair Status</option>
                                <option value="Upcoming">Upcoming</option>
                                <option value="Ongoing">Ongoing</option>
                                <option value="Completed">Completed</option>
                              </select>
                              <select
                                className="custom-btn"
                                onChange={(e) =>
                                  setFilterObj({
                                    ...filterObj,
                                    applicantStatus: e.target.value,
                                  })
                                }
                                value={filterObj.applicantStatus}
                              >
                                <option value="">Application Status</option>
                                <option value={0}>Enroll Now</option>
                                <option value={1}>Enrolled</option>
                              </select>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  // setFilter(!filter);
                                  filter &&
                                    setFilterObj({
                                      jfStatus: "",
                                      applicantStatus: "",
                                    });
                                }}
                                className={!filter ? "" : "select-title"}
                              >
                                {!filter ? (
                                  <i className="fa fa-filter"></i>
                                ) : (
                                  "Reset"
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <table className="table-job-bx cv-manager company-manage-job">
                      <thead>
                        <tr>
                          {/* <th className="feature">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                id="check12"
                                className="custom-control-input selectAllCheckBox"
                                name="example1"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check12"
                              ></label>
                            </div>
                          </th> */}
                          <th>Job Fair Name</th>
                          {/* <th>Venue</th> */}
                          <th>Job Fair Status</th>
                          <th>Application Status</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr>
                           <td className="feature">
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="check1"
                                name="example1"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="check1"
                              ></label>
                            </div>
                          </td> 
                          <td className="job-name">
                            <Link to={"#"}>Social Media Expert</Link>
                            <ul className="job-post-info">
                              <li>
                                <i className="fa fa-map-marker"></i> venue
                              </li>
                              <li>
                                <i className="fa fa-calendar"></i> Date
                              </li>
                              <li>
                                <i className="fa fa-clock-o"></i> Time
                              </li>
                            </ul>
                          </td>
                          <td className="application text-primary">
                            (5) Applications
                          </td>
                          <td className="expired pending">Upcoming </td>
                          <td className="expired pending">
                            <button className="site-button m-1">
                              Enroll Now
                            </button>{" "}
                          </td>
                          <td className="job-links">
                            <Link to={"#"} onClick={() => setCompany(true)}>
                              <i className="fa fa-eye"></i>
                            </Link>
                            {/* <Link to={"#"}>
                              <i className="ti-trash"></i>
                            </Link> 
                          </td>
                        </tr> */}

                        {listJF
                          .slice(
                            (activePage - 1) * postsPerPage,
                            activePage * postsPerPage
                          )
                          .map((item, id) => (
                            <tr key={id}>
                              <td className="job-name">
                                {item.candidate_enrolled ? (
                                  <Link
                                    to={`/events-job?jfid=${item.registration_id}`}
                                  >
                                    {item.name
                                      ? shortenString(item.name, 10, 10)
                                      : "NA"}
                                  </Link>
                                ) : (
                                  <span>
                                    {item.name
                                      ? shortenString(item.name, 10, 10)
                                      : "NA"}
                                  </span>
                                )}
                                <ul className="job-post-info">
                                  <li>
                                    <i className="fa fa-map-marker"></i>{" "}
                                    {`${item.state ? item.state : "NA"},${
                                      item.district ? item.district : "NA"
                                    }`}
                                  </li>
                                </ul>
                                <ul className="job-post-info">
                                  <li>
                                    <i className="fa fa-calendar"></i>{" "}
                                    {item.date ? item.date : "NA"}
                                  </li>
                                  <li>
                                    <i className="fa fa-clock-o"></i>{" "}
                                    {item.time ? timeFormat(item.time) : "NA"}
                                  </li>
                                </ul>
                              </td>
                              {/* <td className="application text-primary">
                              {item.venue ? item.venue : "NA"}
                            </td> */}

                              <td
                                className={`expired ${
                                  item.status === "Completed"
                                    ? "success"
                                    : "pending"
                                }`}
                              >
                                {item.status ? item.status : "NA"}{" "}
                              </td>
                              <td className="expired success">
                                <button
                                  className={`${
                                    item.candidate_enrolled
                                      ? "site-button-green"
                                      : "site-button"
                                  } m-1`}
                                  style={{
                                    cursor:
                                      item.candidate_enrolled ||
                                      item.status === "Completed"
                                        ? "no-drop"
                                        : "pointer",
                                  }}
                                  onClick={() => {
                                    item.candidate_enrolled === 0 &&
                                      handleEnrollFunc(item.registration_id);
                                  }}
                                >
                                  {!item.candidate_enrolled
                                    ? "Enroll Now"
                                    : "Enrolled"}
                                </button>{" "}
                              </td>
                              <td className="job-links">
                                <Link
                                  to={"#"}
                                  onClick={() => (
                                    setCompany(true), setJFDetail(item)
                                  )}
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>
                                {/* <Link to={"#"}>
                              <i className="ti-trash"></i>
                            </Link> */}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <div className="pagination-bx m-t30 float-right">
                      <ul className="pagination">
                        <li
                          className={`previous ${
                            activePage === 1 ? "disabled" : ""
                          }`}
                        >
                          <Link
                            to={"#"}
                            onClick={() =>
                              activePage !== 1 &&
                              handlePageClick(activePage - 1)
                            }
                            disabled={activePage === 1 ? true : false}
                          >
                            <i className="ti-arrow-left"></i> Prev
                          </Link>
                        </li>
                        {startPage > 1 && (
                          <li>
                            <Link
                              to="#"
                              onClick={() =>
                                handlePageClick(startPage - pagesPerGroup)
                              }
                            >
                              &laquo;
                            </Link>
                          </li>
                        )}

                        {paginationArray.map((pageNumber) => (
                          <li
                            key={pageNumber}
                            className={
                              activePage === pageNumber ? "active" : ""
                            }
                          >
                            <Link
                              to="#"
                              onClick={() => handlePageClick(pageNumber)}
                            >
                              {pageNumber}
                            </Link>
                          </li>
                        ))}
                        {endPage < totalPages && (
                          <li>
                            <Link
                              to="#"
                              onClick={() =>
                                handlePageClick(startPage + pagesPerGroup)
                              }
                            >
                              &raquo;
                            </Link>
                          </li>
                        )}
                        <li
                          className={`next ${
                            activePage ===
                            paginationArray[paginationArray.length - 1]
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <Link
                            to="#"
                            onClick={() =>
                              activePage !==
                                paginationArray[paginationArray.length - 1] &&
                              handlePageClick(activePage + 1)
                            }
                            disabled={
                              activePage ===
                              paginationArray[paginationArray.length - 1]
                            }
                          >
                            Next <i className="ti-arrow-right"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <Modal
                      show={company}
                      onHide={setCompany}
                      className="modal fade modal-bx-info"
                    >
                      <div className="modal-dialog my-0 mx-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            {/* <div className="logo-img">
                              <img
                                alt=""
                                src={require("./../../images/logo/icon2.png")}
                              />
                            </div> */}
                            {/* <h5 className="modal-title">Job Fair Details</h5> */}
                            <button
                              type="button"
                              className="close"
                              // style={{ cursor: "pointer" }}
                              onClick={() => (
                                setCompany(false), setJFDetail({})
                              )}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <h5 className="font-weight-200 font-14 m-t10">
                              Job Fair Details
                            </h5>
                            <div className="dez-divider divider-2px bg-gray-dark mb-1 mt-0"></div>
                            <div className="row">
                              <div className="col-lg-3 col-md-3">
                                <strong>Job Fair Name</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.name} </p>
                              </div>
                              <div className="col-lg-3 col-md-3"></div>
                              <div className="col-lg-3 col-md-3"></div>
                              <div className="col-lg-3 col-md-3">
                                <strong>Job Fair Date</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.date}</p>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <strong>Job Fair Time</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {timeFormat(jfDetail.time)}</p>
                              </div>

                              <div className="col-lg-3 col-md-3">
                                <strong>State</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.state}</p>
                              </div>

                              <div className="col-lg-3 col-md-3">
                                <strong>Disrict</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.district}</p>
                              </div>

                              <div className="col-lg-3 col-md-3">
                                <strong>Venue</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.venue}</p>
                              </div>
                            </div>
                            <h5 className="font-weight-200 font-14 m-t10">
                              SPOC Details
                            </h5>
                            <div className="dez-divider divider-2px bg-gray-dark mb-1 mt-0"></div>
                            <div className="row">
                              <div className="col-lg-3 col-md-3">
                                <strong>Name</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.spoc_name}</p>
                              </div>

                              <div className="col-lg-3 col-md-3">
                                <strong>Mobile</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.spoc_mobile}</p>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <strong>Email</strong>
                              </div>
                              <div className="col-lg-3 col-md-3">
                                <p>: {jfDetail.spoc_email}</p>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              // className="btn btn-secondary"
                              style={{ cursor: "pointer" }}
                              className="btn btn-primary"
                              onClick={() => (
                                setCompany(false), setJFDetail({})
                              )}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Companymanage;
