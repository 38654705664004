import axios from "axios";
import Swal from "sweetalert2";
import "../../markup/Pages/Styles.css";
import {
  RESUME_EDIT_FAIL,
  RESUME_EDIT_REQUEST,
  RESUME_EDIT_SUCCESS,
  RESUME_FAIL,
  RESUME_REQUEST,
  RESUME_SUCCESS,
  HEADLINE_EDIT_FAIL,
  HEADLINE_EDIT_REQUEST,
  HEADLINE_EDIT_SUCCESS,
  SKILLS_EDIT_REQUEST,
  SKILLS_EDIT_FAIL,
  SKILLS_EDIT_SUCCESS,
  INTEREST_EDIT_REQUEST,
  INTEREST_EDIT_SUCCESS,
  INTEREST_EDIT_FAIL,
  EMPLOYMENT_EDIT_REQUEST,
  EMPLOYMENT_EDIT_SUCCESS,
  EMPLOYMENT_EDIT_FAIL,
  EDUCATION_EDIT_REQUEST,
  EDUCATION_EDIT_SUCCESS,
  EDUCATION_EDIT_FAIL,
  RESUME_FILE_EDIT_REQUEST,
  RESUME_FILE_EDIT_SUCCESS,
  RESUME_FILE_EDIT_FAIL,
} from "../constants/ResumeContants";

export const fetchresume = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: RESUME_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${userInfo.access}`,
      },
      params: { registration_id: userInfo["Registration ID"] },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/user/resume`,
      config
    );
    dispatch({
      type: RESUME_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: RESUME_FAIL,
      payload: err.response.data,
    });
  }
};

export const editresume =
  (
    headline,
    skill,
    employmenttitle,
    highesteducation,
    degree,
    stream,
    interestvalue,
    resumeFile
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: RESUME_EDIT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const formData = new FormData();
      formData.append("headline", headline);
      formData.append("skills", skill);
      formData.append("employment", employmenttitle);
      formData.append("highest_education", highesteducation);
      formData.append("degree", degree);
      formData.append("stream", stream);
      formData.append("interest", interestvalue);
      formData.append("resume", resumeFile);

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/auth/login/email`,
        formData,
        config
      );
      dispatch({
        type: RESUME_EDIT_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: RESUME_EDIT_FAIL,
        payload: err.response.data,
      });
    }
  };

export const editHeadline = (headline, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: HEADLINE_EDIT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const formData = new FormData();
    formData.append("headline", headline);
    formData.append("registration_id", id);
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-headline`,
      formData,
      config
    );
    dispatch({
      type: HEADLINE_EDIT_SUCCESS,
      payload: data,
    });
    Swal.fire({
      icon: "success",
      title: "Headline updated successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  } catch (err) {
    dispatch({
      type: HEADLINE_EDIT_FAIL,
      payload: err.response.data,
    });
    Swal.fire({
      icon: "error",
      title: "Error",
      text: err?.response?.data["message"]
        ? err?.response?.data["message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};

export const editSkills = (skills, id) => async (dispatch, getState) => {
  console.log(skills, "sk11");
  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  try {
    dispatch({
      type: SKILLS_EDIT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const formData = new FormData();
    formData.append("skills", skills);
    formData.append("registration_id", id);

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-skills`,
      formData
      //   config
    );
    dispatch({
      type: SKILLS_EDIT_SUCCESS,
      payload: data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);
    Swal.fire({
      icon: "success",
      title: "Skills updated successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  } catch (err) {
    dispatch({
      type: SKILLS_EDIT_FAIL,
      payload: err?.response?.data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: err?.response?.data["message"]
        ? err?.response?.data["message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};

export const editEducation =
  (highest_education, degree, stream, id) => async (dispatch, getState) => {
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      dispatch({
        type: EDUCATION_EDIT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          // Authorization: `Bearer ${userInfo.access}`,
        },
      };

      const formData = new FormData();
      formData.append("highest_education", highest_education);
      formData.append("degree", degree);
      formData.append("stream", stream);
      formData.append("registration_id", id);

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-education`,
        formData
        //   config
      );
      dispatch({
        type: EDUCATION_EDIT_SUCCESS,
        payload: data,
      });
      setTimeout(() => {
        waitingPopup.close();
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Education updated successfully",
        //text: res.data["User Message"],
        showConfirmButton: false,
        timer: 1500,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
    } catch (err) {
      dispatch({
        type: EDUCATION_EDIT_FAIL,
        payload: err?.response?.data,
      });
      setTimeout(() => {
        waitingPopup.close();
      }, 0);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data["message"]
          ? err?.response?.data["message"]
          : "Something went wrong!",
        showConfirmButton: false,
        timer: 1500,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
    }
  };

export const editEmployment =
  (employment, id) => async (dispatch, getState) => {
    // const data = employment?.map((el, i) => {
    //   return { ...el, registration_id: id };
    // });
    // console.log(data, "em11");
    const waitingPopup = Swal.fire({
      title: "Please wait...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      dispatch({
        type: EMPLOYMENT_EDIT_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      //   const payload = [
      //     {
      //       registration_id: id,
      //       designation: designation,
      //       organization: organization,
      //       curr_org: curr_org,
      //       working_from: working_from,
      //       working_till: working_till,
      //       description: description,
      //     },
      //   ];

      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-employment`,
        { employment: employment }
      );
      dispatch({
        type: EMPLOYMENT_EDIT_SUCCESS,
        payload: data,
      });
      setTimeout(() => {
        waitingPopup.close();
      }, 0);
      Swal.fire({
        icon: "success",
        title: "Employment added successfully",
        //text: res.data["User Message"],
        showConfirmButton: false,
        timer: 1500,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
    } catch (err) {
      dispatch({
        type: EMPLOYMENT_EDIT_FAIL,
        payload: err?.response?.data,
      });
      setTimeout(() => {
        waitingPopup.close();
      }, 0);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: err?.response?.data["message"]
          ? err?.response?.data["message"]
          : "Something went wrong!",
        showConfirmButton: false,
        timer: 1500,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
    }
  };

export const editInterest = (interests, id) => async (dispatch, getState) => {
  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  try {
    dispatch({
      type: INTEREST_EDIT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const formData = new FormData();
    formData.append("interests", interests);
    formData.append("registration_id", id);

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-interests`,
      formData
      //   config
    );
    dispatch({
      type: INTEREST_EDIT_SUCCESS,
      payload: data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);
    Swal.fire({
      icon: "success",
      title: "Interests updated successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  } catch (err) {
    dispatch({
      type: INTEREST_EDIT_FAIL,
      payload: err?.response?.data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: err?.response?.data["message"]
        ? err?.response?.data["message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};

export const editResumeFile = (resume, id) => async (dispatch, getState) => {
  console.log(resume, "rm11");
  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
  });
  try {
    dispatch({
      type: RESUME_EDIT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const formData = new FormData();
    formData.append("resume", resume);
    formData.append("registration_id", id);

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-resume`,
      formData
      //   config
    );
    dispatch({
      type: RESUME_EDIT_SUCCESS,
      payload: data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);
    Swal.fire({
      icon: "success",
      title: "Resume updated successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  } catch (err) {
    dispatch({
      type: RESUME_EDIT_FAIL,
      payload: err?.response?.data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: err?.response?.data["message"]
        ? err?.response?.data["message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};
