import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  forgotVerCode,
  userLoginReducer,
  userRegisterReducer,
} from "./redux/reducers/LoginReducers";
import {
  editprofileReducer,
  profileReducer,
} from "./redux/reducers/ProfileReducers";
import {
  editEducationResumeReducer,
  editEmploymentResumeReducer,
  editHeadlineResumeReducer,
  editInterestResumeReducer,
  editSkillsResumeReducer,
  resumeReducer,
} from "./redux/reducers/ResumeReducers";
import { opportunitiesReducer } from "./redux/reducers/OpportunitiesReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  profile: profileReducer,
  editprofile: editprofileReducer,
  resumestate: resumeReducer,
  opportunity: opportunitiesReducer,
  verData: forgotVerCode,
  headline: editHeadlineResumeReducer,
  skills: editSkillsResumeReducer,
  education: editEducationResumeReducer,
  employment: editEmploymentResumeReducer,
  interest: editInterestResumeReducer,
});

const userInfoFromStorage = sessionStorage.getItem("userInfo")
  ? JSON.parse(sessionStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
  //   opportunity: [],
  //   opportunity: { opportunity: null },
};

const middleware = [thunk];
export const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
