// eslint-disable-next-line
import React from "react";
import { connect, useDispatch } from "react-redux";
import { Link, withRouter, useHistory } from "react-router-dom";

//import { logout } from '../../store/actions/AuthActions';
//import { isAuthenticated } from '../../store/selectors/AuthSelectors';
import { logout } from "../../redux/actions/LoginActions";

function LogoutPage(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  function onLogout() {
    dispatch(logout());
    // window.location.reload();
    //history.push('/login')
  }

  return (
    <>
      <Link
        to={"#"}
        title="READ MORE"
        className="site-button"
        onClick={onLogout}
      >
        <i className="fa fa-lock"></i> Logout
      </Link>
    </>
  );
}
/*
const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};
*/

//export default withRouter(connect(mapStateToProps)(LogoutPage));
export default LogoutPage;
