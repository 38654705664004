// eslint-disable-next-line
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header2 from "./../Layout/Header2";
import logo from "./../../images/images/Group 27363.png";
import logo1 from "./../../images/images/candidate (1) 3.png";
import logo2 from "./../../images/images/choose 1.png";
import Header from "../Layout/Header";

const managerBlog = [
  {
    id: 1,
    image: require("./../../images/testimonials/pic1.jpg"),
    title: "Alexander Weir",
  },
  {
    id: 2,
    image: require("./../../images/testimonials/pic2.jpg"),
    title: "Jennifer Wood",
  },
  {
    id: 3,
    image: require("./../../images/testimonials/pic3.jpg"),
    title: "Melissa Hassib",
  },
  {
    id: 4,
    image: require("./../../images/testimonials/pic1.jpg"),
    title: "Joseph Macfarlan",
  },
  {
    id: 5,
    image: require("./../../images/testimonials/pic2.jpg"),
    title: "Henry Crooks",
  },
  {
    id: 6,
    image: require("./../../images/testimonials/pic3.jpg"),
    title: "James Rogers",
  },
];

function Jobcvmanager({ location }) {
  const [contacts, setContacts] = useState(managerBlog);
  // delete data
  const handleDeleteClick = (contactId) => {
    const newContacts = [...contacts];
    const index = contacts.findIndex((contact) => contact.id === contactId);
    newContacts.splice(index, 1);
    setContacts(newContacts);
  };
  return (
    <>
      <Header location={location} />
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full bg-white p-t50 p-b20">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4 m-b30">
                  <div className="sticky-top">
                    <div className="candidate-info">
                      <div className="candidate-detail text-center">
                        <div className="canditate-des">
                          <Link to={"#"}>
                            <img
                              alt=""
                              src={require("./../../images/team/pic1.jpg")}
                            />
                          </Link>
                          <div
                            className="upload-link"
                            title="update"
                            data-toggle="tooltip"
                            data-placement="right"
                          >
                            <input type="file" className="update-flie" />
                            <i className="fa fa-camera"></i>
                          </div>
                        </div>
                        <div className="candidate-title">
                          <div className="">
                            <h4 className="m-b5">
                              <Link to={"#"}>David Matin</Link>
                            </h4>
                            <p className="m-b0">
                              <Link to={"#"}>Web developer</Link>
                            </p>
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Link to={"/profile"}>
                            <i className="fa fa-user-o" aria-hidden="true"></i>
                            <span>Profile</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/jobs-my-resume"}>
                            <i
                              className="fa fa-file-text-o"
                              aria-hidden="true"
                            ></i>
                            <span>My Resume</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/job-priority"}>
                            <i
                              className="fa fa-briefcase"
                              aria-hidden="true"
                            ></i>
                            <span>Job Priority Status</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/faq"}>
                            <i
                              className="fa fa-comments-o"
                              aria-hidden="true"
                            ></i>
                            <span>FAQ</span>
                          </Link>
                        </li>
                        <li>
                          <Link to={"/contact-us"}>
                            <i className="fa fa-phone" aria-hidden="true"></i>
                            <span>Contact Us</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-lg-8 m-b30">
                  <div className="job-bx browse-job clearfix">
                    <div className="company-background" />

                    <div className="d-flex">
                      <div className="company-logo">
                        <img src={logo} alt="" width={"134px"} />
                      </div>

                      <div className="company-title ml-3">
                        <div>
                          <h4 className="m-b5">
                            <Link to={"#"}>Samarth</Link>
                          </h4>
                          <p className="m-b0">
                            <Link to={"#"}>
                              Connecting Opportunities, Empowering Careers
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-500 pull-left text-grey">
                        <i className="fa fa-solid fa-building"></i>&emsp;Samarth
                        Details
                      </h5>
                    </div>

                    <div className="job-bx-text">
                      <p>
                        "We empower citizens with a personalized lifetime
                        livelihood enhancement guide, offering tailored
                        recommendations that resonate with their aspirations.
                        Simultaneously, businesses leverage our platform for
                        workforce management, encompassing a recruitment
                        solution for seamless hiring processes and a real-time
                        pulse dashboard predicting attrition probabilities and
                        suggesting interventions. At the heart of our mission is
                        the commitment to making both citizens and businesses
                        more capable, fostering growth, and facilitating
                        empowerment through innovative solutions."
                      </p>

                      <div className="d-flex">
                        <div className="company-logo2">
                          <div className="company-kpi">
                            <h5 className="mt-3">
                              <img src={logo1} alt="" />
                              1,50,000
                            </h5>
                            <p>Candidates Registered</p>
                          </div>
                        </div>

                        <div className="company-logo2">
                          <div className="company-kpi">
                            <h5 className="mt-3">
                              <img src={logo1} alt="" />
                              1,50,000
                            </h5>
                            <p>Candidates Registered</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="job-bx-title clearfix">
                      <h5 className="font-weight-500 pull-left text-grey">
                        <i className="fa fa-solid fa-building"></i>&emsp;Samarth
                        Details
                      </h5>
                    </div>

                    <ul className="no-margin">
                      <li className="icon-bx-wraper left mb-3">
                        <div className="icon-bx-xs border-1">
                          {" "}
                          <Link to={"#"} className="icon-cell">
                            <i className="ti-mobile"></i>
                          </Link>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dez-tilte">
                            PHONE
                          </h6>
                          <p>9527258181</p>
                        </div>
                      </li>

                      <li className="icon-bx-wraper left  mb-3">
                        <div className="icon-bx-xs border-1">
                          {" "}
                          <Link to={"#"} className="icon-cell">
                            <i className="ti-email"></i>
                          </Link>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dez-tilte">
                            Email:
                          </h6>
                          <p>info@example.com</p>
                        </div>
                      </li>

                      <li className="icon-bx-wraper left mb-3">
                        <div className="icon-bx-xs border-1">
                          {" "}
                          <Link to={"#"} className="icon-cell">
                            <i className="ti-location-pin"></i>
                          </Link>{" "}
                        </div>
                        <div className="icon-content">
                          <h6 className="text-uppercase m-tb0 dez-tilte">
                            Address:
                          </h6>
                          <p>
                            604-01, B Wing, HDIL Kaledonia, Sahar Rd, Andheri
                            East, Mumbai, Maharashtra 400069
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*
			<Footer />
			*/}
    </>
  );
}
export default Jobcvmanager;
