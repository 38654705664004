import {
  OPPORTUNITIES_FAIL,
  OPPORTUNITIES_REQUEST,
  OPPORTUNITIES_SUCCESS,
} from "../constants/OpportunitiesConstants";

const initialState = {
  opportunityInfo: [], // Start with an empty array
  loading: false,
  error: null,
};

export const opportunitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPPORTUNITIES_REQUEST:
      return {
        loading: true,
      };
    case OPPORTUNITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        opportunityInfo: action.payload,
        // recommended: { opportunityInfo: action.payload },
      };
    case OPPORTUNITIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
