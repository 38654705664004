// eslint-disable-next-line

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/actions/LoginActions";
import { Form } from "react-bootstrap";
import axios from "axios";
import moment from "moment";
import {
  loadingToggleAction,
  signupAction,
} from "../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
var bnr = require("./../../images/background/bg7.png");
// import loginbg from "./../../images/background/bg7.png";

function Register2(props) {
  const dummy = 1;
  const [name, setName] = useState("");
  const [last, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [educationOption, setEducationOption] = useState([]);
  let errorsObj = {
    name: "",
    last: "",
    mobile: "",
    email: "",
    dob: "",
    password: "",
    confirmpassword: "",
    gender: "",
    highest_education: "",
    degree: "",
    stream: "",
    state: "",
    district: "",
    taluka: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [profilenew, setProfilenew] = useState({
    fullname: "",
    first: "",
    last: "",
    mobile: "",
    email: "",
    gender: "",
    dob: "",
    highest_education: "",
    degree: "",
    stream: "",
    state: "",
    district: "",
    taluka: "",
    password: "",
    confirmpassword: "",
    // reg_id: "JS123",
  });

  const userRegister = useSelector((state) => state.userRegister);
  const { error, loading, userInfo } = userRegister;

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userInfo) {
      history.push("/login");
    }
  }, [userInfo, history]);

  function onSignUp(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (profilenew.first === "") {
      errorObj.name = "First name is Required";
      error = true;
    }
    if (profilenew.last === "") {
      errorObj.last = "Last name is Required";
      error = true;
    }
    if (profilenew.mobile === "") {
      errorObj.mobile = "Mobile Number is Required";
      error = true;
    }
    if (profilenew.email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (profilenew.password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    if (profilenew.confirmpassword === "") {
      errorObj.confirmpassword = "Type password again";
      error = true;
    }
    if (profilenew.confirmpassword !== profilenew.password) {
      errorObj.confirmpassword = "Check password again";
      error = true;
    }
    if (profilenew.dob === "") {
      errorObj.dob = "Enter date of birth is Required";
      error = true;
    }
    if (profilenew.gender === "") {
      errorObj.gender = "Gender is Required";
      error = true;
    }
    if (profilenew.highest_education === "") {
      errorObj.highest_education = "Highest Education is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) return;
    //dispatch(loadingToggleAction(true));
    //dispatch(signupAction(email, password, props.history));
    // dispatch(register(name, last, email, password, mobile, password));
    dispatch(
      register(
        profilenew.first,
        profilenew.last,
        profilenew.email,
        profilenew.password,
        profilenew.mobile,
        moment(profilenew.dob).format("DD-MM-YYYY"),
        profilenew.gender,
        profilenew.highest_education
      )
    );
    //history.push('/login')
  }
  const FetchEducation = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`
      );
      const data = await res?.data;
      setEducationOption(data);
      console.log("education", data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    FetchEducation();
  }, []);
  return (
    <div className="page-wraper">
      <div className={dummy === 1 ? "" : "browse-job login-style3"}>
        <div
          className={dummy === 1 ? "" : "bg-img-fix"}
          style={{
            backgroundImage: `url(${bnr})`,
            backgroundSize: "cover",
            height: "100vh",
          }}
        >
          {dummy === 1 ? (
            <div
              style={{
                // maxWidth: "1000px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // backgroundColor: "white",
                paddingTop: "50px",
              }}
            >
              <div
                style={{
                  width: "800px",
                  display: "flex",
                  justifyContent: "center",
                  // alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                <div
                  className={
                    dummy === 1 ? "p-a10 submit-resume" : "login-form style-2"
                  }
                >
                  <div className="logo-header-reg">
                    {/* <Link to={"./"}> */}
                    <img
                      // src={require("./../../images/images/Group 27363.png")}
                      src={
                        Object.keys(sessionStorage).includes("labelData")
                          ? JSON.parse(sessionStorage.getItem("labelData"))[
                              "logo"
                            ]
                          : require("./../../images/logo/SakshamLogo.svg")
                      }
                      alt=""
                      // className="flex justify-center"
                    />
                    {/* </Link> */}
                  </div>
                  <div className="clearfix"></div>
                  <div className="tab-content nav p-b5 tab">
                    <div id="login" className="tab-pane active ">
                      {props.errorMessage && (
                        <div className="">{props.errorMessage}</div>
                      )}
                      {props.successMessage && (
                        <div className="">{props.successMessage}</div>
                      )}

                      <form className=" dez-form p-b10" onSubmit={onSignUp}>
                        <h3 className="form-title text-center">
                          Let’s get onboarded with us
                        </h3>

                        {/*
											<div className="dez-separator-outer m-b5">
												<div className="dez-separator bg-primary style-liner"></div>
											</div>
											*/}

                        {/* <p>Samarth Citizen Registration</p> */}

                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>First Name*</label>
                              <input
                                //   onChange={(e) => setName(e.target.value)}
                                value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    first: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type First Name"
                              />
                              <div className="text-danger font-12">
                                {errors.name && <div>{errors.name}</div>}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Last Name*</label>
                              <input
                                value={profilenew.last}
                                //   onChange={(e) => setLName(e.target.value)}
                                //   value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    last: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type Last Name"
                              />
                              <div className="text-danger font-12">
                                {errors.last && <div>{errors.last}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Email ID*</label>
                              <input
                                type="email"
                                value={profilenew.email}
                                //   onChange={(e) => setEmail(e.target.value)}
                                //   value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    email: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type Your Email Address"
                              />
                              <div className="text-danger font-12">
                                {errors.email && <div>{errors.email}</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Mobile Number*</label>
                              <input
                                type="tel"
                                value={profilenew.mobile}
                                //   onChange={(e) => setMobile(e.target.value)}
                                //   value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    mobile: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type Your 10 Digit Mobile Number"
                              />
                              <div className="text-danger font-12">
                                {errors.mobile && <div>{errors.mobile}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Date of Birth*</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder=""
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    dob: e.target.value,
                                  })
                                }
                              />

                              <div className="text-danger font-12">
                                {errors.dob && <div>{errors.dob}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Gender*</label>

                              <Form.Control
                                as="select"
                                custom
                                className="btn dropdown-toggle text-left btn-default"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    gender: e.target.value,
                                  })
                                }
                              >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Others</option>
                              </Form.Control>
                              <div className="text-danger font-12">
                                {errors.gender && <div>{errors.gender}</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="row"></div> */}

                        <div className="row">
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Highest Education*</label>

                              <Form.Control
                                as="select"
                                custom
                                className="btn dropdown-toggle text-left btn-default custom-select-dropdown"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    highest_education: e.target.value,
                                  })
                                }
                              >
                                {/* <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Others</option> */}
                                <option>Select</option>
                                {educationOption.map((ele, id) => (
                                  <option value={ele.value} key={id}>
                                    {ele.value}
                                  </option>
                                ))}
                              </Form.Control>
                              <div className="text-danger font-12">
                                {errors.highest_education && (
                                  <div>{errors.highest_education}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Password*</label>
                              <input
                                type="password"
                                value={profilenew.password}
                                className="form-control"
                                defaultValue="Password"
                                placeholder="Type Password"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    password: e.target.value,
                                  })
                                }
                              />
                              <div className="text-danger font-12">
                                {errors.password && (
                                  <div>{errors.password}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md-4">
                            <div className="form-group">
                              <label>Confirm Password*</label>
                              <input
                                type="password"
                                value={profilenew.confirmpassword}
                                className="form-control"
                                defaultValue="Password"
                                placeholder="Type Password Again"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    confirmpassword: e.target.value,
                                  })
                                }
                              />
                              <div className="text-danger font-12">
                                {errors.confirmpassword && (
                                  <div>{errors.confirmpassword}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group text-center p-t10">
                          <button
                            type="submit"
                            className="site-button dz-xs-flex m-r5"
                          >
                            Sign up
                          </button>

                          {/* <Link to="/login">
                            <button
                              type="submit"
                              className="site-button dz-xs-flex m-r5"
                            >
                              Login
                            </button>
                          </Link> */}

                          {/*
												<span className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id="check1" name="example1" />
													<label className="custom-control-label" htmlFor="check1">Remember me</label>
												</span>
												<Link data-toggle="tab" to="#forgot-password" className="forget-pass m-l5"><i className="fa fa-unlock-alt"></i> Forgot Password</Link>
												*/}
                        </div>
                        <div className="form-group text-center p-t10">
                          <Link
                            to="/login"
                            className="site-button-link forget-pass m-t15 float-center"
                          >
                            Login
                          </Link>
                        </div>
                        {/*
											<div className="dz-social clearfix">
												<h5 className="form-title m-t5 pull-left">Sign In With</h5>
												<ul className="dez-social-icon dez-border pull-right dez-social-icon-lg text-white">
													<li><Link to={''} className="fa fa-facebook  fb-btn mr-1" target="bank"></Link></li>
													<li><Link to={''} className="fa fa-twitter  tw-btn mr-1" target="bank"></Link></li>
													<li><Link to={''} className="fa fa-linkedin link-btn mr-1" target="bank"></Link></li>
													<li><Link to={''} className="fa fa-google-plus  gplus-btn" target="bank"></Link></li>
												</ul>
											</div>
											*/}
                      </form>

                      {/*
										<div className="text-center bottom">
											<Link to="/login" className="site-button button-md btn-block text-white">Sign In</Link>
										</div>
										*/}
                    </div>
                  </div>
                  {/*
								<div className="bottom-footer clearfix m-t10 m-b20 row text-center">
									<div className="col-lg-12 text-center">
										<span> © Copyright by <i className="fa fa-heart m-lr5 text-red heart"></i>
											<Link to={''} >DexignZone </Link> All rights reserved.</span>
									</div>
								</div>
								*/}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12 bg-white z-index2 relative p-a0 content-scroll skew-section left-bottom">
                <div className="login-form style-2">
                  <div className="logo-header">
                    {/* <Link to={"./"}> */}
                    <img
                      // src={require("./../../images/images/Group 27363.png")}
                      src={
                        Object.keys(sessionStorage).includes("labelData")
                          ? JSON.parse(sessionStorage.getItem("labelData"))[
                              "logo"
                            ]
                          : require("./../../images/logo/SakshamLogo.svg")
                      }
                      alt=""
                      className=""
                    />
                    {/* </Link> */}
                  </div>
                  <div className="clearfix"></div>
                  <div className="tab-content nav p-b5 tab">
                    <div id="login" className="tab-pane active ">
                      {props.errorMessage && (
                        <div className="">{props.errorMessage}</div>
                      )}
                      {props.successMessage && (
                        <div className="">{props.successMessage}</div>
                      )}

                      <form className=" dez-form p-b10" onSubmit={onSignUp}>
                        <h3 className="form-title">
                          Let’s get onboarded with us
                        </h3>

                        {/*
											<div className="dez-separator-outer m-b5">
												<div className="dez-separator bg-primary style-liner"></div>
											</div>
											*/}

                        {/* <p>Samarth Citizen Registration</p> */}

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>First Name:</label>
                              <input
                                //   onChange={(e) => setName(e.target.value)}
                                value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    first: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type First Name"
                              />
                              <div className="text-danger font-12">
                                {errors.name && <div>{errors.name}</div>}
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Last Name:</label>
                              <input
                                value={profilenew.last}
                                //   onChange={(e) => setLName(e.target.value)}
                                //   value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    last: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type Last Name"
                              />
                              <div className="text-danger font-12">
                                {errors.last && <div>{errors.last}</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Email ID</label>
                              <input
                                type="email"
                                value={profilenew.email}
                                //   onChange={(e) => setEmail(e.target.value)}
                                //   value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    email: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type Your Email Address"
                              />
                              <div className="text-danger font-12">
                                {errors.email && <div>{errors.email}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Mobile Number</label>
                              <input
                                type="tel"
                                value={profilenew.mobile}
                                //   onChange={(e) => setMobile(e.target.value)}
                                //   value={profilenew.first}
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    mobile: e.target.value,
                                  })
                                }
                                className="form-control"
                                placeholder="Type Your 10 Digit Mobile Number"
                              />
                              <div className="text-danger font-12">
                                {errors.mobile && <div>{errors.mobile}</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Date of Birth:</label>
                              <input
                                type="date"
                                className="form-control"
                                placeholder=""
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    dob: e.target.value,
                                  })
                                }
                              />

                              <div className="text-danger font-12">
                                {errors.dob && <div>{errors.dob}</div>}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Gender:</label>

                              <Form.Control
                                as="select"
                                custom
                                className="btn dropdown-toggle text-left btn-default"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    gender: e.target.value,
                                  })
                                }
                              >
                                <option>Select</option>
                                <option>Male</option>
                                <option>Female</option>
                                <option>Others</option>
                              </Form.Control>
                              <div className="text-danger font-12">
                                {errors.gender && <div>{errors.gender}</div>}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                value={profilenew.password}
                                className="form-control"
                                defaultValue="Password"
                                placeholder="Type Password"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    password: e.target.value,
                                  })
                                }
                              />
                              <div className="text-danger font-12">
                                {errors.password && (
                                  <div>{errors.password}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                value={profilenew.confirmpassword}
                                className="form-control"
                                defaultValue="Password"
                                placeholder="Type Password Again"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    confirmpassword: e.target.value,
                                  })
                                }
                              />
                              <div className="text-danger font-12">
                                {errors.confirmpassword && (
                                  <div>{errors.confirmpassword}</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-lg-6 col-md-6">
                            <div className="form-group">
                              <label>Highest Education:</label>

                              <Form.Control
                                as="select"
                                custom
                                className="btn dropdown-toggle text-left btn-default custom-select-dropdown"
                                onChange={(e) =>
                                  setProfilenew({
                                    ...profilenew,
                                    highest_education: e.target.value,
                                  })
                                }
                              >
                                {/* <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Others</option> */}
                                <option>Select</option>
                                {educationOption.map((ele, id) => (
                                  <option value={ele.value} key={id}>
                                    {ele.value}
                                  </option>
                                ))}
                              </Form.Control>
                              <div className="text-danger font-12">
                                {errors.highest_education && (
                                  <div>{errors.highest_education}</div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <label>Gender:</label>

                            <Form.Control
                              as="select"
                              custom
                              className="btn dropdown-toggle text-left btn-default"
                              onChange={(e) =>
                                setProfilenew({
                                  ...profilenew,
                                  gender: e.target.value,
                                })
                              }
                            >
                              <option>Select</option>
                              <option>Male</option>
                              <option>Female</option>
                              <option>Others</option>
                            </Form.Control>
                            <div className="text-danger font-12">
                              {errors.mobile && <div>{errors.mobile}</div>}
                            </div>
                          </div>
                        </div> */}
                        </div>

                        <div className="form-group text-left">
                          <button
                            type="submit"
                            className="site-button dz-xs-flex m-r5"
                          >
                            Sign me up
                          </button>

                          <Link to="/login">
                            <button
                              type="submit"
                              className="site-button dz-xs-flex m-r5"
                            >
                              Login
                            </button>
                          </Link>

                          {/*
												<span className="custom-control custom-checkbox">
													<input type="checkbox" className="custom-control-input" id="check1" name="example1" />
													<label className="custom-control-label" htmlFor="check1">Remember me</label>
												</span>
												<Link data-toggle="tab" to="#forgot-password" className="forget-pass m-l5"><i className="fa fa-unlock-alt"></i> Forgot Password</Link>
												*/}
                        </div>

                        {/*
											<div className="dz-social clearfix">
												<h5 className="form-title m-t5 pull-left">Sign In With</h5>
												<ul className="dez-social-icon dez-border pull-right dez-social-icon-lg text-white">
													<li><Link to={''} className="fa fa-facebook  fb-btn mr-1" target="bank"></Link></li>
													<li><Link to={''} className="fa fa-twitter  tw-btn mr-1" target="bank"></Link></li>
													<li><Link to={''} className="fa fa-linkedin link-btn mr-1" target="bank"></Link></li>
													<li><Link to={''} className="fa fa-google-plus  gplus-btn" target="bank"></Link></li>
												</ul>
											</div>
											*/}
                      </form>

                      {/*
										<div className="text-center bottom">
											<Link to="/login" className="site-button button-md btn-block text-white">Sign In</Link>
										</div>
										*/}
                    </div>
                  </div>
                  {/*
								<div className="bottom-footer clearfix m-t10 m-b20 row text-center">
									<div className="col-lg-12 text-center">
										<span> © Copyright by <i className="fa fa-heart m-lr5 text-red heart"></i>
											<Link to={''} >DexignZone </Link> All rights reserved.</span>
									</div>
								</div>
								*/}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
/*
const mapStateToProps = (state) => {
	return {
		errorMessage: state.auth.errorMessage,
		successMessage: state.auth.successMessage,
		showLoading: state.auth.showLoading,
	};
};
*/
//export default connect(mapStateToProps)(Register2);
export default Register2;
