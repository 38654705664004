import axios from "axios";
import Swal from "sweetalert2";
import "../../markup/Pages/Styles.css";
import {
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  PROFILE_EDIT_REQUEST,
  PROFILE_EDIT_SUCCESS,
  PROFILE_EDIT_FAIL,
} from "../constants/ProfileConstants";
import moment from "moment";

export const fetchprofile = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: PROFILE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    const payload = {
      registration_id: userInfo["Registration ID"],
    };

    const config = {
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${userInfo.access}`,
      },
      params: payload,
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/user/profile`,
      config
    );
    dispatch({
      type: PROFILE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_FAIL,
      payload: err.response.data,
    });
  }
};

export const editprofile = (profilenew) => async (dispatch, getState) => {
  console.log(profilenew, "A123");
  const formData = new FormData();

  // Append text data
  formData.append("first_name", profilenew.first_name);
  formData.append("last_name", profilenew.last_name);
  formData.append("mobile", profilenew.mobile);
  formData.append("email", profilenew.email);
  formData.append("gender", profilenew.gender);
  formData.append("dob", moment(profilenew.dob).format("DD-MM-YYYY"));
  formData.append("highest_education", profilenew.highest_education);
  formData.append("degree", profilenew.degree);
  formData.append("stream", profilenew.stream);
  formData.append("state", profilenew.state);
  formData.append("district", profilenew.district);
  formData.append("taluka", profilenew.taluka);
  formData.append("registration_id", profilenew.registration_id);
  formData.append("is_active", profilenew.is_active ? 1 : 0);
  formData.append("skills", profilenew.skills);
  formData.append("profile", profilenew.profile);

  const waitingPopup = Swal.fire({
    title: "Please wait...",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    },
    customClass: {
      popup: "my-swal-center",
    },
  });
  try {
    dispatch({
      type: PROFILE_EDIT_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        // Authorization: `Bearer ${userInfo.access}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/user/profile/edit`,
      formData,
      config
    );
    dispatch({
      type: PROFILE_EDIT_SUCCESS,
      payload: data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "success",
      title: "Profile updated successfully",
      //text: res.data["User Message"],
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  } catch (err) {
    dispatch({
      type: PROFILE_EDIT_FAIL,
      payload: err.response.data,
    });
    setTimeout(() => {
      waitingPopup.close();
    }, 0);

    Swal.fire({
      icon: "error",
      title: "Error",
      text: err?.response?.data["Developer Message"]
        ? err?.response?.data["Developer Message"]
        : "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      position: "center",
      // customClass: {
      //   popup: "my-swal-center",
      // },
    });
  }
};
