import React from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import PageTitle from "./../Layout/PageTitle";
import nodataImg from "../../images/images/nodataImg.svg";

var bnr = require("./../../images/banner/bnr1.jpg");

function NoDataPage({ step = 6 }) {
  return (
    <div className="page-wraper">
      {/* <Header /> */}
      <div className="page-content">
        {/* <div
          className="dez-bnr-inr overlay-black-middle"
          style={{ backgroundImage: "url(" + bnr + ")" }}
        >
          <PageTitle motherName="Home" activeName="Error 404" />
        </div> */}
        <div className="p-b90 bg-white">
          <div className="container">
            <div className="row m-t10">
              <div className="col-lg-12 col-md-12 col-sm-12 error-page text-center">
                {/* <h2 className="dz_error text-secondry">404</h2> */}
                {/* <h5>No Data</h5> */}
                <div>
                  <img src={nodataImg} width="50%" height="30%" />
                </div>
                <h6 className="text-primary">
                  {step == 0
                    ? "Pls Enroll yourself to view the jobs of this job fair"
                    : step === 1
                    ? "You haven't applied to any job yet"
                    : step === 6
                    ? ""
                    : "No job in this stage"}
                </h6>
                {/* <Link to={"/"} className="site-button">
                  Back To Home
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}
export default NoDataPage;
