import {
  RESUME_EDIT_FAIL,
  RESUME_EDIT_REQUEST,
  RESUME_EDIT_SUCCESS,
  RESUME_FAIL,
  RESUME_REQUEST,
  RESUME_SUCCESS,
  HEADLINE_EDIT_REQUEST,
  HEADLINE_EDIT_FAIL,
  HEADLINE_EDIT_SUCCESS,
  SKILLS_EDIT_REQUEST,
  SKILLS_EDIT_FAIL,
  SKILLS_EDIT_SUCCESS,
  INTEREST_EDIT_REQUEST,
  INTEREST_EDIT_SUCCESS,
  INTEREST_EDIT_FAIL,
  EMPLOYMENT_EDIT_REQUEST,
  EMPLOYMENT_EDIT_SUCCESS,
  EMPLOYMENT_EDIT_FAIL,
  EDUCATION_EDIT_REQUEST,
  EDUCATION_EDIT_SUCCESS,
  EDUCATION_EDIT_FAIL,
  RESUME_FILE_EDIT_REQUEST,
  RESUME_FILE_EDIT_SUCCESS,
  RESUME_FILE_EDIT_FAIL,
} from "../constants/ResumeContants";

export const resumeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_REQUEST:
      return {
        fetching: true,
      };
    case RESUME_SUCCESS:
      return {
        fetching: false,
        resumeInfo: action.payload,
      };
    case RESUME_FAIL:
      return {
        fetching: false,
        err: action.payload,
      };
    default:
      return state;
  }
};

export const editresumeReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_EDIT_REQUEST:
      return {
        updating: true,
      };
    case RESUME_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case RESUME_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export const editHeadlineResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case HEADLINE_EDIT_REQUEST:
      return {
        updating: true,
      };
    case HEADLINE_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case HEADLINE_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export const editSkillsResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case SKILLS_EDIT_REQUEST:
      return {
        updating: true,
      };
    case SKILLS_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case SKILLS_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export const editEducationResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case EDUCATION_EDIT_REQUEST:
      return {
        updating: true,
      };
    case EDUCATION_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case EDUCATION_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export const editEmploymentResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case EMPLOYMENT_EDIT_REQUEST:
      return {
        updating: true,
      };
    case EMPLOYMENT_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case EMPLOYMENT_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export const editInterestResumeReducer = (state = {}, action) => {
  switch (action.type) {
    case INTEREST_EDIT_REQUEST:
      return {
        updating: true,
      };
    case INTEREST_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case INTEREST_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};

export const editResumeFileReducer = (state = {}, action) => {
  switch (action.type) {
    case RESUME_EDIT_REQUEST:
      return {
        updating: true,
      };
    case RESUME_EDIT_SUCCESS:
      return {
        updating: false,
        message: action.payload,
      };
    case RESUME_EDIT_FAIL:
      return {
        updating: false,
        errors: action.payload,
      };
    default:
      return state;
  }
};