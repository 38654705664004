import React, { useEffect, useState } from "react";
import { Link, useNavigate, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import "../Pages/Styles.css";
import Header from "./../Layout/Header";
import Footer from "./../Layout/Footer";
import { Modal } from "react-bootstrap";
import { Form } from "react-bootstrap";
import Listingsidebar from "./../Element/Listingsidebar";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchprofile } from "../../redux/actions/ProfileActions";
import axios from "axios";
import {
  editEducation,
  editEmployment,
  editHeadline,
  editInterest,
  editresume,
  editResumeFile,
  editSkills,
  fetchresume,
} from "../../redux/actions/ResumeActions";
import swal from "sweetalert";
import { editHeadlineResumeReducer } from "../../redux/reducers/ResumeReducers";
var bnr = require("./../../images/banner/bnr1.jpg");
//var bnr2 = require('./../../images/background/bg3.jpg');

function Jobmyresume({ location }) {
  //   const navigate = useNavigate();
  const availableLevels = [
    "SSC",
    "HSC",
    "Graduation",
    "Post Graduation",
    "PhD",
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
  const userResume = useSelector((state) => state.resumestate);
  const [data, setData] = useState({});
  console.log(userResume, "uree");
  const { userInfo } = userLogin;
  const [basicdetails, setBasicDetails] = useState(false);
  const [resume, setResume] = useState(false);
  const [keyskill, setKeyskill] = useState(false);
  const [employment, setEmployment] = useState(false);
  const [editEmp, setEditEmp] = useState(false);
  const [empData, setEmpData] = useState({});
  const [education, setEducation] = useState(false);
  const [editEdu, setEditEdu] = useState(false);
  const [educationDetail, setEducationDetail] = useState({});
  const profile = useSelector((state) => state.profile);
  const editProfile = useSelector((state) => state.editprofile);
  /*
	const [itskills, setItSkills] = useState(false);
	const [projects, setProjects] = useState(false);
	const [profilesummary, setProfileSummary] = useState(false);
	const [onlineprofile, setOnlineProfile] = useState(false);
	const [worksample, setWorkSample] = useState(false);
	const [whitepaper, setWhitePaper] = useState(false);
	const [presentation, setPresentation] = useState(false);
	const [patent, setPatent] = useState(false);
	const [certification, setCertification] = useState(false);
	const [careerprofile, setCareerProfile] = useState(false);
	const [personaldetails, setPersonalDetails] = useState(false);
	*/

  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [headline, setHeadline] = useState("");
  const [skill, setSkill] = useState("");
  const [multiskills, setMultiskills] = useState(null);
  const [highesteducation, setHighesteducation] = useState("");
  const [degree, setDegree] = useState("");
  const [stream, setStream] = useState("");
  const [interests, setInterests] = useState([]);
  const [interestvalue, setInterestvalue] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [interest, setInterest] = useState(false);
  const [stateOption, setStateOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [stateselected, setStateselected] = useState([]);
  const [educationOption, setEducationOption] = useState([]);
  const [degreeOption, setDegreeOption] = useState([]);
  const [streamOption, setStreamOption] = useState([]);
  const [skillOption, setSkillOption] = useState([]);
  const [profilenew, setProfilenew] = useState({
    // fullname: "",
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    gender: "",
    dob: "",
    highest_education: "",
    degree: "",
    stream: "",
    state: "",
    district: "",
    taluka: "",
    registration_id: "",
    profile: "",
  });

  useEffect(() => {
    console.log(profile?.profileInfo?.data, "112");
    setProfilenew({
      // fullname: "",
      first_name: profile?.profileInfo?.data.first_name
        ? profile?.profileInfo?.data.first_name
        : "",
      last_name: profile?.profileInfo?.data.last_name
        ? profile?.profileInfo?.data.last_name
        : "",
      mobile: profile?.profileInfo?.data.mobile
        ? profile?.profileInfo?.data.mobile.length == 12
          ? profile?.profileInfo?.data.mobile.slice(2)
          : profile?.profileInfo?.data.mobile
        : "",
      email: profile?.profileInfo?.data.email
        ? profile?.profileInfo?.data.email
        : "",
      gender: profile?.profileInfo?.data.gender
        ? profile?.profileInfo?.data.gender
        : "",
      dob: profile?.profileInfo?.data.dob ? profile?.profileInfo?.data.dob : "",
      highest_education: profile?.profileInfo?.data.highest_education
        ? profile?.profileInfo?.data.highest_education
        : "",
      degree: profile?.profileInfo?.data.degree
        ? profile?.profileInfo?.data.degree
        : "",
      stream: profile?.profileInfo?.data.stream
        ? profile?.profileInfo?.data.stream
        : "",
      state: profile?.profileInfo?.data.state
        ? profile?.profileInfo?.data.state
        : "",
      district: profile?.profileInfo?.data.district
        ? profile?.profileInfo?.data.district
        : "",
      taluka: profile?.profileInfo?.data.taluka
        ? profile?.profileInfo?.data.taluka
        : "",
      registration_id: profile?.profileInfo?.data.registration_id
        ? profile?.profileInfo?.data.registration_id
        : "",
      profile: profile?.profileInfo?.data.profile
        ? profile?.profileInfo?.data.profile
        : "",
      is_active: profile?.profileInfo?.data.is_active
        ? profile?.profileInfo?.data.is_active
        : "",
      skills: profile?.profileInfo?.data.skills
        ? profile?.profileInfo?.data.skills
        : "",
    });
  }, [profile]);

  const [employmenttitle, setEmploymenttitle] = useState([
    {
      designation: "",
      organization: "",
      curr_org: "",
      working_from: "",
      working_till: "",
      description: "",
      registration_id: "",
    },
  ]);
  const [employmentD, setEmploymenD] = useState([
    {
      designation: "",
      organization: "",
      curr_org: "",
      working_from: "",
      working_till: "",
      description: "",
    },
  ]);
  const [educationTitle, setEducationTitle] = useState({
    level: "",
    highest_education: "",
    degree: "",
    start_date: "",
    end_date: null,
    stream: "",
    ongoing_education: false,
  });
  const [educationArr, setEducationArr] = useState([
    // {
    //   level: "",
    //   highest_education: "",
    //   degree: "",
    //   start_date: "",
    //   end_date: "",
    //   stream: "",
    //   ongoing_education: "",
    // },
  ]);
  const addFields = (e) => {
    e.preventDefault();
    let newfield = {
      designation: "",
      organization: "",
      curr_org: "",
      working_from: "",
      working_till: "",
      description: "",
      registration_id: "",
    };
    setEmploymenttitle([...employmenttitle, newfield]);
  };

  const addFieldsEducation = (e) => {
    e.preventDefault();
    let newfield = {
      level: "",
      highest_education: "",
      degree: "",
      start_date: "",
      end_date: null,
      stream: "",
      ongoing_education: false,
    };
    // setEducationArr([...educationArr, educationTitle]);
    PostApiEducation();
    setEducationTitle(newfield);
  };

  const handleSave = (e) => {
    addFieldsEducation(e); // Add the current form data to the education array
    setEducation(false); // Close the modal
  };

  const handleForm = (index, name, value) => {
    let data = [...employmenttitle];
    data[index][name] = value;
    setEmploymenttitle(data);
  };

  const removeField = (index) => {
    let data = [...employmenttitle];
    data.splice(index, 1);
    setEmploymenttitle(data);
  };

  const handleFormEdu = (name, value) => {
    console.log("educationTitle", educationTitle);
    let data = { ...educationTitle };
    // data.splice(index,1);
    data[name] = value;
    setEducationTitle(data);
  };

  const [uploadedResume, setUploadedResume] = useState("");
  useEffect(() => {
    console.log(userResume?.resumeInfo, "hh11");
    setData(userResume?.resumeInfo);
    setHeadline(userResume?.resumeInfo?.headline);
    setSkill(userResume?.resumeInfo?.skills);
    setMultiskills(userResume?.resumeInfo?.skills?.split(","));
    setHighesteducation(userResume?.resumeInfo?.highest_education);
    setDegree(userResume?.resumeInfo?.degree);
    setStream(userResume?.resumeInfo?.stream);
    setEmploymenD(userResume?.resumeInfo?.employment);
    setInterestvalue(userResume?.resumeInfo?.interests);
    setInterests(
      userResume?.resumeInfo?.interests
        ?.split(",")
        ?.map((el, i) => ({ label: el, value: el }))
    );
    setUploadedResume(userResume?.resumeInfo?.resume);
    setEducationArr(
      userResume?.resumeInfo?.education?.filter((ele) => ele.level !== "")
    );
  }, [userResume]);
  const options = [
    { label: "Reading", value: "reading" },
    { label: "Traveling", value: "traveling" },
    { label: "Cooking", value: "cooking" },
    { label: "Gardening", value: "gardening" },
    { label: "Photography", value: "photography" },
    { label: "Painting", value: "painting" },
    { label: "Cycling", value: "cycling" },
    { label: "Hiking", value: "hiking" },
    { label: "Dancing", value: "dancing" },
    { label: "Writing", value: "writing" },
    { label: "Sports", value: "Sports" },
    { label: "Movies", value: "Movies" },
  ];

  const { loading, profileInfo, error } = profile;
  const resumedetail = useSelector((state) => state.resumestate);
  const { fetching, resumeInfo, err } = resumedetail;

  useEffect(() => {
    dispatch(fetchprofile());
    dispatch(fetchresume());
  }, [dispatch]);

  console.log(err);

  const editEmpData = async () => {
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-employment`,
        empData
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Employment edited successfully",
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
        dispatch(fetchresume());
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data["message"]
            ? err?.response?.data["message"]
            : "Something went wrong!",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const PatchApiEducation = async () => {
    console.log("payload edit::", educationDetail);
    await axios
      .patch(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-education`,
        {
          // registration_id: userInfo["Registration ID"],

          ...educationDetail,
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Education edited successfully",
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
        dispatch(fetchresume());
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data["message"]
            ? err?.response?.data["message"]
            : "Something went wrong!",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const PostApiEducation = async () => {
    console.log("payload::", educationTitle);
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/add-education`,
        {
          registration_id: userInfo["Registration ID"],
          // start_date: educationTitle.start_date,
          // end_date:
          //   educationTitle.end_date === "" ? null : educationTitle.end_date,
          // ongoing_education:
          //   // educationTitle.ongoing_education !== "" &&
          //   educationTitle.ongoing_education ? 1 : 0,
          ...educationTitle,
        }
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Education added successfully",
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
        dispatch(fetchresume());
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data["message"]
            ? err?.response?.data["message"]
            : "Something went wrong!",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const generateResume = async () => {
    const payload = {
      registration_id: profilenew?.registration_id
        ? profilenew?.registration_id
        : "",
      first_name: profilenew?.first_name ? profilenew?.first_name : "",
      last_name: profilenew?.last_name ? profilenew?.last_name : "",
      email: profilenew?.email ? profilenew?.email : "",
      phone: profilenew?.mobile ? profilenew?.mobile : "",
      taluka: profilenew?.taluka ? profilenew?.taluka : "",
      state: profilenew?.state ? profilenew?.state : "",
      gender: profilenew?.gender ? profilenew?.gender : "",
      language: profilenew?.language ? profilenew?.language : "",
      skill: profilenew.skills ? profilenew?.skills : "",
      current_industry: profilenew.current_industry
        ? profilenew?.current_industry
        : "",
      experience: profilenew.experience ? profilenew?.experience : "",
      highest_education: profilenew?.highest_education
        ? profilenew?.highest_education
        : "",
      desired_job_role: profilenew?.desired_job_role
        ? profilenew?.desired_job_role
        : "",
      current_salary: profilenew?.current_salary
        ? profilenew?.current_salary
        : "",
      desired_salary: profilenew?.desired_salary
        ? profilenew?.desired_salary
        : "",
    };
    await axios
      .post(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/superadmin/register/jobseeker/generate-resume`,
        payload
      )
      .then((res) => {
        console.log(res.data);
        Swal.fire({
          icon: "success",
          title: "Resume generated successfully",
          //text: res.data["User Message"],
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
        dispatch(fetchresume());
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: err?.response?.data["message"]
            ? err?.response?.data["message"]
            : "Something went wrong!",
          showConfirmButton: false,
          timer: 1500,
          position: "center",
          // customClass: {
          //   popup: "my-swal-center",
          // },
        });
      });
  };
  const FetchState = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/state`
      );
      const data = await res?.data;
      setStateOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchEducation = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/qualification`
      );
      const data = await res?.data;
      setEducationOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchDegree = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/degree`
      );
      const data = await res?.data;
      setDegreeOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchStream = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/stream`
      );
      const data = await res?.data;
      setStreamOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchDistrict = async () => {
    try {
      const params = {
        state: stateselected,
      };
      const paramString = new URLSearchParams(params).toString();
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/district?${paramString}`
      );
      const data = await res?.data;
      setDistrictOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  const FetchSkill = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_GENERIC_URL}/generic/skills`
      );
      const data = await res?.data;
      setSkillOption(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    stateselected && FetchDistrict();
  }, [stateselected]);

  useEffect(() => {
    FetchState();
    FetchEducation();
    FetchDegree();
    FetchStream();
    FetchSkill();
  }, []);

  const SaveResume = () => {
    dispatch(
      editresume(
        headline,
        skill,
        employmenttitle,
        highesteducation,
        degree,
        stream,
        interestvalue,
        resumeFile
      )
    );
  };
  const headlineMsg = useSelector((state) => state.headline);
  const skillsMsg = useSelector((state) => state.skills);
  const educationMsg = useSelector((state) => state.education);
  const empMsg = useSelector((state) => state.employment);
  const intMsg = useSelector((state) => state.interest);
  const resumeMsg = useSelector((state) => state.resumeFile);
  const saveHeadline = () => {
    dispatch(editHeadline(headline, userInfo["Registration ID"]));
  };
  const saveInterests = () => {
    dispatch(editInterest(interestvalue, userInfo["Registration ID"]));
  };
  useEffect(() => {
    dispatch(fetchresume());
  }, [headlineMsg, skillsMsg, educationMsg, intMsg, empMsg, resumeMsg]);
  const saveSkills = () => {
    dispatch(editSkills(skill, userInfo["Registration ID"]));
  };
  const saveEducation = () => {
    dispatch(
      editEducation(
        highesteducation,
        degree,
        stream,
        userInfo["Registration ID"]
      )
    );
  };
  const saveEmployment = () => {
    const data = employmenttitle?.map((el, i) => ({
      ...el,
      registration_id: userInfo["Registration ID"],
    }));
    console.log(data, "hello13");
    dispatch(editEmployment(data, userInfo["Registration ID"]));
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file.size / 1024 < 5120) {
      setResumeFile(file);
      dispatch(editResumeFile(file, userInfo["Registration ID"]));
    } else {
      swal.fire({
        icon: "error",
        title: "",
        text: "Please upload files < 5mb.",
        showConfirmButton: false,
        timer: 2000,
        position: "center",
        // customClass: {
        //   popup: "my-swal-center",
        // },
      });
      setResumeFile("");
    }
  };

  useEffect(() => {
    dispatch(fetchprofile());
  }, [dispatch]);

  const addedLevels = new Set(
    userResume?.resumeInfo?.education
      ?.filter((item) => item.level)
      .map((item) => item.level)
  );

  // Filter the available levels based on what's already added
  const remainingLevels = availableLevels.filter(
    (level) => !addedLevels.has(level)
  );

  console.log("array", educationArr);
  console.log("edit obj", educationDetail);
  return (
    <>
      <Header location={location} />
      <div className="page-content">
        <div
          className="overlay-black-dark profile-edit p-t50 p-b20"
          style={{ backgroundImage: "url(" + bnr + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 candidate-info">
                <div className="candidate-detail">
                  <div className="canditate-des text-center">
                    <Link to={"#"}>
                      <img
                        alt=""
                        src={
                          profilenew.profile
                            ? profilenew.profile
                            : require("./../../images/team/default.png")
                        }
                        className="img"
                      />
                    </Link>
                    {/* <div
                      className="upload-link"
                      title="update"
                      data-toggle="tooltip"
                      data-placement="right"
                    >
                      <input type="file" className="update-flie" />
                      <i className="fa fa-camera"></i>
                    </div> */}
                  </div>
                  <div className="text-white browse-job text-left">
                    <h4 className="m-b0">
                      {profilenew?.first_name + " " + profilenew.last_name}
                      {/* <Link
                        to={"#"}
                        onClick={() => setBasicDetails(true)}
                        className="m-l15 font-16 text-white"
                      >
                        <i className="fa fa-pencil"></i>
                      </Link> */}
                    </h4>
                    <p className="m-b15 mt-2">{headline}</p>
                    <ul className="clearfix">
                      <li>
                        <i className="ti-location-pin"></i>
                        {profilenew?.district + " " + profilenew?.state}
                      </li>
                      <li>
                        <i className="ti-mobile"></i>
                        {profilenew?.mobile}
                      </li>
                      <li>
                        <i className="ti-briefcase"></i>Fresher
                      </li>
                      <li>
                        <i className="ti-email"></i>
                        {profilenew?.email}
                      </li>
                    </ul>

                    {/*
										<div className="progress-box m-t10">
											<div className="progress-info">Profile Strength (Average)<span>70%</span></div>
											<div className="progress">
												<div className="progress-bar bg-primary" style={{ width: "80%", }} role="progressbar" ></div>
											</div>
										</div>
										*/}
                  </div>
                </div>
              </div>
              {/*
							<div className="col-lg-4 col-md-5">
								<Link to={"#"}>
									<div className="pending-info text-white p-a25">
										<h5>Pending Action</h5>
										<ul className="list-check secondry">
											<li>Verify Mobile Number</li>
											<li>Add Preferred Location</li>
											<li>Add Resume</li>
										</ul>
									</div>
								</Link>
							</div>
							*/}
            </div>
          </div>
          <Modal
            className="modal fade browse-job modal-bx-info editor"
            show={basicdetails}
            onHide={setBasicDetails}
          >
            <div className="modal-dialog my-0" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="ProfilenameModalLongTitle">
                    Basic Details
                  </h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setBasicDetails(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>First Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                first: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Last Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                last: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Email Address:</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Mobile:</label>
                          <input
                            type="tel"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                mobile: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Date of Birth:</label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder=""
                            onChange={(e) =>
                              setProfilenew({
                                ...profilenew,
                                dob: e.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <label>Gender:</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              gender: e.target.value,
                            })
                          }
                        >
                          <option>Select</option>
                          <option>Male</option>
                          <option>Female</option>
                          <option>Others</option>
                        </Form.Control>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <label>State</label>

                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) => {
                            setProfilenew({
                              ...profilenew,
                              state: e.target.value,
                            });
                            setStateselected(e.target.value);
                          }}
                        >
                          <option>Select</option>
                          {stateOption?.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <label>District</label>
                        <Form.Control
                          as="select"
                          custom
                          className="btn dropdown-toggle text-left btn-default"
                          onChange={(e) =>
                            setProfilenew({
                              ...profilenew,
                              district: e.target.value,
                            })
                          }
                        >
                          <option>Select</option>
                          {districtOption?.map((v) => {
                            return <option>{v.label}</option>;
                          })}
                        </Form.Control>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <label>Taluka</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>

                      {/*
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<div className="row">
														<div className="col-lg-6 col-md-6 col-sm-6 col-6">
															<div className="custom-control custom-radio">
																<input type="radio" className="custom-control-input" id="fresher" name="example1" />
																<label className="custom-control-label" htmlFor="fresher">Fresher</label>
															</div>
														</div>
														<div className="col-lg-6 col-md-6 col-sm-6 col-6">
															<div className="custom-control custom-radio">
																<input type="radio" className="custom-control-input" id="experienced" name="example1" />
																<label className="custom-control-label" htmlFor="experienced">Experienced</label>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>Select Your Country</label>
													<Form.Control as="select">
														<option>India</option>
														<option>Australia</option>
														<option>Bahrain</option>
														<option>China</option>
														<option>Dubai</option>
														<option>France</option>
														<option>Germany</option>
														<option>Hong Kong</option>
														<option>Kuwait</option>
													</Form.Control>
												</div>
											</div>
											<div className="col-lg-6 col-md-6">
												<div className="form-group">
													<label>Select Your Country</label>
													<input type="text" className="form-control" placeholder="Select Your Country" />
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>Select Your City</label>
													<input type="text" className="form-control" placeholder="Select Your City" />
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>Telephone Number</label>
													<div className="row">
														<div className="col-lg-4 col-md-4 col-sm-4 col-4">
															<input type="text" className="form-control" placeholder="Country Code" />
														</div>
														<div className="col-lg-4 col-md-4 col-sm-4 col-4">
															<input type="text" className="form-control" placeholder="Area Code" />
														</div>
														<div className="col-lg-4 col-md-4 col-sm-4 col-4">
															<input type="text" className="form-control" placeholder="Phone Number" />
														</div>
													</div>
												</div>
											</div>
											<div className="col-lg-12 col-md-12">
												<div className="form-group">
													<label>Email Address</label>
													<h6 className="m-a0 font-14">info@example.com</h6>
													<Link to={"#"}>Change Email Address</Link>
												</div>
											</div>
											*/}
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="site-button"
                    onClick={() => setBasicDetails(false)}
                  >
                    Cancel
                  </button>
                  <button type="button" className="site-button">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>

        <div className="content-block">
          <div className="section-full browse-job content-inner-2">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                  <Listingsidebar />
                </div>

                <div className="col-xl-9 col-lg-8 col-md-8 col-sm-12">
                  <div className="d-flex m-b30">
                    <Link
                      to={"/profile"}
                      // onClick={() => history.push("/profile")}
                      className="site-button button-sm float-right"
                    >
                      Back
                    </Link>
                  </div>
                  <div
                    id="resume_headline_bx"
                    className="job-bx bg-white m-b30"
                  >
                    <div className="d-flex">
                      <h5 className="m-b15">Resume Headline</h5>
                      <Link
                        to={"#"}
                        className="site-button add-btn button-sm"
                        onClick={() => setResume(true)}
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </Link>
                    </div>
                    <p className="m-b0">{data?.headline}</p>

                    <Modal
                      show={resume}
                      onHide={setResume}
                      className="modal fade modal-bx-info editor"
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="ResumeheadlineModalLongTitle"
                            >
                              Resume Headline
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setResume(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              It is the first thing recruiters notice in your
                              profile. Write concisely what makes you unique and
                              right person for the job you are looking for.
                            </p>
                            <form>
                              <div className="row">
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      placeholder="Type Description"
                                      value={headline}
                                      onChange={(e) =>
                                        setHeadline(e.target.value)
                                      }
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                setResume(false);
                                setHeadline("");
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                setResume(false);
                                saveHeadline();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <div id="attach_resume_bx" className="job-bx bg-white m-b30">
                    <div className="d-flex justify-content-between align-items-center">
                      <h5 className="m-b10">Attach Resume</h5>
                      {/* <button
                        type="button"
                        className="site-button m-1 w-auto"
                        onClick={generateResume}
                      >
                        {" "}
                        Generate Resume
                      </button> */}
                    </div>
                    <p>
                      Resume is the most important document recruiters look for.
                      Recruiters generally do not look at profiles without
                      resumes.
                    </p>
                    <div className="d-flex-space-between p-l20 p-r20">
                      <div
                        className="attach-resume"
                        // style={{ display: "flex", justifyContent: "center" }}
                      >
                        <label htmlFor="uploadresume">
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <div className="custom-file">
                                  <p className="m-auto align-self-center">
                                    <i className="fa fa-upload"></i>
                                    {"Upload Resume File < 5 MB"}
                                  </p>
                                  <input
                                    type="file"
                                    className="site-button form-control"
                                    id="customFile"
                                    onChange={handleFileUpload}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <p className="text-left">
                            <img
                              src={require("../../images/images/file.png")}
                              width="20px"
                              height="20px"
                            />
                            <a
                              href={uploadedResume ? uploadedResume : ""}
                              className="site-button-link text-black  p-l10"
                              target="_blank"
                            >
                              Your Resume
                            </a>
                          </p>
                        </label>
                      </div>
                      <div style={{ borderLeft: "1px solid #333" }}></div>
                      {uploadedResume == "" ? (
                        <p className="text-center">
                          If you do not have a resume document, you may write
                          your brief professional profile{" "}
                          {/* <Link to={"#"} className="site-button-link"> */}
                          here
                          {/* </Link> */}.
                        </p>
                      ) : (
                        <div
                          // className="d-flex flex-gap"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            // alignItems: "center",
                          }}
                        >
                          {/* <p className="text-left">
                            <img
                              src={require("../../images/images/file.png")}
                              width="20px"
                              height="20px"
                            />
                            <a
                              href={uploadedResume ? uploadedResume : ""}
                              className="site-button-link text-black  p-l10"
                              target="_blank"
                            >
                              Download Your Resume
                            </a>
                          </p> */}
                          <div
                            className="attach-resume"
                            onClick={generateResume}
                          >
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <div className="custom-file">
                                    <p className="m-auto align-self-center text-black fw6">
                                      <i className="fa fa-download"></i>
                                      {"Let us generate your resume."}
                                    </p>
                                    {/* <input
                                      type="file"
                                      className="site-button form-control"
                                      id="customFile"
                                      onChange={handleFileUpload}
                                    /> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {userResume?.resumeInfo?.gen_resume !== "" ? (
                            <p className="text-left">
                              <img
                                src={require("../../images/images/file.png")}
                                width="20px"
                                height="20px"
                              />
                              <a
                                href={
                                  userResume?.resumeInfo?.gen_resume
                                    ? userResume?.resumeInfo?.gen_resume
                                    : ""
                                }
                                className="site-button-link text-black p-l10"
                                target="_blank"
                              >
                                Generated Resume
                              </a>
                            </p>
                          ) : (
                            <p>You have not genrated resume yet.</p>
                          )}
                        </div>
                      )}
                    </div>

                    {/* <input
                      type="file"
                      id="uploadresume"
                      style={{ display: "none" }}
                      onClick={(e) => setResumeFile(e.target.files[0])}
                    /> */}
                  </div>
                  <div id="key_skills_bx" className="job-bx bg-white m-b30">
                    <div className="d-flex">
                      <h5 className="m-b15">Key Skills</h5>
                      <Link
                        to={"#"}
                        data-toggle="modal"
                        data-target="#keyskills"
                        onClick={() => setKeyskill(true)}
                        className="site-button add-btn button-sm"
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </Link>
                    </div>
                    <div className="job-time mr-auto">
                      {multiskills?.map((v, i) => {
                        return (
                          <Link id={i} to={"#"} className="mr-1">
                            <span>{v}</span>
                          </Link>
                        );
                      })}
                    </div>

                    <Modal
                      show={keyskill}
                      onHide={setKeyskill}
                      className="modal fade modal-bx-info editor"
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="KeyskillsModalLongTitle"
                            >
                              Key Skills
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setKeyskill(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              It is the first thing recruiters notice in your
                              profile. Write concisely what makes you unique and
                              right person for the job you are looking for.
                            </p>
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div
                                  className="form-group"
                                  style={{ height: "200px" }}
                                >
                                  <Select
                                    value={
                                      skill !== ""
                                        ? skill?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : ""
                                    }
                                    onChange={(e) => {
                                      //   if (e) {
                                      //     setMultiskills(e.map((item) => item));
                                      //   } else {
                                      //     setMultiskills([]);
                                      //   }
                                      setSkill(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    isMulti
                                    options={skillOption}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => setKeyskill(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                setKeyskill(false);
                                saveSkills();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>

                  <div id="employment_bx" className="job-bx bg-white m-b30 ">
                    <div className="d-flex">
                      <h5 className="m-b15">Experience</h5>
                      <Link
                        to={"#"}
                        onClick={() => setEmployment(true)}
                        className="site-button add-btn button-sm"
                      >
                        <i className="fa fa-plus m-r5"></i> Add
                      </Link>
                    </div>

                    {employmentD?.map((v, i) => {
                      return (
                        <div id={1} className="mb-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <h6 className="font-14 m-b0">{v?.designation}</h6>
                            <Link
                              to={"#"}
                              onClick={() => {
                                setEmpData({
                                  ...v,
                                  registration_id: userInfo["Registration ID"],
                                });
                                setEditEmp(true);
                              }}
                              className="site-button add-btn button-sm"
                            >
                              <i className="fa fa-pencil m-r5"></i> Edit
                            </Link>
                          </div>
                          <p className="m-b0">{v?.organization}</p>
                          <p className="m-b0">
                            {v?.working_from}{" "}
                            {v?.working_from === "" ? "" : "-"}{" "}
                            {v?.working_till}
                          </p>
                          <p className="m-b0">{v?.description}</p>
                        </div>
                      );
                    })}

                    <Modal
                      show={editEmp}
                      onHide={setEditEmp}
                      className="modal fade modal-bx-info editor"
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="EmploymentModalLongTitle"
                            >
                              Edit Experience
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setEditEmp(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            {/* {empData?.map((v, i) => {
                              return ( */}
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Your Designation</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="designation"
                                    onChange={(event) => {
                                      setEmpData({
                                        ...empData,
                                        designation: event.target.value,
                                      });
                                    }}
                                    value={empData?.designation}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Your Organisation</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Organisation"
                                    name="organization"
                                    onChange={(event) => {
                                      setEmpData({
                                        ...empData,
                                        organization: event.target.value,
                                      });
                                    }}
                                    value={empData?.organization}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Is this your current company?</label>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          id="employ_yes"
                                          name="curr_org"
                                          onChange={(event) => {
                                            setEmpData({
                                              ...empData,
                                              curr_org: event.target.value,
                                            });
                                          }}
                                          checked={empData?.curr_org === "Yes"}
                                          value={"Yes"}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="employ_yes"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          id="employ_no"
                                          name="curr_org"
                                          onChange={(event) => {
                                            setEmpData({
                                              ...empData,
                                              curr_org: event.target.value,
                                            });
                                          }}
                                          checked={empData?.curr_org === "No"}
                                          value={"No"}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="employ_no"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Started Working From</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="working_from"
                                    onChange={(event) => {
                                      setEmpData({
                                        ...empData,
                                        working_from: event.target.value,
                                      });
                                    }}
                                    value={empData?.working_from}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Worked Till</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="working_till"
                                    onChange={(event) => {
                                      setEmpData({
                                        ...empData,
                                        working_till: event.target.value,
                                      });
                                    }}
                                    value={empData?.working_till}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Describe your Job Profile</label>
                                  <textarea
                                    className="form-control"
                                    placeholder="Type Description"
                                    name="description"
                                    onChange={(event) => {
                                      setEmpData({
                                        ...empData,
                                        description: event.target.value,
                                      });
                                    }}
                                    value={empData?.description}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                            {/* );
                            })} */}
                          </div>

                          <div className="modal-footer">
                            {/* <button
                              type="button"
                              className="site-button"
                              onClick={(e) => addFields(e)}
                            >
                              Add More
                            </button> */}
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => setEditEmp(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                setEditEmp(false);
                                editEmpData();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>

                    <Modal
                      show={employment}
                      onHide={setEmployment}
                      className="modal fade modal-bx-info editor"
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="EmploymentModalLongTitle"
                            >
                              Add Experience
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setEmployment(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            {console.log(employmenttitle, "hello1")}
                            {employmenttitle?.map((v, i) => {
                              return (
                                <div className="row" id={i} key={i}>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>Your Designation</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Your Designation"
                                        name="designation"
                                        onChange={(event) => {
                                          handleForm(
                                            i,
                                            event.target.name,
                                            event.target.value
                                          );
                                        }}
                                        value={v.designation}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>Your Organisation</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Your Organisation"
                                        name="organization"
                                        onChange={(event) => {
                                          handleForm(
                                            i,
                                            event.target.name,
                                            event.target.value
                                          );
                                        }}
                                        value={v.organization}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>
                                        Is this your current company?
                                      </label>
                                      <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                          <div className="custom-control custom-radio">
                                            <input
                                              type="radio"
                                              className="custom-control-input"
                                              id="employ_yes"
                                              name="curr_org"
                                              onChange={(event) => {
                                                console.log(
                                                  event.target,
                                                  "em11"
                                                );
                                                handleForm(
                                                  i,
                                                  event.target.name,
                                                  event.target.value
                                                );
                                              }}
                                              value={"Yes"}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="employ_yes"
                                            >
                                              Yes
                                            </label>
                                          </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                          <div className="custom-control custom-radio">
                                            <input
                                              type="radio"
                                              className="custom-control-input"
                                              id="employ_no"
                                              name="curr_org"
                                              onChange={(event) => {
                                                handleForm(
                                                  i,
                                                  event.target.name,
                                                  event.target.value
                                                );
                                              }}
                                              value={"No"}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor="employ_no"
                                            >
                                              No
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>Started Working From</label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Enter Your Designation"
                                        name="working_from"
                                        onChange={(event) => {
                                          handleForm(
                                            i,
                                            event.target.name,
                                            event.target.value
                                          );
                                        }}
                                        value={v.working_from}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>Worked Till</label>
                                      <input
                                        type="date"
                                        className="form-control"
                                        placeholder="Enter Your Designation"
                                        name="working_till"
                                        onChange={(event) => {
                                          handleForm(
                                            i,
                                            event.target.name,
                                            event.target.value
                                          );
                                        }}
                                        value={v.working_till}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label>Describe your Job Profile</label>
                                      <textarea
                                        className="form-control"
                                        placeholder="Type Description"
                                        name="description"
                                        onChange={(event) => {
                                          handleForm(
                                            i,
                                            event.target.name,
                                            event.target.value
                                          );
                                        }}
                                        value={v.description}
                                      ></textarea>
                                    </div>

                                    {(employmenttitle?.length !== 1) &
                                    (i !== 0) ? (
                                      <button
                                        type="button"
                                        className="site-button mb-3"
                                        onClick={() => removeField(i)}
                                      >
                                        Delete
                                      </button>
                                    ) : null}
                                  </div>
                                </div>
                              );
                            })}
                            {/* <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Your Designation</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="designation"
                                    onChange={(event) => {
                                      handleForm(
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    // value={v.designation}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Your Organization</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="organization"
                                    onChange={(event) => {
                                      handleForm(
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    // value={v.organization}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Is this your current company?</label>
                                  <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          id="employ_yes"
                                          name="curr_org"
                                          onChange={(event) => {
                                            handleForm(
                                              event.target.name,
                                              event.target.value
                                            );
                                          }}
                                          //   value={v.curr_org}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="employ_yes"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          id="employ_no"
                                          name="curr_org"
                                          onChange={(event) => {
                                            handleForm(
                                              event.target.name,
                                              event.target.value
                                            );
                                          }}
                                          //   value={v.curr_org}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="employ_no"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Started Working From</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="working_from"
                                    onChange={(event) => {
                                      handleForm(
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    // value={v.working_from}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Worked Till</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="working_till"
                                    onChange={(event) => {
                                      handleForm(
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    // value={v.working_till}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Describe your Job Profile</label>
                                  <textarea
                                    className="form-control"
                                    placeholder="Type Description"
                                    name="description"
                                    onChange={(event) => {
                                      handleForm(
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    // value={v.description}
                                  ></textarea>
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="modal-footer">
                            {/* <button
                              type="button"
                              className="site-button"
                              onClick={(e) => addFields(e)}
                            >
                              Add More
                            </button> */}
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => setEmployment(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                setEmployment(false);
                                saveEmployment();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                  <div id="education_bx" className="job-bx bg-white m-b30">
                    <div className="d-flex">
                      <h5 className="m-b15">Education</h5>
                      {/* <Link
                        to={"#"}
                        onClick={() => setEducation(true)}
                        className="site-button add-btn button-sm"
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </Link> */}
                      {/* <Link
                        to={"#"}
                        onClick={() => setEducation(true)}
                        className="site-button add-btn button-sm"
                      >
                        <i className="fa fa-plus m-r5"></i> Add
                      </Link> */}
                    </div>
                    {/* <p>
                      Mention your employment details including your current and
                      previous company work experience
                    </p> */}
                    <Modal
                      className="modal fade modal-bx-info editor"
                      show={education}
                      onHide={setEducation}
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="EducationModalLongTitle"
                            >
                              Education
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setEducation(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label> Highest Education</label>
                                  <Form.Control
                                    as="select"
                                    value={educationTitle.highest_education}
                                    onChange={(e) => (
                                      setHighesteducation(e.target.value),
                                      handleFormEdu(
                                        // i,
                                        // e.target.name,
                                        "highest_education",
                                        e.target.value
                                      )
                                    )}
                                  >
                                    <option>Select</option>
                                    {educationOption?.map((v) => {
                                      return <option>{v.label}</option>;
                                    })}
                                  </Form.Control>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Degree</label>
                                  <Form.Control
                                    as="select"
                                    value={educationTitle.degree}
                                    onChange={(e) => (
                                      setDegree(e.target.value),
                                      handleFormEdu(
                                        // i,
                                        // e.target.name,
                                        "degree",
                                        e.target.value
                                      )
                                    )}
                                  >
                                    <option>Select</option>
                                    {degreeOption?.map((v) => {
                                      return <option>{v.label}</option>;
                                    })}
                                  </Form.Control>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Stream</label>
                                  <Form.Control
                                    as="select"
                                    value={educationTitle.stream}
                                    onChange={(e) => (
                                      setStream(e.target.value),
                                      handleFormEdu(
                                        // i,
                                        // e.target.name,
                                        "stream",
                                        e.target.value
                                      )
                                    )}
                                  >
                                    <option>Select</option>
                                    {streamOption?.map((v) => {
                                      return <option>{v.label}</option>;
                                    })}
                                  </Form.Control>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Started From</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="start_date"
                                    onChange={(event) => {
                                      handleFormEdu(
                                        // i,
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    value={educationTitle.start_date}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Completed Till</label>
                                  <input
                                    type="date"
                                    disabled={
                                      educationTitle.ongoing_education
                                        ? true
                                        : false
                                    }
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="end_date"
                                    onChange={(event) => {
                                      handleFormEdu(
                                        // i,
                                        event.target.name,
                                        event.target.value
                                      );
                                    }}
                                    value={educationTitle.end_date}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      // className="custom-control-input"
                                      id="ongoing_education"
                                      name="ongoing_education"
                                      onChange={(event) => {
                                        console.log(event.target, "em11");
                                        handleFormEdu(
                                          // i,
                                          event.target.name,
                                          event.target.checked
                                        );
                                      }}
                                      // value={"Yes"}
                                    />
                                    <label htmlFor="ongoing_education">
                                      Are you still pursuing ?
                                    </label>
                                  </div>
                                  {/* <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          id="ongoing_education"
                                          name="ongoing_education"
                                          onChange={(event) => {
                                            console.log(event.target, "em11");
                                            handleFormEdu(
                                              // i,
                                              event.target.name,
                                              event.target.value
                                            );
                                          }}
                                          value={"Yes"}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="ongoing_education"
                                        >
                                          Yes
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                      <div className="custom-control custom-radio">
                                        <input
                                          type="radio"
                                          className="custom-control-input"
                                          id="ongoing_education"
                                          name="ongoing_education"
                                          onChange={(event) => {
                                            handleFormEdu(
                                              // i,
                                              event.target.name,
                                              event.target.value
                                            );
                                          }}
                                          value={"No"}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="ongoing_education"
                                        >
                                          No
                                        </label>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                // setEducation(false);
                                setEducation(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              // onClick={(e) => {
                              //   setEducation(false);
                              //   // saveEducation();
                              //   e.preventDefault();
                              //   PatchApiEducation();
                              // }}
                              onClick={(e) => handleSave(e)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <Modal
                      show={editEdu}
                      onHide={setEditEdu}
                      className="modal fade modal-bx-info editor"
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="EducationModalLongTitle"
                            >
                              Edit Education
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setEditEdu(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label> Highest Education</label>
                                  <Form.Control
                                    as="select"
                                    value={educationDetail.highest_education}
                                    onChange={(e) =>
                                      // setHighesteducation(e.target.value),
                                      // handleFormEdu(
                                      //   // i,
                                      //   // e.target.name,
                                      //   "highest_education",
                                      //   e.target.value
                                      // )
                                      setEducationDetail({
                                        ...educationDetail,
                                        highest_education: e.target.value,
                                      })
                                    }
                                  >
                                    <option>Select</option>
                                    {educationOption?.map((v) => {
                                      return <option>{v.label}</option>;
                                    })}
                                  </Form.Control>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Degree</label>
                                  <Form.Control
                                    as="select"
                                    value={educationDetail.degree}
                                    onChange={(e) =>
                                      // setDegree(e.target.value),
                                      // handleFormEdu(
                                      //   // i,
                                      //   // e.target.name,
                                      //   "degree",
                                      //   e.target.value
                                      // )
                                      setEducationDetail({
                                        ...educationDetail,
                                        degree: e.target.value,
                                      })
                                    }
                                  >
                                    <option>Select</option>
                                    {degreeOption?.map((v) => {
                                      return <option>{v.label}</option>;
                                    })}
                                  </Form.Control>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Stream</label>
                                  <Form.Control
                                    as="select"
                                    value={educationDetail.stream}
                                    onChange={(e) =>
                                      // setStream(e.target.value),
                                      // handleFormEdu(
                                      //   // i,
                                      //   // e.target.name,
                                      //   "stream",
                                      //   e.target.value
                                      // )
                                      setEducationDetail({
                                        ...educationDetail,
                                        stream: e.target.value,
                                      })
                                    }
                                  >
                                    <option>Select</option>
                                    {streamOption?.map((v) => {
                                      return <option>{v.label}</option>;
                                    })}
                                  </Form.Control>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Started From</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="start_date"
                                    onChange={(e) => {
                                      // handleFormEdu(
                                      //   // i,
                                      //   event.target.name,
                                      //   event.target.value
                                      // );
                                      setEducationDetail({
                                        ...educationDetail,
                                        start_date: e.target.value,
                                      });
                                    }}
                                    value={educationDetail.start_date}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label>Completed Till</label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    placeholder="Enter Your Designation"
                                    name="end_date"
                                    disabled={
                                      educationDetail.ongoing_education
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      // handleFormEdu(
                                      //   // i,
                                      //   event.target.name,
                                      //   event.target.value
                                      // );
                                      setEducationDetail({
                                        ...educationDetail,
                                        end_date:
                                          educationDetail.ongoing_education
                                            ? null
                                            : e.target.value,
                                      });
                                    }}
                                    value={educationDetail.end_date}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      // className="custom-control-input"
                                      id="ongoing_education"
                                      name="ongoing_education"
                                      onChange={(e) => {
                                        // console.log(event.target, "em11");
                                        // handleFormEdu(
                                        //   // i,
                                        //   event.target.name,
                                        //   event.target.checked
                                        // );
                                        setEducationDetail({
                                          ...educationDetail,
                                          ongoing_education: e.target.checked,
                                        });
                                      }}
                                      checked={
                                        educationDetail.ongoing_education
                                      }
                                    />
                                    <label htmlFor="ongoing_education">
                                      Are you still pursuing ?
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                // setEducation(false);
                                setEditEdu(false);
                              }}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              onClick={(e) => {
                                setEditEdu(false);
                                // saveEducation();
                                e.preventDefault();
                                PatchApiEducation();
                              }}
                              // onClick={(e) => handleSave(e)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    {educationArr?.map((v, id) => (
                      <div key={id} className="mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6 className="font-14 m-b0">{v?.level}</h6>
                          <Link
                            to={"#"}
                            onClick={() => {
                              setEducationDetail({
                                ...v,
                                id: v?.id,
                                // registration_id: userInfo["Registration ID"],
                              });
                              setEditEdu(true);
                            }}
                            className="site-button add-btn button-sm"
                          >
                            <i className="fa fa-pencil m-r5"></i> Edit
                          </Link>
                        </div>

                        <p className="m-b0">{v?.highest_education}</p>
                        <p className="m-b0">
                          {v?.start_date} {v?.start_date === "" ? "" : "-"}{" "}
                          {v?.end_date !== "" && v?.ongoing_education === false
                            ? v?.end_date
                            : "present"}
                        </p>
                        <p className="m-b0">{v?.degree}</p>
                        <p className="m-b0">{v?.stream}</p>
                        {/* <div className="clearfix">
                          <label className="m-b0">Degree</label>
                          <p>{v.degree}</p>
                        </div>

                        <div className="clearfix">
                          <label className="m-b0">Stream</label>
                          <p>{v.stream}</p>
                        </div> */}
                      </div>
                    ))}
                    {remainingLevels.includes("SSC") && (
                      <Link
                        to={"#"}
                        className="clearfix"
                        onClick={() => (
                          setEducation(true),
                          setEducationTitle({ ...educationTitle, level: "SSC" })
                        )}
                      >
                        Add SSC
                      </Link>
                    )}
                    {remainingLevels.includes("HSC") && (
                      <Link
                        to={"#"}
                        className="clearfix"
                        onClick={() => (
                          setEducation(true),
                          setEducationTitle({ ...educationTitle, level: "HSC" })
                        )}
                      >
                        Add HSC
                      </Link>
                    )}
                    {remainingLevels.includes("Graduation") && (
                      <Link
                        to={"#"}
                        className="clearfix"
                        onClick={() => (
                          setEducation(true),
                          setEducationTitle({
                            ...educationTitle,
                            level: "Graduation",
                          })
                        )}
                      >
                        Add Graduation/Diploma
                      </Link>
                    )}
                    {remainingLevels.includes("Post Graduation") && (
                      <Link
                        to={"#"}
                        className="clearfix"
                        onClick={() => (
                          setEducation(true),
                          setEducationTitle({
                            ...educationTitle,
                            level: "Post Graduation",
                          })
                        )}
                      >
                        Add Masters/Post-Graduation
                      </Link>
                    )}
                    {remainingLevels.includes("PhD") && (
                      <Link
                        to={"#"}
                        className="clearfix"
                        onClick={() => (
                          setEducation(true),
                          setEducationTitle({
                            ...educationTitle,
                            level: "PhD",
                          })
                        )}
                      >
                        Add Doctorate/PhD
                      </Link>
                    )}
                  </div>

                  <div id="interest" className="job-bx bg-white m-b30">
                    <div className="d-flex">
                      <h5 className="m-b15">Interests</h5>
                      <Link
                        to={"#"}
                        data-toggle="modal"
                        data-target="#interest"
                        onClick={() => setInterest(true)}
                        className="site-button add-btn button-sm"
                      >
                        <i className="fa fa-pencil m-r5"></i> Edit
                      </Link>
                    </div>
                    <div className="job-time mr-auto">
                      {console.log(interests, "it11")}
                      {interests?.map((v, i) => {
                        return (
                          <Link id={i} to={"#"} className="mr-1">
                            <span>{v.label}</span>
                          </Link>
                        );
                      })}
                    </div>

                    <Modal
                      show={interest}
                      onHide={setInterest}
                      className="modal fade modal-bx-info editor"
                    >
                      <div className="modal-dialog my-0" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5
                              className="modal-title"
                              id="KeyskillsModalLongTitle"
                            >
                              Interests
                            </h5>
                            <button
                              type="button"
                              className="close"
                              onClick={() => setInterest(false)}
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              It is the first thing recruiters notice in your
                              profile. Write concisely what makes you unique and
                              right person for the job you are looking for.
                            </p>
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div
                                  className="form-group"
                                  style={{ height: "200px" }}
                                >
                                  <Select
                                    value={
                                      interestvalue !== ""
                                        ? interestvalue?.split(",").map(
                                            (ele) =>
                                              ele !== "null" && {
                                                label: ele,
                                                value: ele,
                                              }
                                          )
                                        : interests
                                    }
                                    onChange={(e) => {
                                      if (e) {
                                        setInterests(e.map((item) => item));
                                      } else {
                                        setInterests([]);
                                      }
                                      setInterestvalue(
                                        e
                                          .map((ele) => ele)
                                          .map((item) => item.value)
                                          .join()
                                      );
                                    }}
                                    isMulti
                                    options={options}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => setInterest(false)}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              className="site-button"
                              onClick={() => {
                                setInterest(false);
                                saveInterests();
                              }}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>

                  {/*
									<div id="it_skills_bx" className="job-bx table-job-bx bg-white m-b30">
										<div className="d-flex">
											<h5 className="m-b15">IT Skills</h5>
											<Link to={"#"} onClick={()=>setItSkills(true)} className="site-button add-btn button-sm">
												<i className="fa fa-pencil m-r5"></i> Edit
											</Link>
										</div>
										<p>Mention your employment details including your current and previous company work experience</p>
										<table>
											<thead>
												<tr>
													<th>Skills</th>
													<th>Version</th>
													<th>Last Used</th>
													<th>Experience</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Bootstrap</td>
													<td>3</td>
													<td>2018</td>
													<td>1 Year 5 Months</td>
													<td><Link to={"#"} className="m-l15 font-14" data-toggle="modal" data-target="#itskills" ><i className="fa fa-pencil"></i></Link></td>
												</tr>
												<tr>
													<td>Bootstrap</td>
													<td>4</td>
													<td>2013</td>
													<td>5 Year 5 Months</td>
													<td><Link to={"#"} className="m-l15 font-14" data-toggle="modal" data-target="#itskills"><i className="fa fa-pencil"></i></Link></td>
												</tr>
												<tr>
													<td>html</td>
													<td>5</td>
													<td>2016</td>
													<td>2 Year 7 Months</td>
													<td><Link to={"#"} className="m-l15 font-14" data-toggle="modal" data-target="#itskills" ><i className="fa fa-pencil"></i></Link></td>
												</tr>
												<tr>
													<td>css</td>
													<td>3</td>
													<td>2018</td>
													<td>0 Year 5 Months</td>
													<td><Link to={"#"} className="m-l15 font-14" data-toggle="modal" data-target="#itskills"><i className="fa fa-pencil"></i></Link></td>
												</tr>
												<tr>
													<td>photoshop</td>
													<td>64bit</td>
													<td>2017</td>
													<td>1 Year 0 Months</td>
													<td><Link to={"#"} className="m-l15 font-14" data-toggle="modal" data-target="#itskills"><i className="fa fa-pencil"></i></Link></td>
												</tr>
											</tbody>
										</table>
										
										<Modal className="modal fade modal-bx-info editor" show={itskills} onHide={setItSkills}>
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" >IT Skills</h5>
														<button type="button" className="close" onClick={()=>setItSkills(false)}><span>&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>IT Skills</label>
																		<input type="email" className="form-control" placeholder="Enter IT Skills" />
																	</div>
																</div>
																<div className="col-lg-6 col-md-6">
																	<div className="form-group">
																		<label>Version</label>
																		<input type="email" className="form-control" placeholder="Enter Version" />
																	</div>
																</div>
																<div className="col-lg-6 col-md-6">
																	<div className="form-group">
																		<label>Last Used</label>
																		<Form.Control as="select">
																			<option>2018</option>
																			<option>2017</option>
																			<option>2016</option>
																			<option>2015</option>
																			<option>2014</option>
																			<option>2013</option>
																			<option>2012</option>
																			<option>2011</option>
																			<option>2010</option>
																			<option>2009</option>
																			<option>2008</option>
																			<option>2007</option>
																			<option>2006</option>
																			<option>2005</option>
																			<option>2004</option>
																			<option>2003</option>
																			<option>2002</option>
																			<option>2001</option>
																		</Form.Control>
																	</div>
																</div>
																<div className="col-lg-12 col-md-6">
																	<div className="form-group">
																		<label>Experience</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select">
																					<option>2018</option>
																					<option>2017</option>
																					<option>2016</option>
																					<option>2015</option>
																					<option>2014</option>
																					<option>2013</option>
																					<option>2012</option>
																					<option>2011</option>
																					<option>2010</option>
																					<option>2009</option>
																					<option>2008</option>
																					<option>2007</option>
																					<option>2006</option>
																					<option>2005</option>
																					<option>2004</option>
																					<option>2003</option>
																					<option>2002</option>
																					<option>2001</option>
																				</Form.Control>
																			</div>
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select">
																					<option>january</option>
																					<option>february</option>
																					<option>March</option>
																					<option>April</option>
																					<option>May</option>
																					<option>Jun</option>
																					<option>July</option>
																					<option>August</option>
																					<option>September</option>
																					<option>October</option>
																					<option>November</option>
																					<option>December</option>
																				</Form.Control>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setItSkills(false)}>Cancel</button>
														<button type="button" className="site-button">Save</button>
													</div>
												</div>
											</div>
										</Modal>
										
									</div>
									*/}

                  {/*
									<div id="projects_bx" className="job-bx bg-white m-b30">
										<div className="d-flex">
											<h5 className="m-b15">Projects</h5>
											<Link to={"#"} onClick={()=>setProjects(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
										</div>
										<h6 className="font-14 m-b0">Job BoardEdit</h6>
										<p className="m-b0">w3itexpert (Offsite)</p>
										<p className="m-b0">Dec 2018 to Present (Full Time)</p>
										<p className="m-b0">Job Board Template</p>
										
										<Modal className="modal fade modal-bx-info editor" show={projects} onHide={setProjects}> 
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" id="ProjectsModalLongTitle">Add Projects</h5>
														<button type="button" className="close" onClick={()=>setProjects(false)}>
															<span aria-hidden="true">&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Project Title</label>
																		<input type="email" className="form-control" placeholder="Enter Project Title" />
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Tag this project with your Employment/Education</label>
																		<select>
																			<option>Class 12th</option>
																			<option>Class 10th</option>
																		</select>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Client</label>
																		<input type="email" className="form-control" placeholder="Enter Client Name" />
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Project Status</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<div className="custom-control custom-radio">
																					<input type="radio" className="custom-control-input" id="inprogress" name="example1" />
																					<label className="custom-control-label" htmlFor="inprogress">In Progress</label>
																				</div>
																			</div>
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<div className="custom-control custom-radio">
																					<input type="radio" className="custom-control-input" id="finished" name="example1" />
																					<label className="custom-control-label" htmlFor="finished">Finished</label>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-6">
																	<div className="form-group">
																		<label>Started Working From</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select">
																					<option>2018</option>
																					<option>2017</option>
																					<option>2016</option>
																					<option>2015</option>
																					<option>2014</option>
																					<option>2013</option>
																					<option>2012</option>
																					<option>2011</option>
																					<option>2010</option>
																					<option>2009</option>
																					<option>2008</option>
																					<option>2007</option>
																					<option>2006</option>
																					<option>2005</option>
																					<option>2004</option>
																					<option>2003</option>
																					<option>2002</option>
																					<option>2001</option>
																				</Form.Control>
																			</div>
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select">
																					<option>january</option>
																					<option>february</option>
																					<option>March</option>
																					<option>April</option>
																					<option>May</option>
																					<option>Jun</option>
																					<option>July</option>
																					<option>August</option>
																					<option>September</option>
																					<option>October</option>
																					<option>November</option>
																					<option>December</option>
																				</Form.Control>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-6">
																	<div className="form-group">
																		<label>Worked Till</label>
																		<div className="row">
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select">
																					<option>2018</option>
																					<option>2017</option>
																					<option>2016</option>
																					<option>2015</option>
																					<option>2014</option>
																					<option>2013</option>
																					<option>2012</option>
																					<option>2011</option>
																					<option>2010</option>
																					<option>2009</option>
																					<option>2008</option>
																					<option>2007</option>
																					<option>2006</option>
																					<option>2005</option>
																					<option>2004</option>
																					<option>2003</option>
																					<option>2002</option>
																					<option>2001</option>
																				</Form.Control>
																			</div>
																			<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																				<Form.Control as="select">
																					<option>january</option>
																					<option>february</option>
																					<option>March</option>
																					<option>April</option>
																					<option>May</option>
																					<option>Jun</option>
																					<option>July</option>
																					<option>August</option>
																					<option>September</option>
																					<option>October</option>
																					<option>November</option>
																					<option>December</option>
																				</Form.Control>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Details of Project</label>
																		<textarea className="form-control" placeholder="Type Description"></textarea>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setProjects(false)}>Cancel</button>
														<button type="button" className="site-button">Save</button>
													</div>
												</div>
											</div>
										</Modal>
										
									</div>
									*/}

                  {/*
									<div id="profile_summary_bx" className="job-bx bg-white m-b30" >
										<div className="d-flex">
											<h5 className="m-b15">Profile Summary</h5>
											<Link to={"#"} onClick={()=>setProfileSummary(true)} className="site-button add-btn button-sm">
												<i className="fa fa-pencil m-r5"></i> Edit
											</Link>
										</div>
										<p className="m-b0">Your Profile Summary should mention the highlights of your career and education, what your professional interests are, and what kind of a career you are looking for. Write a meaningful summary of more than 50 characters.</p>
										
										<Modal className="modal fade modal-bx-info editor" show={profilesummary} onHide={setProfileSummary}>
											<div className="modal-dialog my-0" role="document">
												<div className="modal-content">
													<div className="modal-header">
														<h5 className="modal-title" >Profile Summary</h5>
														<button type="button" className="close"  onClick={()=>setProfileSummary(false)}>
															<span aria-hidden="true">&times;</span>
														</button>
													</div>
													<div className="modal-body">
														<p>Your Profile Summary should mention the highlights of your career and education, what your professional interests are, and what kind of a career you are looking for. Write a meaningful summary of more than 50 characters.</p>
														<form>
															<div className="row">
																<div className="col-lg-12 col-md-12">
																	<div className="form-group">
																		<label>Details of Project</label>
																		<textarea className="form-control" placeholder="Type Description"></textarea>
																	</div>
																</div>
															</div>
														</form>
													</div>
													<div className="modal-footer">
														<button type="button" className="site-button" onClick={()=>setProfileSummary(false)}>Cancel</button>
														<button type="button" className="site-button">Save</button>
													</div>
												</div>
											</div>
										</Modal>
										
									</div>
									*/}

                  {/*
									<div id="accomplishments_bx" className="job-bx bg-white m-b30">
										<h5 className="m-b10">Accomplishments</h5>
										<div className="list-row">
											<div className="list-line">
												<div className="d-flex">
													<h6 className="font-14 m-b5">Online Profile</h6>
													<Link to={"#"} onClick={()=>setOnlineProfile(true)} className="site-button add-btn button-sm">
														<i className="fa fa-pencil m-r5"></i> Edit
													</Link>
												</div>
												<p className="m-b0">Add link to Online profiles (e.g. Linkedin, Facebook etc.).</p>
												
												<Modal className="modal fade modal-bx-info editor" show={onlineprofile} onHide={setOnlineProfile}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title">Online Profiles</h5>
																<button type="button" className="close" onClick={()=>setOnlineProfile(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Social Profile</label>
																				<input type="email" className="form-control" placeholder="Social Profile Name" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>URL</label>
																				<input type="email" className="form-control" placeholder="www.google.com" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Description</label>
																				<textarea className="form-control" placeholder="Type Description"></textarea>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button" onClick={()=>setOnlineProfile(false)}>Cancel</button>
																<button type="button" className="site-button">Save</button>
															</div>
														</div>
													</div>
												</Modal>
												
											<div className="list-line">
												<div className="d-flex">
													<h6 className="font-14 m-b5">Work Sample</h6>
													<Link to={"#"} onClick={()=>setWorkSample(true)} className="site-button add-btn button-sm">
														<i className="fa fa-pencil m-r5"></i> Edit
													</Link>
												</div>
												<p className="m-b0">Add link to your Projects (e.g. Github links etc.).</p>
												
												<Modal className="modal fade modal-bx-info editor" show={worksample} onHide={setWorkSample}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" >Work Sample</h5>
																<button type="button" className="close" onClick={()=>setWorkSample(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Work Title</label>
																				<input type="email" className="form-control" placeholder="Enter Title" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>URL</label>
																				<input type="email" className="form-control" placeholder="www.google.com" />
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Duration From</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>2018</option>
																							<option>2017</option>
																							<option>2016</option>
																							<option>2015</option>
																							<option>2014</option>
																							<option>2013</option>
																							<option>2012</option>
																							<option>2011</option>
																							<option>2010</option>
																							<option>2009</option>
																							<option>2008</option>
																							<option>2007</option>
																							<option>2006</option>
																							<option>2005</option>
																							<option>2004</option>
																							<option>2003</option>
																							<option>2002</option>
																							<option>2001</option>
																						</Form.Control>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>january</option>
																							<option>february</option>
																							<option>March</option>
																							<option>April</option>
																							<option>May</option>
																							<option>Jun</option>
																							<option>July</option>
																							<option>August</option>
																							<option>September</option>
																							<option>October</option>
																							<option>November</option>
																							<option>December</option>
																						</Form.Control>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Duration To</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>2018</option>
																							<option>2017</option>
																							<option>2016</option>
																							<option>2015</option>
																							<option>2014</option>
																							<option>2013</option>
																							<option>2012</option>
																							<option>2011</option>
																							<option>2010</option>
																							<option>2009</option>
																							<option>2008</option>
																							<option>2007</option>
																							<option>2006</option>
																							<option>2005</option>
																							<option>2004</option>
																							<option>2003</option>
																							<option>2002</option>
																							<option>2001</option>
																						</Form.Control>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>january</option>
																							<option>february</option>
																							<option>March</option>
																							<option>April</option>
																							<option>May</option>
																							<option>Jun</option>
																							<option>July</option>
																							<option>August</option>
																							<option>September</option>
																							<option>October</option>
																							<option>November</option>
																							<option>December</option>
																						</Form.Control>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<div className="custom-control custom-checkbox">
																					<input type="checkbox" className="custom-control-input" id="check1" name="example1" />
																					<label className="custom-control-label" htmlFor="check1">I am currently working on this</label>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Description</label>
																				<textarea className="form-control" placeholder="Type Description"></textarea>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button" onClick={()=>setWorkSample(false)}>Cancel</button>
																<button type="button" className="site-button">Save</button>
															</div>
														</div>
													</div>
												</Modal>
												
											</div>
											<div className="list-line">
												<div className="d-flex">
													<h6 className="font-14 m-b5">White Paper / Research Publication / Journal Entry</h6>
													<Link to={"#"} onClick={()=>setWhitePaper(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
												</div>
												<p className="m-b0">Add links to your Online publications.</p>
												
												<Modal className="modal fade modal-bx-info editor" show={whitepaper} onHide={setWhitePaper}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="JournalentryModalLongTitle">White Paper / Research Publication / Journal Entry</h5>
																<button type="button" className="close" onClick={()=>setWhitePaper(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Title</label>
																				<input type="email" className="form-control" placeholder="Enter Title" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>URL</label>
																				<input type="email" className="form-control" placeholder="www.google.com" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Published On</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>2018</option>
																							<option>2017</option>
																							<option>2016</option>
																							<option>2015</option>
																							<option>2014</option>
																							<option>2013</option>
																							<option>2012</option>
																							<option>2011</option>
																							<option>2010</option>
																							<option>2009</option>
																							<option>2008</option>
																							<option>2007</option>
																							<option>2006</option>
																							<option>2005</option>
																							<option>2004</option>
																							<option>2003</option>
																							<option>2002</option>
																							<option>2001</option>
																						</Form.Control>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>january</option>
																							<option>february</option>
																							<option>March</option>
																							<option>April</option>
																							<option>May</option>
																							<option>Jun</option>
																							<option>July</option>
																							<option>August</option>
																							<option>September</option>
																							<option>October</option>
																							<option>November</option>
																							<option>December</option>
																						</Form.Control>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Description</label>
																				<textarea className="form-control" placeholder="Type Description"></textarea>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button" onClick={()=>setWhitePaper(false)}>Cancel</button>
																<button type="button" className="site-button">Save</button>
															</div>
														</div>
													</div>
												</Modal>
												
											</div>
											<div className="list-line">
												<div className="d-flex">
													<h6 className="font-14 m-b5">Presentation</h6>
													<Link to={"#"}  onClick={()=>setPresentation(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
												</div>
												<p className="m-b0">Add links to your Online presentations (e.g. Slideshare presentation links etc.).</p>
												
												<Modal className="modal fade modal-bx-info editor" id="presentation" show={presentation} onHide={setPresentation}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="PresentationModalLongTitle">Presentation</h5>
																<button type="button" className="close" onClick={()=>setPresentation(false)} >
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Title</label>
																				<input type="email" className="form-control" placeholder="Enter Title" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>URL</label>
																				<input type="email" className="form-control" placeholder="www.google.com" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Description</label>
																				<textarea className="form-control" placeholder="Type Description"></textarea>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button" data-dismiss="modal">Cancel</button>
																<button type="button" className="site-button">Save</button>
															</div>
														</div>
													</div>
												</Modal>
												
											</div>
											<div className="list-line">
												<div className="d-flex">
													<h6 className="font-14 m-b5">Patent</h6>
													<Link to={"#"} data-toggle="modal" data-target="#patent" onClick={()=>setPatent(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
												</div>
												<p className="m-b0">Add details of Patents you have filed.</p>
												
												<Modal className="modal fade modal-bx-info editor" show={patent} onHide={setPatent}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="PatentModalLongTitle">Patent</h5>
																<button type="button" className="close" onClick={()=>setPatent(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Title</label>
																				<input type="email" className="form-control" placeholder="Enter Title" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>URL</label>
																				<input type="email" className="form-control" placeholder="www.google.com" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Patent Office</label>
																				<input type="email" className="form-control" placeholder="Enter Patent Office" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Status</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6">
																						<div className="custom-control custom-radio">
																							<input type="radio" className="custom-control-input" id="check2" name="example1" />
																							<label className="custom-control-label" htmlFor="check2">Patent Issued</label>
																						</div>
																					</div>
																					<div className="col-lg-6 col-md-6">
																						<div className="custom-control custom-radio">
																							<input type="radio" className="custom-control-input" id="check3" name="example1" />
																							<label className="custom-control-label" htmlFor="check3">Patent pending</label>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Application Number</label>
																				<input type="email" className="form-control" placeholder="Enter Application Number" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Published On</label>
																				<div className="row">
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>2018</option>
																							<option>2017</option>
																							<option>2016</option>
																							<option>2015</option>
																							<option>2014</option>
																							<option>2013</option>
																							<option>2012</option>
																							<option>2011</option>
																							<option>2010</option>
																							<option>2009</option>
																							<option>2008</option>
																							<option>2007</option>
																							<option>2006</option>
																							<option>2005</option>
																							<option>2004</option>
																							<option>2003</option>
																							<option>2002</option>
																							<option>2001</option>
																						</Form.Control>
																					</div>
																					<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																						<Form.Control as="select">
																							<option>january</option>
																							<option>february</option>
																							<option>March</option>
																							<option>April</option>
																							<option>May</option>
																							<option>Jun</option>
																							<option>July</option>
																							<option>August</option>
																							<option>September</option>
																							<option>October</option>
																							<option>November</option>
																							<option>December</option>
																						</Form.Control>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Description</label>
																				<textarea className="form-control" placeholder="Type Description"></textarea>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button" onClick={()=>setPatent(false)}>Cancel</button>
																<button type="button" className="site-button">Save</button>
															</div>
														</div>
													</div>
												</Modal>
												
											</div>
											<div className="list-line">
												<div className="d-flex">
													<h6 className="font-14 m-b5">Certification</h6>
													<Link to={"#"} onClick={()=>setCertification(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
												</div>
												<p className="m-b0">Add details of Certification you have filed.</p>
												
												<Modal className="modal fade modal-bx-info editor" show={certification} onHide={setCertification}>
													<div className="modal-dialog my-0" role="document">
														<div className="modal-content">
															<div className="modal-header">
																<h5 className="modal-title" id="CertificationModalLongTitle">Certification</h5>
																<button type="button" className="close" onClick={()=>setCertification(false)}>
																	<span aria-hidden="true">&times;</span>
																</button>
															</div>
															<div className="modal-body">
																<form>
																	<div className="row">
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Certification Name</label>
																				<input type="text" className="form-control" placeholder="Enter Certification Name" />
																			</div>
																		</div>
																		<div className="col-lg-12 col-md-12">
																			<div className="form-group">
																				<label>Certification Body</label>
																				<input type="text" className="form-control" placeholder="Enter Certification Body" />
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6">
																			<div className="form-group">
																				<label>Year Onlabel</label>
																					<Form.Control as="select">
																					<option>2018</option>
																					<option>2017</option>
																					<option>2016</option>
																					<option>2015</option>
																					<option>2014</option>
																					<option>2013</option>
																					<option>2012</option>
																					<option>2011</option>
																					<option>2010</option>
																					<option>2009</option>
																					<option>2008</option>
																					<option>2007</option>
																					<option>2006</option>
																					<option>2005</option>
																					<option>2004</option>
																					<option>2003</option>
																					<option>2002</option>
																					<option>2001</option>
																				</Form.Control>
																			</div>
																		</div>
																	</div>
																</form>
															</div>
															<div className="modal-footer">
																<button type="button" className="site-button"onClick={()=>setCertification(false)} >Cancel</button>
																<button type="button" className="site-button">Save</button>
															</div>
														</div>
													</div>
												</Modal>
												
											</div>
										</div>
									</div>
									
								</div>
								*/}

                  {/*
								<div id="desired_career_profile_bx" className="job-bx bg-white m-b30">
									<div className="d-flex">
										<h5 className="m-b30">Desired Career Profile</h5>
										<Link to={"#"} onClick={()=>setCareerProfile(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
									</div>
									<Modal className="modal fade modal-bx-info editor" show={careerprofile} onHide={setCareerProfile}>
										<div className="modal-dialog my-0" role="document">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="DesiredprofileModalLongTitle">Desired Career Profile </h5>
													<button type="button" className="close" onClick={()=>setCareerProfile(false)}>
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
												<div className="modal-body">
													<form>
														<div className="row">
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Industry</label>
																	<Form.Control as="select">
																		<option>Accounting / Finance</option>
																		<option>Banking / Financial Services / Broking</option>
																		<option>Education / Teaching / Training</option>
																		<option>IT-Hardware &amp; Networking</option>
																		<option>Other</option>
																	</Form.Control>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Functional Area / Department</label>
																	<Form.Control as="select">
																		<option>Agent</option>
																		<option>Architecture / Interior Design</option>
																		<option>Beauty / Fitness / Spa Services</option>
																		<option>IT Hardware / Technical Support</option>
																		<option>IT Software - System Programming</option>
																		<option>Other</option>
																	</Form.Control>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Role</label>
																	<Form.Control as="select">
																		<option>Creative</option>
																		<option>Web Designer</option>
																		<option>Graphic Designer</option>
																		<option>National Creative Director</option>
																		<option>Fresher</option>
																		<option>Other</option>
																	</Form.Control>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Job Type</label>
																	<div className="row">
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-checkbox">
																				<input type="checkbox" className="custom-control-input" id="permanent" name="example1" />
																				<label className="custom-control-label" htmlFor="permanent">Permanent</label>
																			</div>
																		</div>
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-checkbox">
																				<input type="checkbox" className="custom-control-input" id="contractual" name="example1" />
																				<label className="custom-control-label" htmlFor="contractual">Contractual</label>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Employment Type</label>
																	<div className="row">
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-checkbox">
																				<input type="checkbox" className="custom-control-input" id="fulltime" name="example1" />
																				<label className="custom-control-label" htmlFor="fulltime">Full Time</label>
																			</div>
																		</div>
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-checkbox">
																				<input type="checkbox" className="custom-control-input" id="parttime" name="example1" />
																				<label className="custom-control-label" htmlFor="parttime">Part Time</label>
																			</div>
																		</div>
																	</div>
																</div>
															</div>	
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Preferred Shift</label>
																	<div className="row">
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="day" name="example1" />
																				<label className="custom-control-label" htmlFor="day">Day</label>
																			</div>
																		</div>
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="night" name="example1" />
																				<label className="custom-control-label" htmlFor="night">Night</label>
																			</div>
																		</div>
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="flexible" name="example1" />
																				<label className="custom-control-label" htmlFor="flexible">Part Time</label>
																			</div>
																		</div>
																	</div>
																</div>
															</div>															
															<div className="col-lg-12 col-md-6">
																<div className="form-group">
																	<label>Availability to Join</label>
																	<div className="row">
																		<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<Form.Control as="select">
																				<option>2018</option>
																				<option>2019</option>
																				<option>2020</option>
																				<option>2021</option>
																				<option>2022</option>
																			</Form.Control>
																		</div>
																		<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<Form.Control as="select">
																				<option>january</option>
																				<option>february</option>
																				<option>March</option>
																				<option>April</option>
																				<option>May</option>
																				<option>Jun</option>
																				<option>July</option>
																				<option>August</option>
																				<option>September</option>
																				<option>October</option>
																				<option>November</option>
																				<option>December</option>
																			</Form.Control>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Expected Salary</label>
																	<div className="row">
																		<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="usdollars" name="example1" />
																				<label className="custom-control-label" htmlFor="usdollars">US Dollars</label>
																			</div>
																		</div>
																		<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="rupees" name="example1" />
																				<label className="custom-control-label" htmlFor="rupees">Indian Rupees</label>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-6">
																<div className="form-group">
																	<div className="row">
																		<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<Form.Control as="select">
																				<option>0 lakh</option>
																				<option>1 lakh</option>
																				<option>2 lakh</option>
																				<option>5 lakh</option>
																				<option>4 lakh</option>
																				<option>5 lakh</option>
																			</Form.Control>
																		</div>
																		<div className="col-lg-6 col-md-6 col-sm-6 col-6">
																			<Form.Control as="select">
																				<option> 05 Thousand </option>
																				<option> 10 Thousand </option>
																				<option> 15 Thousand </option>
																				<option> 20 Thousand </option>
																				<option> 25 Thousand </option>
																				<option> 30 Thousand </option>
																				<option> 35 Thousand </option>
																				<option> 40 Thousand </option>
																				<option> 45 Thousand </option>
																				<option> 50 Thousand </option>
																			</Form.Control>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Desired Location</label>
																	<Form.Control as="select">
																		<option>India</option>
																		<option>Australia</option>
																		<option>Bahrain</option>
																		<option>China</option>
																		<option>Dubai</option>
																		<option>France</option>
																		<option>Germany</option>
																		<option>Hong Kong</option>
																		<option>Kuwait</option>
																	</Form.Control>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Desired Industry</label>
																	<Form.Control as="select">
																		<option>Software</option>
																		<option>Factory</option>
																		<option>Ngo</option>
																		<option>Other</option>
																	</Form.Control>
																</div>
															</div>
														</div>
													</form>
												</div>
												<div className="modal-footer">
													<button type="button" className="site-button" onClick={()=>setCareerProfile(false)}>Cancel</button>
													<button type="button" className="site-button">Save</button>
												</div>
											</div>
										</div>
									</Modal>
									
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="clearfix m-b20">
												<label className="m-b0">Industry</label>
												<span className="clearfix font-13">IT-Software/Software Services</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Role</label>
												<span className="clearfix font-13">Web Designer</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Employment Type</label>
												<span className="clearfix font-13">Full Time</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Availability to Join</label>
												<span className="clearfix font-13">12 july</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Desired Location</label>
												<span className="clearfix font-13">Add Desired Location</span>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="clearfix m-b20">
												<label className="m-b0">Functional Area</label>
												<span className="clearfix font-13">Design / Creative / User Experience</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Job Type</label>
												<span className="clearfix font-13">permanent</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Desired Shift</label>
												<span className="clearfix font-13">Add Desired Shift</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Expected Salary</label>
												<span className="clearfix font-13">1 Lakhs</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Desired Industry</label>
												<span className="clearfix font-13">Add Desired Industry</span>
											</div>
										</div>
									</div>
									
								</div>
								*/}

                  {/*
								<div id="personal_details_bx" className="job-bx bg-white m-b30">
									<div className="d-flex">
										<h5 className="m-b30">Personal Details</h5>
										<Link to={"#"}  onClick={()=>setPersonalDetails(true)} className="site-button add-btn button-sm"><i className="fa fa-pencil m-r5"></i> Edit</Link>
									</div>
									
									<Modal className="modal fade modal-bx-info editor" show={personaldetails} onHide={setPersonalDetails}>
										<div className="modal-dialog my-0" role="document">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title" id="PersonaldetailsModalLongTitle">Personal Details</h5>
													<button type="button" className="close" onClick={()=>setPersonalDetails(false)}>
														<span aria-hidden="true">&times;</span>
													</button>
												</div>
												<div className="modal-body">
													<form>
														<div className="row">
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Date of Birth</label>
																	<div className="row">
																		<div className="col-lg-4 col-md-4 col-sm-4 col-4">
																			<Form.Control as="select">
																				<option>1</option>
																				<option>2</option>
																				<option>3</option>
																				<option>4</option>
																				<option>5</option>
																				<option>6</option>
																				<option>7</option>
																				<option>8</option>
																				<option>9</option>
																				<option>10</option>
																				<option>11</option>
																				<option>12</option>
																				<option>13</option>
																				<option>14</option>
																				<option>15</option>
																				<option>16</option>
																				<option>17</option>
																				<option>18</option>
																				<option>19</option>
																				<option>20</option>
																				<option>21</option>
																				<option>22</option>
																				<option>23</option>
																				<option>24</option>
																				<option>25</option>
																				<option>26</option>
																				<option>27</option>
																				<option>28</option>
																				<option>29</option>
																				<option>30</option>
																				<option>31</option>
																			</Form.Control>
																		</div>
																		<div className="col-lg-4 col-md-4 col-sm-4 col-4">
																			<Form.Control as="select">
																				<option>january</option>
																				<option>february</option>
																				<option>March</option>
																				<option>April</option>
																				<option>May</option>
																				<option>Jun</option>
																				<option>July</option>
																				<option>August</option>
																				<option>September</option>
																				<option>October</option>
																				<option>November</option>
																				<option>December</option>
																			</Form.Control>
																		</div>
																		<div className="col-lg-4 col-md-4 col-sm-4 col-4">
																			<Form.Control as="select">
																				<option>2018</option>
																				<option>2017</option>
																				<option>2016</option>
																				<option>2015</option>
																				<option>2014</option>
																				<option>2013</option>
																				<option>2012</option>
																				<option>2011</option>
																				<option>2010</option>
																				<option>2009</option>
																				<option>2008</option>
																				<option>2007</option>
																				<option>2006</option>
																				<option>2005</option>
																				<option>2004</option>
																				<option>2003</option>
																				<option>2002</option>
																				<option>2001</option>
																			</Form.Control>
																		</div>
																	</div>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Gender</label>
																	<div className="row">
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="male" name="example1" />
																				<label className="custom-control-label" htmlFor="male">Male</label>
																			</div>
																		</div>
																		<div className="col-lg-3 col-md-6 col-sm-6 col-6">
																			<div className="custom-control custom-radio">
																				<input type="radio" className="custom-control-input" id="female" name="example1" />
																				<label className="custom-control-label" htmlFor="female">Female</label>
																			</div>
																		</div>
																	</div>
																</div>
															</div>														
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Permanent Address</label>
																	<input type="email" className="form-control" placeholder="Enter Your Permanent Address" />
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Hometown</label>
																	<input type="email" className="form-control" placeholder="Enter Hometown" />
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Pincode</label>
																	<input type="email" className="form-control" placeholder="Enter Pincode" />
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Marital Status</label>
																	<select>
																		<option>Married</option>
																		<option>Single / Unmarried</option>
																	</select>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Passport Number</label>
																	<input type="email" className="form-control" placeholder="Enter Passport Number" />
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>What assistance do you need</label>
																	<textarea className="form-control" placeholder="Type Description"></textarea>
																</div>
															</div>
															<div className="col-lg-12 col-md-12">
																<div className="form-group">
																	<label>Work Permit for Other Countries</label>
																	<Form.Control as="select">
																		<option>India</option>
																		<option>Australia</option>
																		<option>Bahrain</option>
																		<option>China</option>
																		<option>Dubai</option>
																		<option>France</option>
																		<option>Germany</option>
																		<option>Hong Kong</option>
																		<option>Kuwait</option>
																	</Form.Control>
																</div>
															</div>
														</div>
													</form>
												</div>
												<div className="modal-footer">
													<button type="button" className="site-button"onClick={()=>setPersonalDetails(false)}>Cancel</button>
													<button type="button" className="site-button">Save</button>
												</div>
											</div>
										</div>
									</Modal>
									
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="clearfix m-b20">
												<label className="m-b0">Date of Birth</label>
												<span className="clearfix font-13">31 July 1998</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Gender</label>
												<span className="clearfix font-13">male</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Marital Status</label>
												<span className="clearfix font-13">Single / unmarried</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Passport Number</label>
												<span className="clearfix font-13">+ 123 456 7890</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Differently Abled</label>
												<span className="clearfix font-13">None</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Languages</label>
												<span className="clearfix font-13">English</span>
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6">
											<div className="clearfix m-b20">
												<label className="m-b0">Permanent Address</label>
												<span className="clearfix font-13">Add Permanent Address</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Area Pin Code</label>
												<span className="clearfix font-13">302010</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Hometown</label>
												<span className="clearfix font-13">Delhi</span>
											</div>
											<div className="clearfix m-b20">
												<label className="m-b0">Work permit of other country</label>
												<span className="clearfix font-13">USA</span>
											</div>
										</div>
									</div>	
								</div>
								*/}

                  <div className="float-left">
                    <button
                      type="button"
                      className="site-button m-1"
                      onClick={() => history.push("/profile")}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="site-button-secondry"
                      onClick={() => history.push("/profile")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default Jobmyresume;
